<template>
    <div class="card-body">
        <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
            <div class="col-12 col-md-auto  p-0 d-inline-flex">
                <h5 class="my-auto">Jalur Pendaftaran</h5>
            </div>
            <div class="col-auto p-0">
                <button type="button" class="btn btn-sm btn-secondary hstack gap-1 shadow-sm" data-bs-toggle="modal"
                    data-bs-target="#tambahData">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-plus-lg"
                        viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                    </svg>Tambahkan Jalur
                </button>
            </div>
            <div class="col-auto p-0">
                <select class="form-select form-select-sm shadow-sm" v-model="ta">
                    <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
                </select>
            </div>
        </div>
        <div class="table-responsive shadow vl-parent" ref="dataJalur">
            <form @submit.prevent="updateData">
                <table class="table table-sm mb-0">
                    <thead class="table-secondary align-middle">
                        <tr>
                            <th>#</th>
                            <th>Nama</th>
                            <th>Kuota</th>
                            <th>Biaya DU</th>
                            <th>Status</th>
                            <th>Pendaftar</th>
                            <th>Diterima</th>
                            <th>Tindakan</th>
                        </tr>
                    </thead>
                    <tbody v-if="dataJalur.length">
                        <tr v-for="d, index in dataJalur" :key="d">
                            <td>{{ index+=1 }}</td>
                            <td v-if="d.edit"><input type="text" class="form-control form-control-sm"
                                    v-model="formEdit.nama" :placeholder="d.nama" required></td>
                            <td v-else>
                                <transition name="fade" mode="out-in">
                                    <span :key="d.nama">{{ d.nama }}</span>
                                </transition>
                            </td>
                            <td v-if="d.edit"><input type="number" class="form-control form-control-sm"
                                    v-model="formEdit.kuota" :placeholder="d.kuota" required></td>
                            <td v-else>
                                <transition name="fade" mode="out-in">
                                    <span :key="d.kuota">{{ d.kuota }}</span>
                                </transition>
                            </td>
                            <td v-if="d.edit"><input type="text" class="form-control form-control-sm"
                                    v-model="input_nominal" :placeholder="d.biaya_du" required></td>
                            <td v-else>
                                <transition name="fade" mode="out-in">
                                    <span :key="d.biaya_du">{{ d.biaya_du.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    maximumFractionDigits: 0,
                }) }}</span>
                                </transition>
                            </td>
                            <td>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" :checked="d.status"
                                        @change="confirmUpdateStatus(d)"
                                        v-tooltip="d.status ? 'Nonaktifkan' : 'Aktifkan'">
                                </div>
                            </td>
                            <td>
                                <transition name="slide-up" mode="out-in">
                                    <span class="d-inline-block" :key="d.jumlah_pendaftar">{{ d.jumlah_pendaftar
                                        }}</span>
                                </transition>
                            </td>
                            <td>
                                <transition name="slide-up" mode="out-in">
                                    <span class="d-inline-block" :key="d.jumlah_diterima">{{ d.jumlah_diterima }}</span>
                                </transition>
                            </td>
                            <td v-if="d.edit">
                                <div class="hstack gap-1">
                                    <button class="btn btn-sm btn-outline-dark" @click="batalEdit(d)">Batal
                                    </button>
                                    <div class="vr"></div>
                                    <button type="submit" class="btn btn-sm btn-primary" :disabled="sedangMengunggah">
                                        <span v-if="sedangMengunggah" class="spinner-border spinner-border-sm"
                                            aria-hidden="true"></span>
                                        <span v-if="sedangMengunggah" role="status">Loading...</span>
                                        <span v-else>Simpan</span>
                                    </button>
                                </div>
                            </td>
                            <td v-else>
                                <div class="hstack gap-1">
                                    <button type="button" class="btn btn-sm btn-outline-primary d-flex"
                                        @click="editData(d)" v-tooltip="'Edit Data'">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor"
                                            class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path
                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fill-rule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg>
                                    </button>
                                    <div class="vr"></div>
                                    <button type="button" class="btn btn-sm btn-outline-danger d-flex"
                                        @click="confirmDelete(d)" v-tooltip="'Hapus Data'">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor"
                                            class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="text-center fst-italic py-3" colspan="8">Data tidak ditemukan.</td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </div>
    <div class="modal fade" id="tambahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content vl-parent" ref="formCreate">
                <form @submit.prevent="insertData">
                    <div class="modal-header">
                        <legend class="modal-title">Tambah Data Jalur Pendaftaran</legend>
                        <button type="button" id="tutupFormCreate" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="Nama Jalur Pendaftaran" v-model="formCreate.nama" required>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Kuota</label>
                            <div class="col-sm-9">
                                <input type="number" class="form-control form-control-sm" placeholder="Kuota"
                                    v-model="formCreate.kuota" required>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Biaya DU</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" placeholder="Biaya Daftar Ulang"
                                    v-model="biaya_du" required>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary">Simpan</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
    name: 'DataJalur',
    data() {
        return {
            insertOrDeleteListener: () => {
                this.getDataJalur(2)
            },
            updateListener: (d) => {
                const update = JSON.parse(d);
                for (const i of this.dataJalur) {
                    if (i.jalur_ppdb_id === update.jalur_ppdb_id) {
                        if (!update.nama) {
                            i.status = update.status;
                        } else {
                            i.edit = false;
                            i.nama = update.nama;
                            i.kuota = update.kuota;
                            i.biaya_du = update.biaya_du;
                        }
                    }
                }
            },
            insertPpdbListener: (d) => {
                const insert = JSON.parse(d);
                if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
                    for (const j of this.dataJalur) {
                        if (j.jalur_ppdb_id == insert.jalur_ppdb_id) {
                            j.jumlah_pendaftar++;
                        }
                    }
                }
            },
            updatePpdbListener: (d) => {
                const update = JSON.parse(d);
                if (this.ta.tahun_ajaran_id == update.tahun_ajaran_id) {
                    this.getDataJalur(0);
                }
            },
            deletePpdbListener: (d) => {
                const del = JSON.parse(d);
                if (this.ta.tahun_ajaran_id == del.tahun_ajaran_id) {
                    this.getDataJalur(0);
                }
            },
            pembayaranListener: (d) => {
                const data = JSON.parse(d);
                if (this.ta.tahun_ajaran_id == data.tahun_ajaran_id) {
                    this.getDataJalur(0);
                }
            },
            pernyataanListener: (d) => {
                const data = JSON.parse(d);
                if (this.ta.tahun_ajaran_id == data.tahun_ajaran_id) {
                    this.getDataJalur(0);
                }
            },
            dataJalur: [],
            listTa: [],
            ta: this.$store.getters.getTahunAjaran,
            formCreate: {
                nama: null,
                kuota: null,
                biaya_du: null,
            },
            biaya_du: null,
            formEdit: {},
            input_nominal: null,
            sedangMengunggah: false,
        }
    },
    computed: {
        token() {
            return this.$store.getters.getToken
        },
        taAktif() {
            return this.$store.getters.getTahunAjaran
        },
    },
    mounted() {
        this.getListTa();
        this.getDataJalur(0);
        WebSocketService.on("inserted-jalur", this.insertOrDeleteListener);
        WebSocketService.on("updated-jalur", this.updateListener);
        WebSocketService.on("updated-status-jalur", this.updateListener);
        WebSocketService.on("deleted-jalur", this.insertOrDeleteListener);
        WebSocketService.on("inserted-ppdb", this.insertPpdbListener);
        WebSocketService.on("updated-ppdb", this.updatePpdbListener);
        WebSocketService.on("deleted-ppdb", this.deletePpdbListener);
        WebSocketService.on("inserted-pembayaran", this.pembayaranListener);
        WebSocketService.on("updated-pembayaran", this.pembayaranListener);
        WebSocketService.on("deleted-pembayaran", this.pembayaranListener);
        WebSocketService.on("inserted-pernyataan", this.pernyataanListener);
        WebSocketService.on("updated-pernyataan", this.pernyataanListener);
        WebSocketService.on("deleted-pernyataan", this.pernyataanListener);
    },
    watch: {
        ta() {
            this.getDataJalur(1);
        },
        biaya_du(newValue) {
            let input = new String(newValue).replace(/[^,\d]/g, '');
            const formattedValue = new Intl.NumberFormat('id-ID', {
                style: "currency",
                currency: "IDR",
                maximumFractionDigits: 0,
            }).format(input);
            this.biaya_du = formattedValue;
            this.formCreate.biaya_du = new Number(input)
        },
        input_nominal(newValue) {
            let input = new String(newValue).replace(/[^,\d]/g, '');
            const formattedValue = new Intl.NumberFormat('id-ID', {
                style: "currency",
                currency: "IDR",
                maximumFractionDigits: 0,
            }).format(input);
            this.input_nominal = formattedValue;
            this.formEdit.biaya_du = new Number(input)
        }
    },
    beforeUnmount() {
        WebSocketService.off("inserted-jalur", this.insertOrDeleteListener);
        WebSocketService.off("updated-jalur", this.updateListener);
        WebSocketService.off("updated-status-jalur", this.updateListener);
        WebSocketService.off("deleted-jalur", this.insertOrDeleteListener);
        WebSocketService.off("inserted-ppdb", this.insertPpdbListener);
        WebSocketService.off("updated-ppdb", this.updatePpdbListener);
        WebSocketService.off("deleted-ppdb", this.deletePpdbListener);
        WebSocketService.off("inserted-pembayaran", this.pembayaranListener);
        WebSocketService.off("updated-pembayaran", this.pembayaranListener);
        WebSocketService.off("deleted-pembayaran", this.pembayaranListener);
        WebSocketService.off("inserted-pernyataan", this.pernyataanListener);
        WebSocketService.off("updated-pernyataan", this.pernyataanListener);
        WebSocketService.off("deleted-pernyataan", this.pernyataanListener);
    },
    methods: {
        async getListTa() {
            try {
                const response = await this.axios.post('/list/tahun-ajaran', {
                }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.listTa = response.data.tahun_ajaran;
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silahkan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                    }
                } else {
                    console.log(err)
                }
            }
        },
        async getDataJalur(n) {
            let memuat;
            if (n) {
                memuat = this.$loading.show({
                    container: this.$refs.quickCount,
                    loader: n == 2 ? 'dots' : 'spinner'
                });
            }
            try {
                const response = await this.axios.post('/data/jalur', {
                    tahun_ajaran_id: this.ta.tahun_ajaran_id,
                }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.dataJalur = response.data.jalur;
                    for (const i of this.dataJalur) {
                        i.edit = false;
                    }
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silahkan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                    }
                } else {
                    console.log(err)
                }
            }
            if (n) {
                setTimeout(() => {
                    memuat.hide();
                }, 250);
            }
        },
        async insertData() {
            let memuat = this.$loading.show({
                container: this.$refs.formCreate,
                loader: 'spinner'
            });
            try {
                const response = await this.axios.post('/setting/jalur/tambah', this.formCreate, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    document.getElementById('tutupFormCreate').click();
                    this.$swal({
                        title: 'Sukses',
                        text: response.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(() => {
                        this.formCreate = {
                            nama: null,
                            kuota: null,
                            biaya_du: null,
                        }
                    });
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silahkan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                        this.$swal({
                            title: 'Gagal',
                            html: `<p class="text-danger">${err.response.data.error}</p>
                                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                                message: ${err.response.data.message}</code>`,
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        });
                    }
                } else {
                    console.log(err)
                }
            }
            setTimeout(() => {
                memuat.hide();
            }, 250);
        },
        editData(d) {
            for (const i of this.dataJalur) {
                i.edit = i.jalur_ppdb_id === d.jalur_ppdb_id;
            }
            this.input_nominal = d.biaya_du;
            this.formEdit = { ...d }
        },
        batalEdit(d) {
            d.edit = false;
        },
        async updateData() {
            this.sedangMengunggah = true;
            const awal = this.dataJalur.filter((i) => i.jalur_ppdb_id == this.formEdit.jalur_ppdb_id)[0];
            let perubahan = false;
            if (JSON.stringify(awal) === JSON.stringify(this.formEdit)) {
                this.$swal({
                    title: 'Perhatian',
                    text: 'Anda belum melakukan perubahan!',
                    icon: 'info',
                    showConfirmButton: true,
                }).then(() => {
                    this.sedangMengunggah = false;
                })
            } else {
                perubahan = true;
                try {
                    const response = await this.axios.post('/setting/jalur/perbarui', {
                        jalur_ppdb_id: this.formEdit.jalur_ppdb_id,
                        nama: this.formEdit.nama,
                        kuota: this.formEdit.kuota,
                        biaya_du: this.formEdit.biaya_du,
                    }, {
                        headers: { Authorization: this.token }
                    });
                    if (response.data) {
                        this.$swal({
                            title: 'Sukses',
                            text: response.data.message,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }
                catch (err) {
                    this.sedangMengunggah = false;
                    if (err.response) {
                        if (err.response.status == 401) {
                            this.$swal({
                                title: 'Gagal',
                                text: 'Sesi berakhir. Silahkan login ulang.',
                                icon: 'error',
                                confirmButtonText: 'Baik'
                            }).then(() => {
                                this.$store.dispatch('logoutUser');
                                window.location.href = this.$sso + "/" + this.$appId;
                            });
                        } else {
                            console.log(err);
                            this.$swal({
                                title: 'Gagal',
                                html: `<p class="text-danger">${err.response.data.error}</p>
                                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                                    message: ${err.response.data.message}</code>`,
                                icon: 'error',
                                confirmButtonText: 'Baik'
                            });
                        }
                    } else {
                        console.log(err)
                    }
                }
            }
            setTimeout(() => {
                this.sedangMengunggah = perubahan ? false : true;
            }, 250);
        },
        confirmUpdateStatus(d) {
            const a = d.status;
            d.status = d.status == 1 ? 0 : 1;
            if (a) {
                this.$swal({
                    title: 'Konfirmasi',
                    html: `<span class="h5">Anda yakin ingin menonaktifkan Jalur <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span>`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Batal',
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.updateStatus(d);
                    } else {
                        d.status = 1;
                    }
                });
            } else {
                this.$swal({
                    title: 'Konfirmasi',
                    html: `<span class="h5">Anda yakin ingin mengaktifkan Jalur <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span>`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Batal',
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.updateStatus(d);
                    } else {
                        d.status = 0;
                    }
                });
            }
        },
        async updateStatus(d) {
            let memuat = this.$loading.show({
                opacity: 0,
                loader: 'spinner'
            });
            try {
                const response = await this.axios.post('/setting/jalur/perbarui-status', {
                    jalur_ppdb_id: d.jalur_ppdb_id,
                    status_jalur: d.status
                }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.$swal({
                        title: 'Sukses',
                        text: response.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silahkan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                        this.$swal({
                            title: 'Gagal',
                            html: `<p class="text-danger">${err.response.data.error}</p>
                                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                                    message: ${err.response.data.message}</code>`,
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        });
                    }
                } else {
                    console.log(err);
                }
            }
            setTimeout(() => {
                memuat.hide();
            }, 250);
        },
        confirmDelete(d) {
            this.$swal({
                title: 'Konfirmasi',
                html: `<span class="h5">Anda yakin ingin menghapus data Jalur Pendaftaran <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span><br/>
                    <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Ya, Hapus',
                cancelButtonText: 'Batal',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteData(d);
                }
            });
        },
        async deleteData(d) {
            let memuat = this.$loading.show({
                opacity: 0,
                loader: 'spinner'
            });
            try {
                const response = await this.axios.post('/setting/jalur/hapus', { jalur_ppdb_id: d.jalur_ppdb_id }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.$swal({
                        title: 'Sukses',
                        text: response.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silahkan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                        this.$swal({
                            title: 'Gagal',
                            html: `<p class="text-danger">${err.response.data.error}</p>
                                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                                    message: ${err.response.data.message}</code>`,
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        });
                    }
                } else {
                    console.log(err);
                }
            }
            setTimeout(() => {
                memuat.hide();
            }, 250);
        },
    }
}
</script>

<style></style>