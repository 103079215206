<template>
  <div class="text-center mb-4">
    <h4>Aplikasi Penerimaan Peserta Didik Baru</h4>
    <img alt="SKAPSA logo" class="my-2" width="150" src="../assets/logo.png">
    <h4>SMK PGRI 1 KEDIRI {{ taAktif.nama }}</h4>
  </div>
  <info-jurusan />
</template>

<script>
// @ is an alias to /src
import InfoJurusan from '@/components/InfoJurusan.vue'

export default {
  name: 'HomeView',
  data() {
    return {
    };
  },
  components: {
    InfoJurusan,
  },
  computed: {
    user() {
      return this.$store.state.userData;
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    }
  },
  mounted() {
  },
  watch: {
  }
}
</script>
