<template>
    <nav class="navbar navbar-expand-lg py-1 sticky-top" data-bs-theme="dark">
        <div class="container-fluid">
            <router-link class="navbar-brand fs-5" to="/" @click="tutupNavBar">
                <img src="../assets/logo.png" class="d-inline-block align-text-bottom" alt="Logo" height="24">
                Aplikasi PPDB
            </router-link>
            <button id="toggler" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu"
                aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="menu">
                <ul class="navbar-nav me-auto" v-if="adminPpdb">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link" :active-class="'active'"
                            @click="tutupNavBar">Beranda</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/pendaftaran" class="nav-link"
                            :class="{ active: $route.name == 'pendaftaran' }"
                            @click="tutupNavBar">Pendaftaran</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/daftar-ulang" class="nav-link"
                            :class="{ active: $route.name == 'daftarUlang' }" @click="tutupNavBar">Daftar
                            Ulang</router-link>
                    </li>
                    <li class="nav-item" v-if="adminKhusus">
                        <router-link to="/pernyataan" class="nav-link" :class="{ active: $route.name == 'pernyataan' }"
                            @click="tutupNavBar">Pernyataan</router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false" title="data">
                            <span :class="{ 'text-white': String($route.name).startsWith('data') }">Data</span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-dark shadow-lg">
                            <li>
                                <router-link to="/data-pendaftaran" class="dropdown-item" :active-class="'active'"
                                    @click="tutupNavBar">Data Pendaftaran</router-link>
                            </li>
                            <li>
                                <router-link to="/data-pembayaran" class="dropdown-item" :active-class="'active'"
                                    @click="tutupNavBar">Data Pembayaran</router-link>
                            </li>
                            <li v-if="adminKhusus">
                                <router-link to="/data-pernyataan" class="dropdown-item" :active-class="'active'"
                                    @click="tutupNavBar">Data Pernyataan</router-link>
                            </li>
                            <li>
                                <router-link to="/pembawa" class="dropdown-item" :active-class="'active'"
                                    @click="tutupNavBar">Pembawa PPDB</router-link>
                            </li>
                            <li v-if="adminKhusus">
                                <router-link to="/riwayat-pendaftaran" class="dropdown-item" :active-class="'active'"
                                    @click="tutupNavBar">Riwayat Pendaftaran</router-link>
                            </li>
                            <li v-if="adminKhusus">
                                <router-link to="/riwayat-pembayaran" class="dropdown-item" :active-class="'active'"
                                    @click="tutupNavBar">Riwayat Pembayaran</router-link>
                            </li>
                            <li v-if="adminKhusus">
                                <router-link to="/asal-pendaftar" class="dropdown-item" :active-class="'active'"
                                    @click="tutupNavBar">Data Asal Pendaftar</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item" v-if="adminKhusus">
                        <router-link to="/pengaturan" class="nav-link" :class="{ active: $route.name == 'pengaturan' }"
                            @click="tutupNavBar">Pengaturan</router-link>
                    </li>
                    <li class="nav-item" v-if="adminSeragam">
                        <router-link to="/seragam" class="nav-link" :class="{ active: $route.name == 'seragam' }"
                            @click="tutupNavBar">Seragam</router-link>
                    </li>
                </ul>
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <img v-if="user.foto" :src="user.foto" height="24" alt="" class="rounded-circle"
                                :class="{ 'border border-light': $route.name == 'ganti-sandi' || $route.name == 'profil' }">
                            <img v-else src="../assets/person-light.png" height="24" alt="">
                        </a>
                        <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end shadow-lg">
                            <li>
                                <div class="dropdown-header text-center py-0">
                                    <img v-if="user.foto" :src="user.foto" height="72" alt="" class="rounded-circle">
                                    <img v-else src="../assets/person-light.png" height="72" alt="">
                                    <h6 class="h6 text-light mb-0">{{ user.nama }}</h6>
                                    <span class="fst-italic small text-light opacity-50">{{ user.username }}</span>
                                </div>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li>
                                <router-link to="/profil" class="dropdown-item" :active-class="'active'"
                                    @click="tutupNavBar">Profil</router-link>
                            </li>
                            <li>
                                <router-link to="/ganti-sandi" class="dropdown-item" :active-class="'active'"
                                    @click="tutupNavBar">Ganti Kata Sandi</router-link>
                            </li>
                            <li><a class="dropdown-item" @click="logout">Keluar</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            adminKhusus: false,
            adminPpdb: false,
            adminSeragam: false,
            activeClass: 'nav-link dropdown-toggle active',
        }
    },
    props: {
        user: Object
    },
    computed: {
        token() {
            return this.$store.getters.getToken;
        },
    },
    mounted() {
        this.adminKhusus = String(this.user.ket).split(",").some((k) => {
            return ["it", "ppdb-khusus"].includes(k);
        });
        this.adminPpdb = String(this.user.ket).split(",").some((k) => {
            return ["it", "ppdb-khusus", "ppdb"].includes(k);
        });
        this.adminSeragam = String(this.user.ket).split(",").some((k) => {
            return ["it", "ppdb-khusus", "seragam"].includes(k);
        });
    },
    methods: {
        async logout() {
            try {
                const response = await this.axios.post(this.$auth + '/logout', {},
                    { headers: { Authorization: this.token } });
                if (response) {
                    this.$store.dispatch('logoutUser');
                    window.location.href = this.$sso + "/" + this.$appId;
                }
            } catch (err) {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + '/' + this.$appId
            }
        },
        tutupNavBar() {
            const menu = document.getElementById('menu');
            const toggler = document.getElementById('toggler');
            if (menu.getAttribute('class') == 'navbar-collapse collapse show' && toggler.getAttribute('class') == 'navbar-toggler') {
                toggler.click();
            }
        }
    }
}
</script>

<style scoped>
nav,
.dropdown-menu {
    background-color: #184b89;
}
</style>