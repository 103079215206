export default {
    install(app) {
        app.config.globalProperties.$terbilang = function (n) {
            if (n > 999999999999999) {
                return "Nominal berada di luar batas (di atas triliun)";
            }
            const bilangan = [
                "",
                "Satu",
                "Dua",
                "Tiga",
                "Empat",
                "Lima",
                "Enam",
                "Tujuh",
                "Delapan",
                "Sembilan",
                "Sepuluh",
                "Sebelas",
                "Dua Belas",
                "Tiga Belas",
                "Empat Belas",
                "Lima Belas",
                "Enam Belas",
                "Tujuh Belas",
                "Delapan Belas",
                "Sembilan Belas",
            ];
            let kalimat = "";
            const tingkat = [
                { nilai: 1000000000000, nama: "Triliun" },
                { nilai: 1000000000, nama: "Miliar" },
                { nilai: 1000000, nama: "Juta" },
                { nilai: 1000, nama: "Ribu" },
            ];
            let i = 0;
            for (let t = 0; t < tingkat.length; t++) {
                let seribu = false;
                if (n >= tingkat[t].nilai) {
                    let temp = Math.floor(n / tingkat[t].nilai);
                    if (t == 3 && i == 0 && temp == 1) {
                        seribu = true;
                    }
                    while (temp > 0) {
                        if (temp < 20) {
                            kalimat += seribu ? "" : " " + bilangan[temp];
                            temp = 0;
                        } else if (temp < 100) {
                            kalimat +=
                                Math.floor(temp / 10) == 1
                                    ? " Sepuluh"
                                    : " " +
                                      bilangan[Math.floor(temp / 10)] +
                                      " Puluh";
                            temp = temp % 10;
                        } else {
                            kalimat +=
                                Math.floor(temp / 100) == 1
                                    ? " Seratus"
                                    : " " +
                                      bilangan[Math.floor(temp / 100)] +
                                      " Ratus";
                            temp = temp % 100;
                        }
                    }
                    kalimat += seribu ? " Seribu" : " " + tingkat[t].nama;
                    n = n % tingkat[t].nilai;
                    i++;
                }
            }
            while (n > 0) {
                if (n < 20) {
                    kalimat += " " + bilangan[n];
                    n = 0;
                } else if (n < 100) {
                    kalimat +=
                        Math.floor(n / 10) == 1
                            ? " Sepuluh"
                            : " " + bilangan[Math.floor(n / 10)] + " Puluh";
                    n = n % 10;
                } else {
                    kalimat +=
                        Math.floor(n / 100) == 1
                            ? " Seratus"
                            : " " + bilangan[Math.floor(n / 100)] + " Ratus";
                    n = n % 100;
                }
            }
            return kalimat.trim();
        };
    },
};
