<template>
  <div v-if="!pendaftar" class="h3 text-center text-secondary">Data pendaftar tidak ditemukan</div>
  <div v-else id="bukti-pendaftaran">
    <img v-for="i in 3" :key="i" :class="'bg-img bg-img' + i" src="../assets/bg-bukti.png" alt="">
    <div class="container-fluid px-2">
      <div class="row px-3 py-2">
        <div class="col-10 text-center d-flex px-1">
          <div class="col-2 d-flex">
            <div class="m-auto text-center">
              <img class="w-100" src="../assets/logo.png" alt="">
            </div>
          </div>
          <div class="col-10 text-center d-flex px-1">
            <div class="m-auto">
              <h5 class="mb-0">
                PENERIMAAN PESERTA DIDIK BARU SMK PGRI 1 KEDIRI<br />
                TAHUN AJARAN {{ pendaftar.nama_tahun_ajaran }}
              </h5>
              <hr class="kop my-1">
              <span id="h-pendaftaran" class="h6 mb-0">BUKTI PENDAFTARAN</span>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex border border-2 border-dark rounded-3">
          <div class="m-auto text-center">
            <h3 class="mb-0">F1</h3>
            <hr class="my-0 opacity-100">
            <h6>Panitia</h6>
          </div>
        </div>
      </div>
      <div class="px-1">
        <table class="table table-bordered border-dark mb-2">
          <thead>
            <tr>
              <td class="text-center bg-transparent" colspan="2">
                <div class="hstack gap-1 justify-content-center">
                  <span class="text-secondary">Nomor Registrasi :</span>
                  <span class="fw-bold">{{ pendaftar.no_reg }}</span>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="w-50 py-0 bg-transparent">
                <span id="judul" class="small">Nomor Induk Kependudukan :</span>
                <h6 class="mb-1">{{ pendaftar.nik }}</h6>
                <span id="judul" class="small">Nama Peserta Didik Baru :</span>
                <h6 class="mb-1">{{ pendaftar.nama }}</h6>
                <span id="judul" class="small">Tempat/Tanggal Lahir :</span>
                <h6 class="mb-1">{{ pendaftar.nama_tempat_lahir }}, {{ pendaftar.tanggal_lahir }}</h6>
                <span id="judul" class="small">Jenis Kelamin :</span>
                <h6 class="mb-1">{{ jenisKelamin[pendaftar.jenis_kelamin] }}</h6>
                <span id="judul" class="small">Agama :</span>
                <h6 class="mb-1">{{ pendaftar.nama_agama }}</h6>
              </td>
              <td class="py-0 bg-transparent">
                <span id="judul" class="small">Nomor Induk Siswa Nasional :</span>
                <h6 class="mb-1">{{ pendaftar.nisn }}</h6>
                <span id="judul" class="small">Asal Sekolah :</span>
                <h6 class="mb-1">{{ pendaftar.nama_asal_sekolah }} - ({{ pendaftar.npsn_asal_sekolah }})</h6>
                <span id="judul" class="small">Alamat :</span>
                <h6 class="mb-1">{{ pendaftar.alamat }} RT {{ pendaftar.rt }} RW {{ pendaftar.rw }}, Desa {{
                  pendaftar.nama_desa }}, {{ pendaftar.nama_kec }}, {{ pendaftar.nama_kab_kota }},
                  {{ pendaftar.nama_provinsi }}
                </h6>
                <span id="judul" class="small">Nomor Telepon :</span>
                <h6 class="mb-0">{{ pendaftar.kontak }} <span class="small fst-italic">(Peserta Didik Baru)</span></h6>
                <h6 class="mb-1">{{ pendaftar.kontak_wali }} <span class="small fst-italic">(Orang Tua/Wali)</span></h6>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="py-0 bg-transparent text-center">
                <span id="judul" class="fst-italic">{{ pendaftar.jalur_pendaftaran }} - {{ pendaftar.created_at }} ({{
                  pendaftar.creator }})</span>
                <span id="judul" class="fst-italic" v-if="pendaftar.editor">, diperbarui
                  {{ pendaftar.updated_at }} ({{ pendaftar.editor }})</span>
                <h6 id="jurusan" class="mb-0">
                  {{ pendaftar.nama_jurusan + " (" + pendaftar.kode + ")" }}</h6>
              </td>
            </tr>
            <tr class="text-center">
              <td class="py-0 bg-transparent">
                <br />
                <div class="mb-5">Peserta Didik Baru</div>
                <h6 class="text-decoration-underline fw-bold mb-1">{{ pendaftar.nama }}</h6>
              </td>
              <td class="py-0 bg-transparent">
                Kediri, {{ new Date().toLocaleDateString("id-ID", {
                  dateStyle: "long"
                }) }}
                <div class="mb-5">Petugas</div>
                <h6 class="text-decoration-underline fw-bold mb-1">{{ user.nama }}</h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr class="batas my-0">
    <div class="container-fluid px-2">
      <div class="row px-3 py-2">
        <div class="col-10 text-center d-flex px-1">
          <div class="col-2 d-flex">
            <div class="m-auto text-center">
              <img class="w-100" src="../assets/logo.png" alt="">
            </div>
          </div>
          <div class="col-10 text-center d-flex px-1">
            <div class="m-auto">
              <h5 class="mb-0">
                PENERIMAAN PESERTA DIDIK BARU SMK PGRI 1 KEDIRI<br />
                TAHUN AJARAN {{ pendaftar.nama_tahun_ajaran }}
              </h5>
              <hr class="kop my-1">
              <span id="h-pendaftaran" class="h6 mb-0">BUKTI PENDAFTARAN</span>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex border border-2 border-dark rounded-3">
          <div class="m-auto text-center">
            <h3 class="mb-0">F1</h3>
            <hr class="my-0 opacity-100">
            <h6>Peserta</h6>
          </div>
        </div>
      </div>
      <div class="px-1">
        <table class="table table-bordered border-dark mb-0">
          <thead>
            <tr>
              <td class="text-center bg-transparent" colspan="2">
                <div class="hstack gap-1 justify-content-center">
                  <span>Nomor Registrasi :</span>
                  <span class="fw-bold">{{ pendaftar.no_reg }}</span>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="w-50 py-0 bg-transparent">
                <span id="judul" class="small">Nomor Induk Kependudukan :</span>
                <h6 class="mb-1">{{ pendaftar.nik }}</h6>
                <span id="judul" class="small">Nama Peserta Didik Baru :</span>
                <h6 class="mb-1">{{ pendaftar.nama }}</h6>
                <span id="judul" class="small">Tempat/Tanggal Lahir :</span>
                <h6 class="mb-1">{{ pendaftar.nama_tempat_lahir }}, {{ pendaftar.tanggal_lahir }}</h6>
                <span id="judul" class="small">Jenis Kelamin :</span>
                <h6 class="mb-1">{{ jenisKelamin[pendaftar.jenis_kelamin] }}</h6>
                <span id="judul" class="small">Agama :</span>
                <h6 class="mb-1">{{ pendaftar.nama_agama }}</h6>
              </td>
              <td class="py-0 bg-transparent">
                <span id="judul" class="small">Nomor Induk Siswa Nasional :</span>
                <h6 class="mb-1">{{ pendaftar.nisn }}</h6>
                <span id="judul" class="small">Asal Sekolah :</span>
                <h6 class="mb-1">{{ pendaftar.nama_asal_sekolah }} - ({{ pendaftar.npsn_asal_sekolah }})</h6>
                <span id="judul" class="small">Alamat :</span>
                <h6 class="mb-1">{{ pendaftar.alamat }} RT {{ pendaftar.rt }} RW {{ pendaftar.rw }}, Desa {{
                  pendaftar.nama_desa }}, {{ pendaftar.nama_kec }}, {{ pendaftar.nama_kab_kota }},
                  {{ pendaftar.nama_provinsi }}
                </h6>
                <span id="judul" class="small">Nomor Telepon :</span>
                <h6 class="mb-0">{{ pendaftar.kontak }} <span class="small fst-italic">(Peserta Didik Baru)</span></h6>
                <h6 class="mb-1">{{ pendaftar.kontak_wali }} <span class="small fst-italic">(Orang Tua/Wali)</span></h6>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="py-0 bg-transparent text-center">
                <span id="judul" class="small">Jalur - Waktu Pendaftaran :</span>
                <h6 class="mb-1">{{ pendaftar.jalur_pendaftaran }} - {{ pendaftar.created_at }}</h6>
                <span id="judul" class="small">Jurusan/Konsentrasi Keahlian :</span>
                <h6 id="jurusan" class="mb-0">{{ pendaftar.nama_jurusan }} ({{ pendaftar.kode }})</h6>
                <span id="peringatan" class="text-danger fst-italic small">*) Jurusan yang sudah dipilih tidak dapat
                  diubah. Maka pastikan jurusan yang kamu pilih sudah tepat!</span>
              </td>
            </tr>
            <tr class="text-center">
              <td class="py-0 bg-transparent">
                <br />
                <div class="mb-5">Peserta Didik Baru</div>
                <h6 class="text-decoration-underline fw-bold mb-1">{{ pendaftar.nama }}</h6>
              </td>
              <td class="py-0 bg-transparent">
                Kediri, {{ new Date().toLocaleDateString("id-ID", {
                  dateStyle: "long"
                }) }}
                <div class="mb-5">Petugas</div>
                <h6 class="text-decoration-underline fw-bold mb-1">{{ user.nama }}</h6>
              </td>
            </tr>
          </tbody>
        </table>
        <div id="peringatan" class="hstack gap-1 text-danger fw-bold mb-2">
          <span class="mb-auto">*)</span>
          <p class="mb-0" style="text-align: justify;text-justify: inter-word;">
            Bukti Pendaftaran ini BUKAN merupakan bukti diterima sebagai siswa SMK PGRI 1 KEDIRI. Untuk menjadi siswa,
            silahkan lakukan tahap berikutnya yaitu DAFTAR ULANG.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuktiPendaftaran',
  data() {
    return {
      jenisKelamin: {
        L: 'Laki-laki',
        P: 'Perempuan'
      },
    }
  },
  props: {
    pendaftar: Object
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>
#bukti-pendaftaran {
  position: relative;
  font-family: 'Trebuchet MS', sans-serif;
}

.bg-img {
  position: absolute;
  height: auto;
  opacity: 0.3;
  object-fit: cover;
  z-index: -1;
}

.bg-img1 {
  top: 0;
  left: 0;
  width: 60%;
}

.bg-img2 {
  bottom: 0;
  right: 0;
  width: 60%;
}

.bg-img3 {
  bottom: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 50%);
}

table tr td,
td h6 {
  font-size: 12px;
}

#h-pendaftaran {
  color: #800080;
}

#jurusan {
  color: #375995;
}

#peringatan {
  font-size: 10px;
}

#judul {
  color: #4d4d4d;
}

hr.kop {
  height: 5px;
  border-top: 2px solid black;
  border-bottom: 1px solid black;
}

hr.batas {
  height: 2px;
  border-top: none;
  border-bottom: 2px dashed black;
}
</style>