<template>
  <h4>Penerimaan Peserta Didik Baru {{ ta.nama }}</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="row row-cols-auto justify-content-between mb-2">
        <div class="col-12 col-md-auto d-inline-flex">
          <h5 class="my-auto">Data Pembayaran Daftar Ulang</h5>
        </div>
        <div v-if="adminKhusus" class="col-auto d-inline-flex">
          <select class="form-select form-select-sm shadow-sm my-auto" v-model="ta">
            <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
          </select>
        </div>
        <div v-else class="col-auto d-inline-flex">
          <h6 class="bg-white rounded px-2 py-1 shadow my-auto">{{ ta.nama }}</h6>
        </div>
      </div>
      <div class="row g-1">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="petugas_keuangan" placeholder="Petugas Keuangan"
            label="nama" :options="listKeuangan">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Petugas Keuangan tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="teller" placeholder="Petugas Pendaftaran"
            label="nama_petugas" :options="listTeller">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Petugas Pendaftaran tidak ditemukan.
            </template>
          </v-select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 ms-auto">
          <input id="cari" type="search" class="form-control form-control-sm shadow-sm" placeholder="Cari . . ."
            v-model="key" @keyup="search" @search="search">
        </div>
      </div>
      <div class="table-responsive vl-parent shadow" ref="dataPembayaran">
        <form @submit.prevent="updateData">
          <table class="table table-sm mb-0 text-nowrap">
            <thead class="table-secondary align-middle">
              <tr>
                <th>#</th>
                <th class="position-sticky start-0">No. Reg.</th>
                <th>Nama</th>
                <th>Jurusan</th>
                <th>Jalur</th>
                <th>Nominal</th>
                <th>Petugas Keuangan</th>
                <th>Dibuat</th>
                <th>Diperbarui</th>
                <th>Tindakan</th>
              </tr>
            </thead>
            <tbody v-if="dataPembayaran.length">
              <tr v-for="d, index in dataPembayaran" :key="d">
                <td>{{ index+=1 + pageOffset }}</td>
                <td class="position-sticky start-0">
                  {{ d.no_reg }}</td>
                <td>{{ d.nama }}</td>
                <td>{{ d.kode }}</td>
                <td>{{ d.jalur_pendaftaran }}</td>
                <td v-if="d.edit">
                  <input type="text" class="form-control form-control-sm shadow" style="min-width: 100px;"
                    placeholder="Nominal Pembayaran" v-model="input_nominal" required>
                </td>
                <td v-else>
                  <transition name="fade" mode="out-in">
                    <div :key="d.nominal" class="d-flex justify-content-between gap-1 fw-semibold">
                      <span>Rp</span><span>{{ d.nominal.toLocaleString("id-ID") }}</span>
                    </div>
                  </transition>
                </td>
                <td v-if="d.edit">
                  <v-select class="bg-white rounded shadow" style="min-width: 150px;"
                    v-model="formEdit.petugas_keuangan" placeholder="Petugas Keuangan" label="nama"
                    :options="listKeuangan" :clearable="false" append-to-body :calculate-position="withPopper">
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!formEdit.petugas_keuangan" v-bind="attributes"
                        v-on="events" />
                    </template>
                    <template #no-options>
                      Petugas Keuangan tidak ditemukan.
                    </template>
                  </v-select>
                </td>
                <td v-else>
                  <transition name="fade" mode="out-in">
                    <span :key="d.nama_petugas_keuangan">{{ d.nama_petugas_keuangan }}</span>
                  </transition>
                </td>
                <td>
                  <div class="fw-bold">{{ d.creator }}</div>
                  <small class="fst-italic">{{ d.created_at }}</small>
                </td>
                <td>
                  <transition name="fade" mode="out-in">
                    <div class="fw-bold" :key="d.editor">{{ d.editor || '-' }}</div>
                  </transition>
                  <transition name="fade" mode="out-in">
                    <small class="fst-italic" :key="d.updated_at">{{ d.updated_at }}</small>
                  </transition>
                </td>
                <td v-if="d.edit">
                  <div class="hstack gap-1">
                    <button class="btn btn-sm btn-outline-dark" @click="batalEdit(d)">Batal</button>
                    <div class="vr"></div>
                    <button type="submit" class="btn btn-sm btn-primary" :disabled="sedangMengunggah">
                      <span v-if="sedangMengunggah" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span v-if="sedangMengunggah" role="status">Loading...</span>
                      <span v-else>Simpan</span>
                    </button>
                  </div>
                </td>
                <td v-else>
                  <div class="hstack gap-1">
                    <button type="button" class="btn btn-sm btn-outline-secondary d-flex" @click="cetak(d)"
                      v-tooltip="'Cetak Bukti Pembayaran'"><svg xmlns="http://www.w3.org/2000/svg" width="13"
                        fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                        <path
                          d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                      </svg>
                    </button>
                    <div class="vr"></div>
                    <button type="button" class="btn btn-sm btn-outline-primary d-flex" @click="editData(d)"
                      v-tooltip="'Edit Data'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                        viewBox="0 0 16 16">
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </button>
                    <div class="vr"></div>
                    <button type="button" class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)"
                      v-tooltip="'Hapus Data'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                        viewBox="0 0 16 16">
                        <path
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center fst-italic py-3" colspan="11">Data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <span v-if="dataPembayaran.length" class="small text-muted fst-italic">
        Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataPembayaran.length }} dari total {{ count }} data
      </span>
    </div>
    <div v-if="dataPembayaran.length" class="card-footer">
      <div class="row row-cols-auto justify-content-sm-between justify-content-center">
        <div class="row row-cols-auto">
          <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
          <div class="col-auto ps-1">
            <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
              <option v-for="l in arrayLimit" :key="l" :value="l">
                {{ l }} baris
              </option>
            </select>
          </div>
        </div>
        <div class="col-auto">
          <ul class="pagination pagination-sm shadow mb-0">
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
                @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
            </li>
            <li class="page-item" v-for="p in  pageCount " :key="p">
              <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
              <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button
                v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
                class="page-link" @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
    (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
                @click="pageCurrent = p">...</button>
            </li>
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
                @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import WebSocketService from '@/components/WebSocketService';
import { createPopper } from '@popperjs/core'
export default {
  name: 'DataPembayaran',
  data() {
    return {
      insertListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          this.getDataPembayaran(1);
        }
      },
      updateListener: (d) => {
        const update = JSON.parse(d);
        for (const i of this.dataPembayaran) {
          if (i.bayar_ppdb_id === update.bayar_ppdb_id) {
            if (i.edit) {
              i.edit = false;
            }
            this.getDataPembayaran(2);
            break;
          }
        }
      },
      deleteListener: (d) => {
        const del = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == del.tahun_ajaran_id) {
          if (this.pageCurrent == this.pageCount && this.pageCurrent > 1) {
            if (this.dataPembayaran.length == 1) {
              this.pageCurrent = 1;
            } else {
              this.getDataPembayaran(1);
            }
          } else {
            this.getDataPembayaran(1);
          }
        }
      },
      updateKeuanganListener: () => {
        this.getlistKeuangan();
      },
      adminKhusus: false,
      listTa: [],
      listKeuangan: [],
      listTeller: [],
      dataPembayaran: [],
      ta: this.$store.getters.getTahunAjaran,
      key: null,
      petugas_keuangan: null,
      teller: null,
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: this.$store.getters.getDefaultLimit,
      pageCount: 0,
      dataAwal: {},
      formEdit: {},
      input_nominal: null,
      sedangMengunggah: false,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.adminKhusus = String(this.user.ket).split(",").some((k) => {
      return ["it", "ppdb-khusus"].includes(k);
    });
    this.getListTa();
    this.getDataPembayaran(0);
    this.getListKeuangan();
    this.getListTeller();
    WebSocketService.on("inserted-keuangan", this.updateKeuanganListener);
    WebSocketService.on("updated-keuangan", this.updateKeuanganListener);
    WebSocketService.on("deleted-keuangan", this.updateKeuanganListener);
    WebSocketService.on("inserted-pembayaran", this.insertListener);
    WebSocketService.on("updated-pembayaran", this.updateListener);
    WebSocketService.on("deleted-pembayaran", this.deleteListener);
  },
  watch: {
    ta() {
      if (this.pageCurrent == 1) {
        this.getDataPembayaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    petugas_keuangan() {
      if (this.pageCurrent == 1) {
        this.getDataPembayaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    teller() {
      if (this.pageCurrent == 1) {
        this.getDataPembayaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataPembayaran(1);
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataPembayaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    input_nominal(newValue) {
      let input = new String(newValue).replace(/[^,\d]/g, '');
      const formattedValue = new Intl.NumberFormat('id-ID', {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0,
      }).format(input);
      this.input_nominal = formattedValue;
      this.formEdit.nominal = new Number(input)
    }
  },
  beforeUnmount() {
    WebSocketService.off("inserted-keuangan", this.updateKeuanganListener);
    WebSocketService.off("updated-keuangan", this.updateKeuanganListener);
    WebSocketService.off("deleted-keuangan", this.updateKeuanganListener);
    WebSocketService.off("inserted-pembayaran", this.insertListener);
    WebSocketService.off("updated-pembayaran", this.updateListener);
    WebSocketService.off("deleted-pembayaran", this.deleteListener);
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
    search: debounce(function () {
      if (this.pageCurrent == 1) {
        this.getDataPembayaran(1);
      } else {
        this.pageCurrent = 1;
      }
    }, 500),
    async getListTa() {
      try {
        const response = await this.axios.post('/list/tahun-ajaran', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTa = response.data.tahun_ajaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListTeller() {
      try {
        const response = await this.axios.post('/list/teller', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTeller = response.data.teller;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataPembayaran(n) {
      let memuat;
      if (n != 2) {
        memuat = this.$loading.show({
          container: this.$refs.dataPembayaran,
          loader: n ? 'dots' : 'spinner'
        });
      }
      try {
        const response = await this.axios.post('/data/pembayaran', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          limit: this.pageLimit,
          offset: this.pageOffset,
          key: this.key,
          petugas_keuangan_id: Object(this.petugas_keuangan).petugas_keuangan_id,
          created_by: Object(this.teller).user_id,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataPembayaran = response.data.pembayaran;
          for (const i of this.dataPembayaran) {
            i.edit = false;
            i.created_at = new Date(i.created_at).toLocaleString('id-ID')
            if (i.updated_at) {
              i.updated_at = new Date(i.updated_at).toLocaleString('id-ID')
            }
          }
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      if (n != 2) {
        setTimeout(() => {
          memuat.hide();
        }, 250);
      }
    },
    async getListKeuangan() {
      try {
        const response = await this.axios.post('/list/keuangan', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listKeuangan = response.data.keuangan;
          if (this.petugas_keuangan) {
            let ditemukan = 0;
            for (const k of this.listKeuangan) {
              if (this.petugas_keuangan.petugas_keuangan_id == k.petugas_keuangan_id) {
                ditemukan++;
                this.petugas_keuangan = k;
              }
            }
            if (!ditemukan) {
              this.petugas_keuangan = null;
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    cetak(d) {
      const routeCetak = this.$router.resolve({
        path: '/cetak/pembayaran/' + d.ppdb_id
      });
      window.open(routeCetak.href, '_blank');
    },
    editData(d) {
      this.dataAwal = d;
      this.dataAwal.petugas_keuangan = this.listKeuangan.filter(k => k.petugas_keuangan_id == d.petugas_keuangan_id)[0];
      this.input_nominal = d.nominal;
      this.formEdit = { ...this.dataAwal }
      for (const i of this.dataPembayaran) {
        i.edit = i.bayar_ppdb_id === d.bayar_ppdb_id;
      }
    },
    batalEdit(d) {
      d.edit = false;
      this.dataAwal = {};
      this.formEdit = {};
    },
    async updateData() {
      this.sedangMengunggah = true;
      let dataSama = JSON.stringify(this.formEdit) === JSON.stringify(this.dataAwal);
      if (dataSama) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan!',
          icon: 'info',
          showConfirmButton: true,
        }).then(() => {
          this.sedangMengunggah = false;
        });
      } else {
        try {
          const response = await this.axios.post('/pembayaran/perbarui', {
            bayar_ppdb_id: this.formEdit.bayar_ppdb_id,
            nominal: this.formEdit.nominal,
            petugas_keuangan_id: this.formEdit.petugas_keuangan.petugas_keuangan_id,
          }, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.sedangMengunggah = false;
              this.cetak(this.formEdit)
            })
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Pembayaran <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(d);
        }
      });
    },
    async deleteData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/pembayaran/hapus', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          bayar_ppdb_id: d.bayar_ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err);
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style scoped></style>