import { Tooltip } from "bootstrap";

const bootstrapTooltip = {
    mounted(el, binding) {
        const tooltip = new Tooltip(el, {
            title: binding.value,
            placement: binding.arg || "auto",
        });
        el._tooltip = tooltip;
        el.addEventListener("click", () => {
            document
                .querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
                .forEach((el) => el.remove());
        });
    },
    updated(el, binding) {
        if (binding.oldValue !== binding.value) {
            el._tooltip.dispose();
            const tooltip = new Tooltip(el, {
                title: binding.value,
                placement: binding.arg || "auto",
            });
            el._tooltip = tooltip;
            el.addEventListener("click", () => {
                document
                    .querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
                    .forEach((el) => el.remove());
            });
        }
    },
};

export default bootstrapTooltip;
