<template>
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 justify-content-center vl-parent" ref="infoJurusan">
    <div class="col" v-for="j in infoJurusan" :key="j">
      <div class="card shadow h-100 vl-parent" :ref="'info' + j.jurusan_id">
        <h6 class="card-header d-flex justify-content-between gap-3">
          <div class="hstack gap-1">
            <span>
              {{ j.nama }} ({{ j.kode }})
            </span>
            <div class="vr"></div>
            <button class="btn btn-sm btn-outline-dark border-0 h-100" @click="generateQRCode(j)"
              v-tooltip="'QR Code Grup WA'">
              <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-qr-code d-flex"
                viewBox="0 0 16 16">
                <path d="M2 2h2v2H2V2Z" />
                <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
                <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
                <path
                  d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z" />
                <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
              </svg>
            </button>
          </div>
          <transition name="slide-up" mode="out-in">
            <span class="fw-bold d-inline-block" :key="j.kuota">{{ j.kuota }}</span>
          </transition>
        </h6>
        <div class="card-body small py-1">
          <div class="fw-bold">Program Keahlian :</div>
          <div class="mb-2">{{ j.progli || '-' }}</div>
          <div class="fw-bold">Deskripsi :</div>
          <transition name="fade" mode="out-in">
            <div class="mb-2" :key="j.deskripsi">{{ j.deskripsi || '-' }}</div>
          </transition>
          <div class="fw-bold">Kompetensi Lulusan :</div>
          <transition name="fade" mode="out-in">
            <div class="mb-2" :key="j.kompetensi_lulusan">{{ j.kompetensi_lulusan || '-' }}</div>
          </transition>
          <div class="fw-bold">Industri Rekanan :</div>
          <transition name="fade" mode="out-in">
            <div class="mb-2" :key="j.industri_rekanan">{{ j.industri_rekanan || '-' }}</div>
          </transition>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="qrCode" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <legend class="modal-title" ref="judulModal"></legend>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body text-center">
          <h6 class="modal-title">Grup WhatsApp</h6>
          <canvas class="border border-1" ref="qrCodeCanvas"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
import QRCode from 'qrcode';
import { Modal } from 'bootstrap';
export default {
  name: 'InfoJurusan',
  data() {
    return {
      updateListener: (d) => {
        const update = JSON.parse(d);
        for (const i of this.infoJurusan) {
          if (i.jurusan_id === update.jurusan_id) {
            i.kuota = update.kuota;
            i.deskripsi = update.deskripsi;
            i.kompetensi_lulusan = update.kompetensi_lulusan;
            i.industri_rekanan = update.industri_rekanan;
            i.url_grup_wa = update.url_grup_wa;
          }
        }
      },
      infoJurusan: [],
      kode_jurusan: null,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
  },
  mounted() {
    this.getInfoJurusan();
    WebSocketService.on("upserted-info-jurusan", this.updateListener);
  },
  beforeUnmount() {
    WebSocketService.off("upserted-info-jurusan", this.updateListener);
  },
  watch: {},
  methods: {
    async getInfoJurusan() {
      let memuat = this.$loading.show({
        container: this.$refs.infoJurusan,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/data/info-jurusan', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.infoJurusan = response.data.info_jurusan;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    generateQRCode(d) {
      if (d.url_grup_wa) {
        const qrCodeModal = new Modal(document.getElementById('qrCode'));
        qrCodeModal.show();
        const text = d.url_grup_wa
        const judul = `SKAPSA ${d.kode} ${this.taAktif.tahun_ajaran_id}`
        const judulModal = this.$refs.judulModal
        judulModal.innerHTML = judul
        const canvas = this.$refs.qrCodeCanvas
        canvas.style.backgroundColor = 'transparent'

        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        QRCode.toDataURL(text, function (error, url) {
          if (error) {
            console.error(error)
          } else {
            const img = new Image()
            img.src = url
            img.onload = function () {
              canvas.width = img.width
              canvas.height = img.height

              ctx.fillStyle = 'white'
              ctx.fillRect(0, 0, canvas.width, canvas.height)
              ctx.drawImage(img, 0, 0)
            }
          }
        }.bind(this))
      } else {
        this.$swal({
          title: 'Perhatian',
          text: `URL untuk gabung Grup WhatsApp belum terisi!`,
          icon: 'info',
          showConfirmButton: true,
        });
      }
    },
  },
}
</script>

<style scoped>
.list-info-jurusan {
  transition: transform 0.5s;
}

.list-info-jurusan-move {
  transition: transform 0.5s;
}
</style>