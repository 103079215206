<template>
  <div class="card-body">
    <div class="row row-cols-auto justify-content-between mb-2">
      <div class="col-12 col-md-auto d-inline-flex">
        <h5 class="my-auto">Data Pembagian Seragam</h5>
      </div>
      <div v-if="adminKhusus" class="col-auto d-inline-flex">
        <select class="form-select form-select-sm shadow-sm my-auto" v-model="ta">
          <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
        </select>
      </div>
      <div v-else class="col-auto d-inline-flex">
        <h6 class="bg-white rounded px-2 py-1 shadow my-auto">{{ ta.nama }}</h6>
      </div>
    </div>
    <div class="row g-1 mb-3">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <v-select class="bg-white rounded shadow" v-model="pendaftar" placeholder="Pendaftar" label="no_reg_nama"
          :options="listPendaftar">
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
          <template #option="option">
            <strong>({{ option.no_reg }})</strong> {{ option.nama }}
          </template>
          <template #no-options>
            Pendaftar tidak ditemukan.
          </template>
        </v-select>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-if="adminKhusus">
        <v-select class="bg-white rounded shadow" v-model="petugas" placeholder="Petugas Seragam" label="nama_petugas"
          :options="listPetugas">
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
          <template #no-options>
            Petugas Seragam tidak ditemukan.
          </template>
        </v-select>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="input-group input-group-sm w-auto">
          <span class="input-group-text">Tanggal</span>
          <input type="date" class="form-control form-control-sm" v-model="tanggal" @change="gantiTanggal">
        </div>
      </div>
    </div>
    <div class="table-responsive vl-parent shadow" ref="dataPembagian">
      <form @submit.prevent="updateData">
        <table class="table table-sm mb-0 text-nowrap">
          <thead class="table-secondary align-middle">
            <tr>
              <th>#</th>
              <th class="position-sticky start-0">No. Reg.</th>
              <th>Nama</th>
              <th>Jenis Seragam</th>
              <th>Dibagikan</th>
              <th>Diperbarui</th>
              <th>Dihapus</th>
              <th>Tindakan</th>
            </tr>
          </thead>
          <tbody v-if="dataPembagian.length">
            <tr v-for="d, index in dataPembagian" :key="d">
              <td>{{ index+=1 + pageOffset }}</td>
              <td class="position-sticky start-0" :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                {{ d.no_reg }}</td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">{{ d.nama }}</td>
              <td v-if="d.edit" style="min-width: 220px;">
                <v-select class="bg-white rounded shadow" style="min-width: 150px;" v-model="formEditSeragam"
                  :placeholder="d.nama_seragam" label="nama" :options="listSeragam" :clearable="false" append-to-body
                  :calculate-position="withPopper">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formEditSeragam" v-bind="attributes" v-on="events" />
                  </template>
                  <template #no-options>
                    Jenis Seragam tidak ditemukan.
                  </template>
                </v-select>
              </td>
              <td v-else :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <transition name="fade" mode="out-in">
                  <span :key="d.nama_seragam">{{ d.nama_seragam }}</span>
                </transition>
              </td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <div class="fw-bold">{{ d.creator }}</div>
                <small class="fst-italic">{{ d.created_at }}</small>
              </td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <transition name="fade" mode="out-in">
                  <div class="fw-bold" :key="d.editor">{{ d.editor || '-' }}</div>
                </transition>
                <transition name="fade" mode="out-in">
                  <small class="fst-italic" :key="d.updated_at">{{ d.updated_at }}</small>
                </transition>
              </td>
              <td>
                <transition name="fade" mode="out-in">
                  <div class="fw-bold" :key="d.deleter">{{ d.deleter || '-' }}</div>
                </transition>
                <transition name="fade" mode="out-in">
                  <small class="fst-italic" :key="d.deleted_at">{{ d.deleted_at }}</small>
                </transition>
              </td>
              <td v-if="d.deleted_at">-</td>
              <td v-else-if="d.edit">
                <div class="hstack gap-1">
                  <button class="btn btn-sm btn-outline-dark" @click="batalEdit(d)">Batal</button>
                  <div class="vr"></div>
                  <button type="submit" class="btn btn-sm btn-primary" :disabled="sedangMengunggah">
                    <span v-if="sedangMengunggah" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span v-if="sedangMengunggah" role="status">Loading...</span>
                    <span v-else>Simpan</span>
                  </button>
                </div>
              </td>
              <td v-else>
                <div class="hstack gap-1">
                  <button type="button" class="btn btn-sm btn-outline-primary d-flex" @click="editData(d)"
                    v-tooltip="'Edit Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                      viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button type="button" class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)"
                    v-tooltip="'Hapus Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="8">Data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
    <span v-if="dataPembagian.length" class="small text-muted fst-italic">
      Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataPembagian.length }} dari total {{ count }} data
    </span>
  </div>
  <div v-if="dataPembagian.length" class="card-footer">
    <div class="row row-cols-auto justify-content-sm-between justify-content-center">
      <div class="row row-cols-auto">
        <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
        <div class="col-auto ps-1">
          <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
            <option v-for="l in arrayLimit" :key="l" :value="l">
              {{ l }} baris
            </option>
          </select>
        </div>
      </div>
      <div class="col-auto">
        <ul class="pagination pagination-sm shadow mb-0">
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
              @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
          </li>
          <li class="page-item" v-for="p in  pageCount " :key="p">
            <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
            <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
              @click="pageCurrent = p">{{ p }}</button>
            <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
        (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
              @click="pageCurrent = p">{{ p }}</button>
            <button
              v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
              class="page-link" @click="pageCurrent = p">{{ p }}</button>
            <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
        (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
        (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
              @click="pageCurrent = p">...</button>
          </li>
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
              @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
import { createPopper } from '@popperjs/core'
export default {
  name: 'DataPembagianSeragam',
  data() {
    return {
      insertedPpdbListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          this.getListPendaftar();
        }
      },
      updatePpdbListener: (d) => {
        const update = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == update.tahun_ajaran_id) {
          this.getListPendaftar();
        }
      },
      deletePpdbListener: (d) => {
        const del = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == del.tahun_ajaran_id) {
          this.getListPendaftar();
        }
        if (this.dataPendaftar) {
          if (this.dataPendaftar.ppdb_id == del.ppdb_id) {
            this.pendaftar = null;
          }
        }
      },
      insertedSeragamListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          if (this.selectedData) {
            this.getListSeragamTersedia(this.selectedData);
          }
        }
      },
      updateSeragamListener: (d) => {
        const update = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == update.tahun_ajaran_id) {
          this.getDataPembagian(2);
          if (this.selectedData) {
            this.getListSeragamTersedia(this.selectedData);
          }
        }
      },
      deleteSeragamListener: (d) => {
        const del = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == del.tahun_ajaran_id) {
          this.getDataPembagian(1);
          if (this.selectedData) {
            this.getListSeragamTersedia(this.selectedData);
          }
        }
      },
      insertedPembagianListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          this.getDataPembagian(1);
        }
      },
      updatePembagianListener: (d) => {
        const update = JSON.parse(d);
        for (const i of this.dataPembagian) {
          if (i.pembagian_seragam_id === update.pembagian_seragam_id) {
            if (i.edit) {
              i.edit = false;
            }
            this.getDataPembagian(2);
            break;
          }
        }
      },
      deletePembagianListener: (d) => {
        const del = JSON.parse(d);
        for (const i of this.dataPembagian) {
          if (i.pembagian_seragam_id === del.pembagian_seragam_id) {
            if (i.edit) {
              i.edit = false;
            }
            this.getDataPembagian(2);
            break;
          }
        }
      },
      adminKhusus: false,
      listTa: [],
      listPetugas: [],
      listPendaftar: [],
      listSeragam: [],
      dataPembagian: [],
      ta: this.$store.getters.getTahunAjaran,
      pendaftar: null,
      petugas: null,
      tanggal: null,
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: this.$store.getters.getDefaultLimit,
      pageCount: 0,
      selectedData: {},
      formEditSeragam: null,
      sedangMengunggah: false,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.adminKhusus = String(this.user.ket).split(",").some((k) => {
      return ["it", "ppdb-khusus"].includes(k);
    });
    this.getListTa();
    this.getListPendaftar();
    this.getDataPembagian(0);
    this.getListPetugas();
    WebSocketService.on("inserted-ppdb", this.insertedPpdbListener);
    WebSocketService.on("updated-ppdb", this.updatePpdbListener);
    WebSocketService.on("deleted-ppdb", this.deletePpdbListener);
    WebSocketService.on("inserted-seragam", this.insertedSeragamListener);
    WebSocketService.on("updated-seragam", this.updateSeragamListener);
    WebSocketService.on("deleted-seragam", this.deleteSeragamListener);
    WebSocketService.on("inserted-pembagian", this.insertedPembagianListener);
    WebSocketService.on("updated-pembagian", this.updatePembagianListener);
    WebSocketService.on("deleted-pembagian", this.deletePembagianListener);
  },
  watch: {
    ta() {
      if (this.pageCurrent == 1) {
        this.getDataPembagian(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    pendaftar() {
      if (this.pageCurrent == 1) {
        this.getDataPembagian(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    petugas() {
      if (this.pageCurrent == 1) {
        this.getDataPembagian(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataPembagian(1);
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataPembagian(1);
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  beforeUnmount() {
    WebSocketService.off("inserted-ppdb", this.insertedPpdbListener);
    WebSocketService.off("updated-ppdb", this.updatePpdbListener);
    WebSocketService.off("deleted-ppdb", this.deletePpdbListener);
    WebSocketService.off("inserted-seragam", this.insertedSeragamListener);
    WebSocketService.off("updated-seragam", this.updateSeragamListener);
    WebSocketService.off("deleted-seragam", this.deleteSeragamListener);
    WebSocketService.off("inserted-pembagian", this.insertedPembagianListener);
    WebSocketService.off("updated-pembagian", this.updatePembagianListener);
    WebSocketService.off("deleted-pembagian", this.deletePembagianListener);
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
    async getListTa() {
      try {
        const response = await this.axios.post('/list/tahun-ajaran', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTa = response.data.tahun_ajaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListPendaftar() {
      try {
        const response = await this.axios.post('/list/pendaftar', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listPendaftar = response.data.pendaftar;
          for (const p of this.listPendaftar) {
            p.no_reg_nama = "(" + p.no_reg + ") " + p.nama;
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListPetugas() {
      try {
        const response = await this.axios.post('/list/petugas-seragam', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listPetugas = response.data.petugas;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    gantiTanggal() {
      if (this.pageCurrent == 1) {
        this.getDataPembagian(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    async getDataPembagian(n) {
      let memuat;
      if (n != 2) {
        memuat = this.$loading.show({
          container: this.$refs.dataPembagian,
          loader: n ? 'dots' : 'spinner'
        });
      }
      try {
        const response = await this.axios.post('/data/pembagian-seragam', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          limit: this.pageLimit,
          offset: this.pageOffset,
          ppdb_id: Object(this.pendaftar).ppdb_id,
          created_by: Object(this.petugas).user_id,
          tanggal: this.tanggal,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataPembagian = response.data.pembagian;
          for (const i of this.dataPembagian) {
            i.edit = false;
            i.created_at = new Date(i.created_at).toLocaleString('id-ID')
            if (i.updated_at) {
              i.updated_at = new Date(i.updated_at).toLocaleString('id-ID')
            }
            if (i.deleted_at) {
              i.deleted_at = new Date(i.deleted_at).toLocaleString('id-ID')
            }
          }
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      if (n != 2) {
        setTimeout(() => {
          memuat.hide();
        }, 250);
      }
    },
    async getListSeragamTersedia(d) {
      try {
        const response = await this.axios.post('/list/seragam', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          ppdb_id: d.ppdb_id,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listSeragam = response.data.seragam;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    editData(d) {
      this.selectedData = { ...d }
      this.getListSeragamTersedia(d);
      for (const i of this.dataPembagian) {
        i.edit = i.pembagian_seragam_id === d.pembagian_seragam_id;
      }
    },
    batalEdit(d) {
      d.edit = false;
      this.formEditSeragam = null;
    },
    async updateData() {
      this.sedangMengunggah = true;
      try {
        const response = await this.axios.post('/pembagian-seragam/perbarui', {
          pembagian_seragam_id: this.selectedData.pembagian_seragam_id,
          seragam_id: this.formEditSeragam.seragam_id,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.sedangMengunggah = false;
          })
        }
      }
      catch (err) {
        this.sedangMengunggah = false;
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Pembagian Seragam <code class="text-black text-bg-secondary bg-opacity-50">${d.nama} ~ ${d.nama_seragam}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(d);
        }
      });
    },
    async deleteData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/pembagian-seragam/hapus', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          pembagian_seragam_id: d.pembagian_seragam_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err);
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style></style>