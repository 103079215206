<template>
  <h4>Penerimaan Peserta Didik Baru {{ ta.nama }}</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="row row-cols-auto justify-content-between mb-2">
        <div class="col-12 col-md-auto d-inline-flex">
          <h5 class="my-auto">Data Riwayat Pernyataan</h5>
        </div>
        <div class="col-auto d-inline-flex">
          <select class="form-select form-select-sm shadow-sm my-auto" v-model="ta">
            <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
          </select>
        </div>
      </div>
      <div class="row g-1">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="keperluan" placeholder="Jenis Keperluan" label="nama"
            :options="listKeperluan">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Jenis keperluan tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="asal_jurusan" placeholder="Asal Jurusan" label="nama"
            :options="listJurusan">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Jurusan tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="jurusan_tujuan" placeholder="Jurusan Tujuan" label="nama"
            :options="listJurusan">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Jurusan tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="teller" placeholder="Petugas Pendaftaran"
            label="nama_petugas" :options="listTeller">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Petugas Pendaftaran tidak ditemukan.
            </template>
          </v-select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 ms-auto">
          <input id="cari" type="search" class="form-control form-control-sm shadow-sm" placeholder="Cari . . ."
            v-model="key" @keyup="search" @search="search">
        </div>
      </div>
      <div class="table-responsive vl-parent shadow" ref="dataPernyataan">
        <table class="table table-sm mb-0 text-nowrap">
          <thead class="table-secondary align-middle">
            <tr>
              <th rowspan="2">#</th>
              <th class="position-sticky start-0" rowspan="2">No. Reg.</th>
              <th rowspan="2">Nama</th>
              <th rowspan="2">Jenis Keperluan</th>
              <th colspan="2">Jurusan</th>
              <th colspan="2">Status</th>
              <th rowspan="2" style="min-width: 150px;">Alasan</th>
              <th rowspan="2">Orang Tua</th>
              <th rowspan="2">Dibuat</th>
              <th rowspan="2">Diperbarui</th>
              <th rowspan="2">Dihapus</th>
              <th rowspan="2">Tindakan</th>
            </tr>
            <tr>
              <th class="small">Dari</th>
              <th class="small">Ke</th>
              <th class="small">Dari</th>
              <th class="small">Ke</th>
            </tr>
          </thead>
          <tbody v-if="dataPernyataan.length">
            <tr v-for="d, index in dataPernyataan" :key="d">
              <td>{{ index+=1 + pageOffset }}</td>
              <td class="position-sticky start-0" :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                {{ d.no_reg }}</td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <transition name="fade" mode="out-in">
                  <span :key="d.nama">{{ d.nama }}</span>
                </transition>
              </td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <transition name="fade" mode="out-in">
                  <span :key="d.jenis_keperluan">{{ jenisKeperluan[d.jenis_keperluan] }}</span>
                </transition>
              </td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <transition name="fade" mode="out-in">
                  <span :key="d.kode_asal_jurusan">{{ d.kode_asal_jurusan }}</span>
                </transition>
              </td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <transition name="fade" mode="out-in">
                  <span :key="d.kode_jurusan_tujuan">{{ d.kode_jurusan_tujuan || '-' }}</span>
                </transition>
              </td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <transition name="fade" mode="out-in">
                  <span :key="d.nama_asal_status">{{ d.nama_asal_status }}</span>
                </transition>
              </td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <transition name="fade" mode="out-in">
                  <span :key="d.nama_status_tujuan">{{ d.nama_status_tujuan || '-' }}</span>
                </transition>
              </td>
              <td class="text-wrap" :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <transition name="fade" mode="out-in">
                  <span :key="d.alasan">{{ d.alasan }}</span>
                </transition>
              </td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <transition name="fade" mode="out-in">
                  <span :key="d.orang_tua">{{ d.orang_tua }}</span>
                </transition>
              </td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <div class="fw-bold">{{ d.creator }}</div>
                <small class="fst-italic">{{ d.created_at }}</small>
              </td>
              <td :class="{ 'text-decoration-line-through fst-italic': d.deleted_at }">
                <transition name="fade" mode="out-in">
                  <div class="fw-bold" :key="d.editor">{{ d.editor || '-' }}</div>
                </transition>
                <transition name="fade" mode="out-in">
                  <small class="fst-italic" :key="d.updated_at">{{ d.updated_at }}</small>
                </transition>
              </td>
              <td>
                <transition name="fade" mode="out-in">
                  <div class="fw-bold" :key="d.deleter">{{ d.deleter || '-' }}</div>
                </transition>
                <transition name="fade" mode="out-in">
                  <small class="fst-italic" :key="d.deleted_at">{{ d.deleted_at }}</small>
                </transition>
              </td>
              <td>
                <div class="hstack gap-1" v-if="!d.deleted_at">
                  <button type="button" class="btn btn-sm btn-outline-secondary d-flex" @click="cetak(d)"
                    v-tooltip="'Cetak Surat Pernyataan'"><svg xmlns="http://www.w3.org/2000/svg" width="13"
                      fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path
                        d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button type="button" class="btn btn-sm btn-outline-primary d-flex" @click="editData(d)"
                    data-bs-toggle="modal" data-bs-target="#editData" v-tooltip="'Edit Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                      viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button type="button" class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)"
                    v-tooltip="'Hapus Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
                <div v-else>-</div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="14">Data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <span v-if="dataPernyataan.length" class="small text-muted fst-italic">
        Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataPernyataan.length }} dari total {{ count }} data
      </span>
    </div>
    <div v-if="dataPernyataan.length" class="card-footer">
      <div class="row row-cols-auto justify-content-sm-between justify-content-center">
        <div class="row row-cols-auto">
          <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
          <div class="col-auto ps-1">
            <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
              <option v-for="l in arrayLimit" :key="l" :value="l">
                {{ l }} baris
              </option>
            </select>
          </div>
        </div>
        <div class="col-auto">
          <ul class="pagination pagination-sm shadow mb-0">
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
                @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
            </li>
            <li class="page-item" v-for="p in  pageCount " :key="p">
              <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
              <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button
                v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
                class="page-link" @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
    (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
                @click="pageCurrent = p">...</button>
            </li>
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
                @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formEdit">
        <form @submit.prevent="updateData">
          <div class="modal-header position-sticky top-0 bg-body z-2">
            <legend class="modal-title">Perbarui Data Pernyataan</legend>
            <div class="hstack gap-2">
              <h5 class="border border-2 rounded px-4 py-2 my-auto shadow text-nowrap">No. Reg. {{ formEdit.no_reg }}
              </h5>
              <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-8 col-lg-6">
                <input type="text" readonly class="form-control-plaintext fw-bold" :value="formEdit.nama">
              </div>
            </div>
            <div class="row">
              <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Asal Jurusan</label>
              <div class="col-sm-8 col-lg-6">
                <input type="text" readonly class="form-control-plaintext fw-bold" :value="formEdit.nama_asal_jurusan">
              </div>
            </div>
            <div class="row">
              <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Jenis Keperluan</label>
              <div class="col-sm-8 col-lg-6">
                <div class="row row-cols-auto gap-3 row-gap-0" style="padding: 5px 12px;">
                  <div v-for="k in listKeperluan" :key="k" class="form-check col-auto">
                    <input class="form-check-input" name="jenis_keperluan" :id="'k' + k.jenis_keperluan" type="radio"
                      :value="k" v-model="formEdit.keperluan" required>
                    <label class="form-check-label" :for="'k' + k.jenis_keperluan">{{ k.nama }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2" v-if="formEdit.keperluan && formEdit.keperluan.jenis_keperluan == 1">
              <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Jurusan Tujuan</label>
              <div class="col-sm-8 col-lg-6">
                <v-select class="bg-white rounded shadow" v-model="formEdit.jurusan" placeholder="Pilih Jurusan Tujuan"
                  label="nama" :options="listJurusan" :selectable="(option) => option.penuh == 0"
                  :disabled="dataAwal.status_ppdb_id > 1">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formEdit.jurusan && formEdit.keperluan.jenis_keperluan == 1"
                      v-bind="attributes" v-on="events" />
                  </template>
                  <template #option="option">
                    <div v-if="option.penuh" class="fst-italic">
                      {{ option.nama }} (penuh)
                    </div>
                    <div v-else>{{ option.nama }}</div>
                  </template>
                  <template #no-options>
                    Jurusan tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Alasan</label>
              <div class="col-sm-8 col-lg-6">
                <textarea rows="3" class="form-control form-control-sm shadow"
                  :placeholder="'Alasan ' + jenisKeperluan[formEdit.jenis_keperluan]" v-model="formEdit.alasan"
                  required></textarea>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Orang Tua/Wali</label>
              <div class="col-sm-8 col-lg-6">
                <input type="text" class="form-control form-control-sm shadow" placeholder="Nama Orang Tua/Wali Murid"
                  v-model="formEdit.orang_tua" required>
              </div>
            </div>
          </div>
          <div class="modal-footer position-sticky bottom-0 bg-body z-2">
            <button type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'DataPernyataan',
  data() {
    return {
      insertListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          this.getDataPernyataan(1);
        }
      },
      updateListener: (d) => {
        const update = JSON.parse(d);
        for (const i of this.dataPernyataan) {
          if (i.sp_ppdb_id === update.sp_ppdb_id) {
            if (this.formEdit.sp_ppdb_id === update.sp_ppdb_id) {
              document.getElementById('tutupFormEdit').click();
            }
            this.getDataPernyataan(2);
            break;
          }
        }
      },
      deleteListener: (d) => {
        const del = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == del.tahun_ajaran_id) {
          for (const i of this.dataPernyataan) {
            if (i.sp_ppdb_id === del.sp_ppdb_id) {
              if (this.formEdit.sp_ppdb_id === del.sp_ppdb_id) {
                document.getElementById('tutupFormEdit').click();
              }
              this.getDataPernyataan(2);
              break;
            }
          }
        }
      },
      listTa: [],
      listKeperluan: [
        {
          jenis_keperluan: 1,
          nama: 'Pindah Jurusan'
        },
        {
          jenis_keperluan: 2,
          nama: 'Mengundurkan Diri'
        }
      ],
      listJurusan: [],
      jenisKeperluan: ["", "Pindah Jurusan", "Mengundurkan Diri"],
      listTeller: [],
      dataPernyataan: [],
      ta: this.$store.getters.getTahunAjaran,
      key: null,
      keperluan: null,
      asal_jurusan: null,
      jurusan_tujuan: null,
      teller: null,
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: this.$store.getters.getDefaultLimit,
      pageCount: 0,
      dataAwal: {},
      formEdit: {},
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.getListTa();
    this.getDataPernyataan(0);
    this.getListJurusan();
    this.getListTeller();
    WebSocketService.on("inserted-pernyataan", this.insertListener);
    WebSocketService.on("updated-pernyataan", this.updateListener);
    WebSocketService.on("deleted-pernyataan", this.deleteListener);
  },
  watch: {
    ta() {
      if (this.pageCurrent == 1) {
        this.getDataPernyataan(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    keperluan() {
      if (this.pageCurrent == 1) {
        this.getDataPernyataan(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    asal_jurusan() {
      if (this.pageCurrent == 1) {
        this.getDataPernyataan(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    jurusan_tujuan() {
      if (this.pageCurrent == 1) {
        this.getDataPernyataan(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    teller() {
      if (this.pageCurrent == 1) {
        this.getDataPernyataan(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataPernyataan(1);
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataPernyataan(1);
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  beforeUnmount() {
    WebSocketService.off("inserted-pernyataan", this.insertListener);
    WebSocketService.off("updated-pernyataan", this.updateListener);
    WebSocketService.off("deleted-pernyataan", this.deleteListener);
  },
  methods: {
    search: debounce(function () {
      if (this.pageCurrent == 1) {
        this.getDataPernyataan(1);
      } else {
        this.pageCurrent = 1;
      }
    }, 500),
    async getListTa() {
      try {
        const response = await this.axios.post('/list/tahun-ajaran', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTa = response.data.tahun_ajaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListTeller() {
      try {
        const response = await this.axios.post('/list/teller', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTeller = response.data.teller;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataPernyataan(n) {
      let memuat;
      if (n != 2) {
        memuat = this.$loading.show({
          container: this.$refs.dataPernyataan,
          loader: n ? 'dots' : 'spinner'
        });
      }
      try {
        const response = await this.axios.post('/data/pernyataan', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          limit: this.pageLimit,
          offset: this.pageOffset,
          key: this.key,
          jenis_keperluan: Object(this.keperluan).jenis_keperluan,
          asal_jurusan: Object(this.asal_jurusan).jurusan_id,
          jurusan_tujuan: Object(this.jurusan_tujuan).jurusan_id,
          created_by: Object(this.teller).user_id,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataPernyataan = response.data.pernyataan;
          for (const i of this.dataPernyataan) {
            i.created_at = new Date(i.created_at).toLocaleString('id-ID')
            if (i.updated_at) {
              i.updated_at = new Date(i.updated_at).toLocaleString('id-ID')
            }
            if (i.deleted_at) {
              i.deleted_at = new Date(i.deleted_at).toLocaleString('id-ID')
            }
          }
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      if (n != 2) {
        setTimeout(() => {
          memuat.hide();
        }, 250);
      }
    },
    async getListJurusan() {
      try {
        const response = await this.axios.post('/list/jurusan', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listJurusan = response.data.jurusan;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    cetak(d) {
      const routeCetak = this.$router.resolve({
        path: '/cetak/pernyataan/' + d.sp_ppdb_id
      });
      window.open(routeCetak.href, '_blank');
    },
    editData(d) {
      this.dataAwal = d;
      this.dataAwal.keperluan = this.listKeperluan.filter(k => k.jenis_keperluan == d.jenis_keperluan)[0];
      this.dataAwal.jurusan = this.dataAwal.ke_jurusan ? this.listJurusan.filter(j => j.jurusan_id == d.ke_jurusan)[0] : null;
      this.formEdit = { ...this.dataAwal }
    },
    async updateData() {
      let dataSama = JSON.stringify(this.formEdit) === JSON.stringify(this.dataAwal);
      if (dataSama) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan!',
          icon: 'info',
          showConfirmButton: true,
        })
      } else {
        try {
          const response = await this.axios.post('/pernyataan/perbarui', {
            sp_ppdb_id: this.formEdit.sp_ppdb_id,
            ppdb_id: this.formEdit.ppdb_id,
            jenis_keperluan: this.formEdit.keperluan.jenis_keperluan,
            jurusan_id: this.formEdit.keperluan.jenis_keperluan == 1 ? this.formEdit.jurusan.jurusan_id : null,
            alasan: this.formEdit.alasan,
            orang_tua: this.formEdit.orang_tua,
          }, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.cetak(this.formEdit)
            })
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Pernyataan <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(d);
        }
      });
    },
    async deleteData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/pernyataan/hapus', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          sp_ppdb_id: d.sp_ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err);
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style scoped></style>