<template>
  <div class="card-body">
    <h5>Informasi Jurusan</h5>
    <div class="table-responsive shadow vl-parent" ref="infoJurusan">
      <table class="table table-sm mb-0">
        <thead class="table-secondary align-middle">
          <tr>
            <th>#</th>
            <th>Jurusan</th>
            <th>Detail</th>
          </tr>
        </thead>
        <tbody v-if="infoJurusan.length">
          <tr v-for="d, index in infoJurusan" :key="d">
            <td>{{ index+=1 }}</td>
            <td>{{ d.nama }} ({{ d.kode }})</td>
            <td v-if="!d.edit" class="position-relative small">
              <span class="fw-bold">Kuota :</span>
              <transition name="fade" mode="out-in">
                <span class="mb-1 ms-1" :key="d.kuota">{{ d.kuota }}</span>
              </transition>
              <div class="fw-bold">Deskripsi :</div>
              <transition name="fade" mode="out-in">
                <div class="mb-1" :key="d.deskripsi">{{ d.deskripsi || '-' }}</div>
              </transition>
              <div class="fw-bold">Kompetensi Lulusan :</div>
              <transition name="fade" mode="out-in">
                <div class="mb-1" :key="d.kompetensi_lulusan">{{ d.kompetensi_lulusan || '-' }}</div>
              </transition>
              <div class="fw-bold">Industri Rekanan :</div>
              <transition name="fade" mode="out-in">
                <div class="mb-1" :key="d.industri_rekanan">{{ d.industri_rekanan || '-' }}</div>
              </transition>
              <div class="fw-bold">URL Grup WhatsApp :</div>
              <transition name="fade" mode="out-in">
                <div class="mb-1" :key="d.url_grup_wa"><a :href="d.url_grup_wa">{{ d.url_grup_wa || '-' }}</a></div>
              </transition>
              <button class="btn btn-sm btn-outline-primary position-absolute top-0 end-0 m-1 border-0"
                v-tooltip="'Edit Info Jurusan'" @click="editData(d)">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                  viewBox="0 0 16 16">
                  <path
                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                </svg>
              </button>
            </td>
            <td v-else>
              <form @submit.prevent="updateData">
                <div class="form-floating">
                  <input type="number" class="form-control mb-1" v-model="formEdit.kuota" placeholder="Kuota" min="1"
                    required>
                  <label>Kuota</label>
                </div>
                <div class="form-floating">
                  <textarea class="form-control mb-1" v-model="formEdit.deskripsi" style="height: 100px"
                    placeholder="Deskripsi Jurusan"></textarea>
                  <label>Deskripsi</label>
                </div>
                <div class="form-floating">
                  <textarea class="form-control mb-1" v-model="formEdit.kompetensi_lulusan" style="height: 100px"
                    placeholder="Kompetensi Lulusan"></textarea>
                  <label>Kompetensi Lulusan</label>
                </div>
                <div class="form-floating">
                  <textarea class="form-control mb-1" v-model="formEdit.industri_rekanan" style="height: 100px"
                    placeholder="Industri Rekanan"></textarea>
                  <label>Industri Rekanan</label>
                </div>
                <div class="form-floating">
                  <textarea class="form-control mb-1" v-model="formEdit.url_grup_wa" style="height: 100px"
                    placeholder="URL Grup WhatsApp"></textarea>
                  <label>URL Grup WhatsApp</label>
                </div>
                <div class="hstack justify-content-end gap-1">
                  <button type="button" class="btn btn-outline-dark" @click="batalEdit(d)">Batal
                  </button>
                  <div class="vr"></div>
                  <button type="submit" class="btn btn-primary" :disabled="sedangMengunggah">
                    <span v-if="sedangMengunggah" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span v-if="sedangMengunggah" role="status">Loading...</span>
                    <span v-else>Simpan</span>
                  </button>
                </div>
              </form>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center fst-italic py-3" colspan="3">Data tidak ditemukan.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
  name: 'InfoJurusan',
  data() {
    return {
      updateListener: (d) => {
        const update = JSON.parse(d);
        for (const i of this.infoJurusan) {
          if (i.jurusan_id === update.jurusan_id) {
            i.edit = false;
            i.kuota = update.kuota;
            i.deskripsi = update.deskripsi;
            i.kompetensi_lulusan = update.kompetensi_lulusan;
            i.industri_rekanan = update.industri_rekanan;
            i.url_grup_wa = update.url_grup_wa;
          }
        }
      },
      infoJurusan: [],
      formEdit: {},
      sedangMengunggah: false
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
  },
  mounted() {
    this.getDataInfoJurusan();
    WebSocketService.on("upserted-info-jurusan", this.updateListener);
  },
  watch: {
  },
  beforeUnmount() {
    WebSocketService.off("upserted-info-jurusan", this.updateListener);

  },
  methods: {
    async getDataInfoJurusan() {
      let memuat = this.$loading.show({
        container: this.$refs.infoJurusan,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/data/info-jurusan', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.infoJurusan = response.data.info_jurusan;
          for (const i of this.infoJurusan) {
            i.edit = false
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    editData(d) {
      for (const i of this.infoJurusan) {
        i.edit = i.jurusan_id === d.jurusan_id;
      }
      this.formEdit = { ...d }
    },
    batalEdit(d) {
      d.edit = false;
    },
    async updateData() {
      this.sedangMengunggah = true;
      const awal = this.infoJurusan.filter((i) => i.jurusan_id == this.formEdit.jurusan_id)[0];
      let perubahan = false;
      if (JSON.stringify(awal) === JSON.stringify(this.formEdit)) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan!',
          icon: 'info',
          showConfirmButton: true,
        }).then(() => {
          this.sedangMengunggah = false;
        })
      } else {
        perubahan = true;
        try {
          const response = await this.axios.post('/setting/info-jurusan/tambah-perbarui', {
            jurusan_id: this.formEdit.jurusan_id,
            kuota: this.formEdit.kuota,
            deskripsi: this.formEdit.deskripsi || '-',
            kompetensi_lulusan: this.formEdit.kompetensi_lulusan || '-',
            industri_rekanan: this.formEdit.industri_rekanan || '-',
            url_grup_wa: this.formEdit.url_grup_wa || '-',
          }, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
        catch (err) {
          this.sedangMengunggah = false;
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
      setTimeout(() => {
        this.sedangMengunggah = perubahan ? false : true;
      }, 250);
    }
  }
}
</script>

<style></style>