<template>
  <h4>Penerimaan Peserta Didik Baru {{ taAktif.nama }}</h4>
  <div class="card shadow">
    <h5 class="card-header">Formulir Pembayaran Daftar Ulang</h5>
    <form @submit.prevent="insertData">
      <div class="card-body vl-parent" ref="formPembayaran">
        <div class="row mb-2">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Pendaftar</label>
          <div class="col-sm-7 col-lg-5">
            <v-select class="bg-white rounded shadow" v-model="pendaftar" placeholder="Pendaftar" label="no_reg_nama"
              :options="listPendaftar">
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!pendaftar" v-bind="attributes" v-on="events" />
              </template>
              <template #option="option">
                <strong>({{ option.no_reg }})</strong> {{ option.nama }}
              </template>
              <template #no-options>
                Pendaftar tidak ditemukan.
              </template>
            </v-select>
          </div>
        </div>
        <div v-if="dataPendaftar" class="row mb-2">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Data Pendaftar</label>
          <div class="col-sm-8 col-md-9 col-lg-10">
            <table class="table table-sm table-striped small shadow mb-0">
              <tbody>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>No. Reg.</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.no_reg }}</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Nama</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.nama }}</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Jurusan</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.nama_jurusan }} ({{ dataPendaftar.kode }})</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Jalur Pendaftaran</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.jalur_pendaftaran }}</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Status Pendaftaran</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.status_pendaftaran }}</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Waktu Pendaftaran</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.created_at }}</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Biaya Daftar Ulang</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ biaya_du.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }) }}
                    <span v-if="dataPendaftar.pembawa_ppdb_id && dataPendaftar.jalur_ppdb_id == 1" class="fst-italic">
                      (dengan voucher)
                    </span>
                    <span v-if="dataPendaftar.jalur_ppdb_id !== 1" class="fst-italic"> (Jalur {{
    dataPendaftar.jalur_pendaftaran }})</span>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Kekurangan</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ kekurangan.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }) }}</th>
                </tr>
              </tbody>
            </table>
            <div v-if="dataPendaftar.status_ppdb_id == 1 && (jurusan_penuh || jalur_penuh)"
              class="hstack gap-1 text-danger small fst-italic">
              <span class="mb-auto">*)</span>
              <p v-if="jurusan_penuh && jalur_penuh" class="mb-0" style="text-align: justify;text-justify: inter-word;">
                Kuota jurusan {{ dataPendaftar.nama_jurusan }} dan pendaftaran jalur {{ dataPendaftar.jalur_pendaftaran
                }}
                sudah penuh. Silahkan ganti jurusan dan jalur pendaftaran melalui fitur edit pada menu data pendaftaran
                untuk melanjutkan!</p>
              <p v-else-if="jurusan_penuh" class="mb-0" style="text-align: justify;text-justify: inter-word;">Kuota
                jurusan {{ dataPendaftar.nama_jurusan }} sudah penuh. Silahkan ganti jurusan melalui fitur edit pada
                menu
                data pendaftaran untuk melanjutkan!</p>
              <p v-else class="mb-0" style="text-align: justify;text-justify: inter-word;">Kuota pendaftaran jalur {{
    dataPendaftar.jalur_pendaftaran }} sudah penuh. Silahkan ganti jalur pendaftaran melalui fitur edit pada
                menu data pendaftaran untuk melanjutkan!</p>
            </div>
          </div>
        </div>
        <div v-if="dataPendaftar" class="row mb-2">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Riwayat Pembayaran</label>
          <div class="col-sm-8 col-md-9 col-lg-10">
            <table class="table table-sm table-striped table-bordered border-secondary small text-center shadow mb-0">
              <thead class="table-secondary border-secondary">
                <tr>
                  <th>No</th>
                  <th>Waktu Pembayaran</th>
                  <th>Nominal Pembayaran</th>
                </tr>
              </thead>
              <tbody v-if="riwayatPembayaran.length">
                <tr v-for="r, index in riwayatPembayaran" :key="r">
                  <td>{{ index+=1 }}</td>
                  <td>{{ r.created_at }}</td>
                  <td>{{ r.nominal.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }) }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center fst-italic py-3" colspan="3">Riwayat pembayaran tidak ditemukan.</td>
                </tr>
              </tbody>
              <tfoot v-if="riwayatPembayaran.length" class="table-secondary border-secondary">
                <tr>
                  <th colspan="2">Total Pembayaran</th>
                  <th>{{ total_pembayaran.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
                    maximumFractionDigits: 0,
                    }) }}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Nominal</label>
          <div class="col-sm-6 col-lg-4">
            <input type="text" class="form-control form-control-sm shadow" placeholder="Nominal Pembayaran"
              v-model="input_nominal" required pattern="^(Rp\s)(\d{1,3}(?:\.\d{3})+)" title="Nominal pembayaran">
          </div>
        </div>
        <div class="row">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Petugas Keuangan</label>
          <div class="col-sm-6 col-lg-4">
            <v-select class="bg-white rounded shadow" v-model="petugas_keuangan" placeholder="Petugas Keuangan"
              label="nama" :options="listKeuangan">
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!petugas_keuangan" v-bind="attributes" v-on="events" />
              </template>
              <template #no-options>
                Petugas Keuangan tidak ditemukan.
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <input type="submit" class="btn btn-primary px-4" value="Simpan">
      </div>
    </form>
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'DaftarUlangView',
  data() {
    return {
      updateKuotaListener: () => {
        this.getListJurusan();
      },
      updateGelombangListener: () => {
        if (this.dataPendaftar) {
          this.getBiayaDu(1);
        }
      },
      updateJalurListener: () => {
        this.getListJalur();
        if (this.dataPendaftar) {
          this.getBiayaDu(1);
        }
      },
      updateKeuanganListener: () => {
        this.getListKeuangan();
      },
      insertedPpdbListener: (d) => {
        const insert = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == insert.tahun_ajaran_id) {
          this.getListPendaftar();
        }
      },
      updatePpdbListener: (d) => {
        const update = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == update.tahun_ajaran_id) {
          this.getListPendaftar();
        }
        if (this.dataPendaftar) {
          if (this.dataPendaftar.ppdb_id == update.ppdb_id) {
            this.getDataPendaftar();
            this.getBiayaDu(1);
          }
        }
      },
      deletePpdbListener: (d) => {
        const del = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == del.tahun_ajaran_id) {
          this.getListPendaftar();
        }
        if (this.dataPendaftar) {
          if (this.dataPendaftar.ppdb_id == del.ppdb_id) {
            this.pendaftar = null;
          }
        }
      },
      pembayaranListener: (d) => {
        const data = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == data.tahun_ajaran_id) {
          if (this.dataPendaftar) {
            if (this.dataPendaftar.ppdb_id == data.ppdb_id) {
              this.getDataPendaftar();
              this.getBiayaDu(1);
            }
          }
          this.getListJurusan();
          this.getListJalur();
        }
      },
      pernyataanListener: (d) => {
        const data = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getListJurusan();
          this.getListJalur();
        }
      },
      listPendaftar: [],
      listJurusan: [],
      listJalur: [],
      listKeuangan: [],
      pendaftar: null,
      nominal: null,
      input_nominal: null,
      petugas_keuangan: null,
      dataPendaftar: null,
      riwayatPembayaran: [],
      biaya_du: 0,
      total_pembayaran: 0,
      kekurangan: 0,
      jurusan_penuh: false,
      jalur_penuh: false,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
  },
  mounted() {
    let memuat = this.$loading.show({
      container: this.$refs.formPembayaran,
      loader: 'spinner'
    });
    WebSocketService.on("upserted-info-jurusan", this.updateKuotaListener);
    WebSocketService.on("updated-jalur", this.updateJalurListener);
    WebSocketService.on("inserted-ppdb", this.insertedPpdbListener);
    WebSocketService.on("updated-ppdb", this.updatePpdbListener);
    WebSocketService.on("deleted-ppdb", this.deletePpdbListener);
    WebSocketService.on("inserted-keuangan", this.updateKeuanganListener);
    WebSocketService.on("updated-keuangan", this.updateKeuanganListener);
    WebSocketService.on("deleted-keuangan", this.updateKeuanganListener);
    WebSocketService.on("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.on("updated-pembayaran", this.pembayaranListener);
    WebSocketService.on("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.on("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.on("updated-pernyataan", this.pernyataanListener);
    WebSocketService.on("deleted-pernyataan", this.pernyataanListener);
    this.getListPendaftar();
    this.getListJurusan();
    this.getListJalur();
    this.getListKeuangan();
    setTimeout(() => {
      memuat.hide();
    }, 250);
  },
  beforeUnmount() {
    WebSocketService.off("upserted-info-jurusan", this.updateKuotaListener);
    WebSocketService.off("updated-jalur", this.updateJalurListener);
    WebSocketService.off("inserted-ppdb", this.insertedPpdbListener);
    WebSocketService.off("updated-ppdb", this.updatePpdbListener);
    WebSocketService.off("deleted-ppdb", this.deletePpdbListener);
    WebSocketService.off("inserted-keuangan", this.updateKeuanganListener);
    WebSocketService.off("updated-keuangan", this.updateKeuanganListener);
    WebSocketService.off("deleted-keuangan", this.updateKeuanganListener);
    WebSocketService.off("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.off("updated-pembayaran", this.pembayaranListener);
    WebSocketService.off("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.off("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.off("updated-pernyataan", this.pernyataanListener);
    WebSocketService.off("deleted-pernyataan", this.pernyataanListener);
  },
  watch: {
    pendaftar() {
      if (this.pendaftar) {
        let memuat = this.$loading.show({
          container: this.$refs.formPembayaran,
          loader: 'spinner'
        });
        this.getDataPendaftar();
        this.getBiayaDu(0);
        setTimeout(() => {
          memuat.hide();
        }, 250);
      } else {
        this.dataPendaftar = null;
        this.riwayatPembayaran = [];
        this.biaya_du = null;
        this.kekurangan = null;
        this.jurusan_penuh = false;
        this.jalur_penuh = false;
      }
    },
    input_nominal(newValue) {
      let input = new String(newValue).replace(/[^,\d]/g, '');
      const formattedValue = new Intl.NumberFormat('id-ID', {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0,
      }).format(input);
      this.input_nominal = formattedValue;
      this.nominal = new Number(input)
    }
  },
  methods: {
    async getListPendaftar() {
      try {
        const response = await this.axios.post('/list/pendaftar', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listPendaftar = response.data.pendaftar;
          for (const p of this.listPendaftar) {
            p.no_reg_nama = "(" + p.no_reg + ") " + p.nama;
          }
          if (this.pendaftar) {
            let ditemukan = 0;
            for (const p of this.listPendaftar) {
              if (this.pendaftar.ppdb_id == p.ppdb_id) {
                ditemukan++;
                this.pendaftar = p;
              }
            }
            if (!ditemukan) {
              this.pendaftar = null;
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataPendaftar() {
      try {
        const response = await this.axios.post('/data/pendaftaran', {
          ppdb_id: Object(this.pendaftar).ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataPendaftar = response.data.pendaftar[0];
          this.dataPendaftar.created_at = new Date(this.dataPendaftar.created_at).toLocaleString('id-ID', { dateStyle: 'long', timeStyle: 'medium' });
          this.jurusan_penuh = false;
          this.jalur_penuh = false;
          for (const j of this.listJurusan) {
            if (j.jurusan_id == this.dataPendaftar.jurusan_id && j.penuh) {
              this.jurusan_penuh = true;
            }
          }
          for (const j of this.listJalur) {
            if (j.jalur_ppdb_id == this.dataPendaftar.jalur_ppdb_id && j.penuh) {
              this.jalur_penuh = true;
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getRiwayatPembayaran() {
      try {
        const response = await this.axios.post('/data/pembayaran', {
          ppdb_id: Object(this.pendaftar).ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.riwayatPembayaran = response.data.pembayaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getBiayaDu(n) {
      try {
        const response = await this.axios.post('/data/biaya-du', {
          ppdb_id: Object(this.pendaftar).ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.biaya_du = response.data.biaya_du;
          this.total_pembayaran = 0;
          if (!n) {
            await this.getRiwayatPembayaran();
          }
          for (const r of this.riwayatPembayaran) {
            r.created_at = new Date(r.created_at).toLocaleString('id-ID', { dateStyle: 'long', timeStyle: 'medium' });
            this.total_pembayaran += r.nominal;
          }
          this.kekurangan = Math.max(0, this.biaya_du - this.total_pembayaran);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListJurusan() {
      try {
        const response = await this.axios.post('/list/jurusan', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listJurusan = response.data.jurusan;
          this.jurusan_penuh = false;
          if (this.dataPendaftar) {
            for (const j of this.listJurusan) {
              if (j.jurusan_id == this.dataPendaftar.jurusan_id && j.penuh) {
                this.jurusan_penuh = true;
              }
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListJalur() {
      try {
        const response = await this.axios.post('/list/jalur', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listJalur = response.data.jalur;
          this.jalur_penuh = false;
          if (this.dataPendaftar) {
            for (const j of this.listJalur) {
              if (j.jalur_ppdb_id == this.dataPendaftar.jalur_ppdb_id && j.penuh) {
                this.jalur_penuh = true;
              }
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListKeuangan() {
      try {
        const response = await this.axios.post('/list/keuangan', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listKeuangan = response.data.keuangan;
          if (this.petugas_keuangan) {
            let ditemukan = 0;
            for (const k of this.listKeuangan) {
              if (this.petugas_keuangan.petugas_keuangan_id == k.petugas_keuangan_id) {
                ditemukan++;
                this.petugas_keuangan = k;
              }
            }
            if (!ditemukan) {
              this.petugas_keuangan = null;
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async insertData() {
      let memuat = this.$loading.show({
        container: this.$refs.formPembayaran,
        loader: 'spinner'
      });
      if (this.dataPendaftar.status_ppdb_id == 1 && this.jurusan_penuh && this.jalur_penuh) {
        this.$swal({
          title: 'Perhatian',
          text: `Kuota jurusan ${this.dataPendaftar.nama_jurusan} dan pendaftaran jalur ${this.dataPendaftar.jalur_pendaftaran} sudah penuh!
            Silahkan ganti jurusan dan jalur pendaftaran melalui fitur edit pada menu data pendaftaran untuk melanjutkan!`,
          icon: 'info',
          showConfirmButton: true,
        });
      } else if (this.dataPendaftar.status_ppdb_id == 1 && this.jurusan_penuh) {
        this.$swal({
          title: 'Perhatian',
          text: `Kuota jurusan ${this.dataPendaftar.nama_jurusan} sudah penuh!
            Silahkan ganti jurusan melalui fitur edit pada menu data pendaftaran untuk melanjutkan!`,
          icon: 'info',
          showConfirmButton: true,
        });
      } else if (this.dataPendaftar.status_ppdb_id == 1 && this.jalur_penuh) {
        this.$swal({
          title: 'Perhatian',
          text: `Kuota pendaftaran jalur ${this.dataPendaftar.jalur_pendaftaran} sudah penuh!
            Silahkan ganti jalur pendaftaran melalui fitur edit pada menu data pendaftaran untuk melanjutkan!`,
          icon: 'info',
          showConfirmButton: true,
        });
      } else {
        try {
          const response = await this.axios.post('/pembayaran/tambah', {
            ppdb_id: Object(this.pendaftar.ppdb_id),
            nominal: this.nominal,
            petugas_keuangan_id: this.petugas_keuangan.petugas_keuangan_id
          }, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              const routeCetak = this.$router.resolve({
                path: '/cetak/pembayaran/' + this.pendaftar.ppdb_id
              });
              this.pendaftar = null;
              this.input_nominal = null;
              this.nominal = null;
              this.petugas_keuangan = null;
              window.open(routeCetak.href, '_blank');
            });
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
      setTimeout(() => {
        memuat.hide()
      }, 250);
    },
  }
}
</script>

<style></style>