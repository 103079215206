<template>
  <h4>Penerimaan Peserta Didik Baru {{ ta.nama }}</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="row row-cols-auto justify-content-between gap-2">
        <div class="col-12 col-md-auto d-inline-flex">
          <h5 class="my-auto">Data Pembawa PPDB</h5>
        </div>
        <div class="col-auto d-inline-flex">
          <button type="button" class="btn btn-sm btn-secondary hstack gap-1 shadow-sm my-auto" data-bs-toggle="modal"
            data-bs-target="#tambahData">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-plus-lg"
              viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
            </svg>Tambahkan Data
          </button>
        </div>
        <div v-if="adminKhusus" class="col-auto  d-inline-flex">
          <select class="form-select form-select-sm shadow-sm my-auto" v-model="ta">
            <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 ms-auto">
          <input id="cari" type="search" class="form-control form-control-sm shadow-sm" placeholder="Cari . . ."
            v-model="key" @keyup="search" @search="search">
        </div>
      </div>
      <div class="table-responsive shadow vl-parent" ref="dataPembawa">
        <form @submit.prevent="updateData">
          <table class="table table-sm table-hover mb-0">
            <thead class="table-secondary align-middle">
              <tr>
                <th>#</th>
                <th>NIK/NIS</th>
                <th>Nama</th>
                <th>Kontak</th>
                <th>Keterangan</th>
                <th>Potongan</th>
                <th>Pendaftar</th>
                <th>Diterima</th>
                <th>Tindakan</th>
              </tr>
            </thead>
            <tbody v-if="dataPembawa.length">
              <tr v-for="d, index in dataPembawa" :key="d">
                <td>{{ index+=1 + pageOffset }}</td>
                <td v-if="d.edit"><input type="text" class="form-control form-control-sm" v-model="formEdit.nik"
                    :placeholder="d.nik" required></td>
                <td v-else>
                  <transition name="fade" mode="out-in">
                    <span :key="d.nik">{{ d.nik }}</span>
                  </transition>
                </td>
                <td v-if="d.edit"><input type="text" class="form-control form-control-sm" v-model="formEdit.nama"
                    :placeholder="d.nama" required></td>
                <td v-else>
                  <transition name="fade" mode="out-in">
                    <span :key="d.nama">{{ d.nama }}</span>
                  </transition>
                </td>
                <td v-if="d.edit"><input type="text" class="form-control form-control-sm" v-model="formEdit.kontak"
                    :placeholder="d.kontak" required></td>
                <td v-else>
                  <transition name="fade" mode="out-in">
                    <span :key="d.kontak">{{ d.kontak }}</span>
                  </transition>
                </td>
                <td v-if="d.edit"><input type="text" class="form-control form-control-sm" v-model="formEdit.keterangan"
                    :placeholder="d.keterangan" required></td>
                <td v-else>
                  <transition name="fade" mode="out-in">
                    <span :key="d.keterangan">{{ d.keterangan }}</span>
                  </transition>
                </td>
                <td v-if="d.edit"><input type="number" class="form-control form-control-sm" v-model="formEdit.potongan"
                    :placeholder="d.potongan" required></td>
                <td v-else>
                  <transition name="fade" mode="out-in">
                    <span :key="d.potongan">{{ d.potongan }}</span>
                  </transition>
                </td>
                <td>
                  <transition name="slide-up" mode="out-in">
                    <span class="d-inline-block" :key="d.jumlah_pendaftar">{{ d.jumlah_pendaftar || 0 }}</span>
                  </transition>
                </td>
                <td>
                  <transition name="slide-up" mode="out-in">
                    <span class="d-inline-block" :key="d.jumlah_diterima">{{ d.jumlah_diterima || 0 }}</span>
                  </transition>
                </td>
                <td v-if="d.edit">
                  <div class="hstack gap-1">
                    <button class="btn btn-sm btn-outline-dark" @click="batalEdit(d)">Batal
                    </button>
                    <div class="vr"></div>
                    <button type="submit" class="btn btn-sm btn-primary" :disabled="sedangMengunggah">
                      <span v-if="sedangMengunggah" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span v-if="sedangMengunggah" role="status">Loading...</span>
                      <span v-else>Simpan</span>
                    </button>
                  </div>
                </td>
                <td v-else>
                  <div class="hstack gap-1">
                    <button type="button" class="btn btn-sm btn-outline-primary d-flex" @click="editData(d)"
                      v-tooltip="'Edit Data'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                        viewBox="0 0 16 16">
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </button>
                    <div v-if="adminKhusus" class="vr"></div>
                    <button v-if="adminKhusus" type="button" class="btn btn-sm btn-outline-danger d-flex"
                      @click="confirmDelete(d)" v-tooltip="'Hapus Data'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                        viewBox="0 0 16 16">
                        <path
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center fst-italic py-3" colspan="9">Data tidak ditemukan.</td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <span v-if="dataPembawa.length" class="small text-muted fst-italic">
        Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataPembawa.length }} dari total {{ count }} data
      </span>
    </div>
    <div v-if="dataPembawa.length" class="card-footer">
      <div class="row row-cols-auto justify-content-sm-between justify-content-center">
        <div class="row row-cols-auto">
          <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
          <div class="col-auto ps-1">
            <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
              <option v-for="l in arrayLimit" :key="l" :value="l">
                {{ l }} baris
              </option>
            </select>
          </div>
        </div>
        <div class="col-auto">
          <ul class="pagination pagination-sm shadow mb-0">
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
                @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
            </li>
            <li class="page-item" v-for="p in  pageCount " :key="p">
              <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
              <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button
                v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
                class="page-link" @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
    (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
                @click="pageCurrent = p">...</button>
            </li>
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
                @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="tambahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formCreate">
        <form @submit.prevent="insertData">
          <div class="modal-header">
            <legend class="modal-title">Tambah Data Pembawa PPDB</legend>
            <button type="button" id="tutupFormCreate" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">NIK/NIS</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="NIK (alumni)/NIS (siswa aktif)"
                  v-model="formCreate.nik" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama Pembawa PPDB"
                  v-model="formCreate.nama" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Kontak</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nomor Telepon"
                  v-model="formCreate.kontak" pattern="[0-9]{10,13}"
                  title="Nomor telepon setidaknya terdiri dari 10 hingga 13 digit" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Keterangan</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Alumni/Nama Rombel (siswa aktif)"
                  v-model="formCreate.keterangan" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Potongan</label>
              <div class="col-sm-9">
                <input type="number" class="form-control form-control-sm" placeholder="Nominal Potongan Voucher"
                  v-model="formCreate.potongan" required>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'DataPembawa',
  data() {
    return {
      insertListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          this.getDataPembawa(2);
        }
      },
      updateListener: (d) => {
        const update = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == update.tahun_ajaran_id) {
          for (const i of this.dataPembawa) {
            if (i.pembawa_ppdb_id === update.pembawa_ppdb_id) {
              i.edit = false;
              i.nik = update.nik;
              i.nama = update.nama;
              i.kontak = update.kontak;
              i.keterangan = update.keterangan;
              i.potongan = update.potongan;
            }
          }
        }
      },
      deleteListener: (d) => {
        const del = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == del.tahun_ajaran_id) {
          if (this.pageCurrent == this.pageCount && this.pageCurrent > 1) {
            if (this.dataPembawa.length == 1) {
              this.pageCurrent = 1;
            } else {
              this.getDataPembawa(2);
            }
          } else {
            this.getDataPembawa(2);
          }
        }
      },
      insertPpdbListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id && insert.pembawa_ppdb_id) {
          for (const p of this.dataPembawa) {
            if (p.pembawa_ppdb_id == insert.pembawa_ppdb_id) {
              p.jumlah_pendaftar++;
            }
          }
        }
      },
      updatePpdbListener: (d) => {
        const update = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == update.tahun_ajaran_id) {
          this.getDataPembawa(0);
        }
      },
      deletePpdbListener: (d) => {
        const del = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == del.tahun_ajaran_id) {
          this.getDataPembawa(0);
        }
      },
      pembayaranListener: (d) => {
        const data = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getDataPembawa(0);
        }
      },
      pernyataanListener: (d) => {
        const data = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getDataPembawa(0);
        }
      },
      adminKhusus: false,
      dataPembawa: [],
      listTa: [],
      ta: this.$store.getters.getTahunAjaran,
      key: null,
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: this.$store.getters.getDefaultLimit,
      pageCount: 0,
      formCreate: {
        nik: null,
        nama: null,
        kontak: null,
        keterangan: null,
        potongan: null,
      },
      formEdit: {},
      sedangMengunggah: false,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.adminKhusus = String(this.user.ket).split(",").some((k) => {
      return ["it", "ppdb-khusus"].includes(k);
    });
    this.getListTa();
    this.getDataPembawa(1);
    WebSocketService.on("inserted-pembawa", this.insertListener);
    WebSocketService.on("updated-pembawa", this.updateListener);
    WebSocketService.on("deleted-pembawa", this.deleteListener);
    WebSocketService.on("inserted-ppdb", this.insertPpdbListener);
    WebSocketService.on("updated-ppdb", this.updatePpdbListener);
    WebSocketService.on("deleted-ppdb", this.deletePpdbListener);
    WebSocketService.on("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.on("updated-pembayaran", this.pembayaranListener);
    WebSocketService.on("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.on("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.on("updated-pernyataan", this.pernyataanListener);
    WebSocketService.on("deleted-pernyataan", this.pernyataanListener);
  },
  watch: {
    ta() {
      if (this.pageCurrent == 1) {
        this.getDataPembawa(2);
      } else {
        this.pageCurrent = 1;
      }
    },
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataPembawa(2);
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataPembawa(2);
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  beforeUnmount() {
    WebSocketService.off("inserted-pembawa", this.insertListener);
    WebSocketService.off("updated-pembawa", this.updateListener);
    WebSocketService.off("deleted-pembawa", this.deleteListener);
    WebSocketService.off("inserted-ppdb", this.insertPpdbListener);
    WebSocketService.off("updated-ppdb", this.updatePpdbListener);
    WebSocketService.off("deleted-ppdb", this.deletePpdbListener);
    WebSocketService.off("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.off("updated-pembayaran", this.pembayaranListener);
    WebSocketService.off("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.off("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.off("updated-pernyataan", this.pernyataanListener);
    WebSocketService.off("deleted-pernyataan", this.pernyataanListener);
  },
  methods: {
    search: debounce(function () {
      if (this.pageCurrent == 1) {
        this.getDataPembawa(2);
      } else {
        this.pageCurrent = 1;
      }
    }, 500),
    async getListTa() {
      try {
        const response = await this.axios.post('/list/tahun-ajaran', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTa = response.data.tahun_ajaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataPembawa(n) {
      let memuat;
      if (n) {
        memuat = this.$loading.show({
          container: this.$refs.dataPembawa,
          loader: n == 2 ? 'dots' : 'spinner'
        });
      }
      try {
        const response = await this.axios.post('/data/pembawa', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          limit: this.pageLimit,
          offset: this.pageOffset,
          key: this.key,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataPembawa = response.data.pembawa;
          for (const i of this.dataPembawa) {
            i.edit = false;
          }
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      if (n) {
        setTimeout(() => {
          memuat.hide();
        }, 250);
      }
    },
    async insertData() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      this.formCreate.tahun_ajaran_id = this.ta.tahun_ajaran_id;
      try {
        const response = await this.axios.post('/setting/pembawa/tambah', this.formCreate, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          document.getElementById('tutupFormCreate').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.formCreate = {
              nik: null,
              nama: null,
              kontak: null,
              keterangan: null,
              potongan: null,
            }
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                                message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide()
      }, 250);
    },
    editData(d) {
      for (const i of this.dataPembawa) {
        i.edit = i.pembawa_ppdb_id === d.pembawa_ppdb_id;
      }
      this.formEdit = { ...d }
    },
    batalEdit(d) {
      d.edit = false;
    },
    async updateData() {
      this.sedangMengunggah = true;
      const awal = this.dataPembawa.filter((i) => i.pembawa_ppdb_id == this.formEdit.pembawa_ppdb_id)[0];
      let perubahan = false;
      if (JSON.stringify(awal) === JSON.stringify(this.formEdit)) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan!',
          icon: 'info',
          showConfirmButton: true,
        }).then(() => {
          this.sedangMengunggah = false;
        })
      } else {
        perubahan = true;
        try {
          const response = await this.axios.post('/setting/pembawa/perbarui', {
            pembawa_ppdb_id: this.formEdit.pembawa_ppdb_id,
            tahun_ajaran_id: this.ta.tahun_ajaran_id,
            nik: this.formEdit.nik,
            nama: this.formEdit.nama,
            kontak: this.formEdit.kontak,
            keterangan: this.formEdit.keterangan,
            potongan: this.formEdit.potongan,
          }, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
        catch (err) {
          this.sedangMengunggah = false;
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                                    message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
      setTimeout(() => {
        this.sedangMengunggah = perubahan ? false : true;
      }, 250);
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Pembawa PPDB <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span><br/>
                    <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(d);
        }
      });
    },
    async deleteData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/setting/pembawa/hapus', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          pembawa_ppdb_id: d.pembawa_ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err);
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style></style>