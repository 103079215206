<template>
  <h4>Pembagian Seragam</h4>
  <div class="card shadow">
    <div class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item">
          <router-link to="/seragam" class="nav-link" active-class="active">Pembagian</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/seragam/data-pembagian" class="nav-link" active-class="active">Data Pembagian</router-link>
        </li>
      </ul>
    </div>
    <pembagian-seragam v-if="!$route.params.tab" />
    <data-pembagian-seragam v-else-if="$route.params.tab == 'data-pembagian'" />
    <not-found v-else class="card-body" />
  </div>
</template>

<script>
import PembagianSeragam from '@/components/PembagianSeragam'
import DataPembagianSeragam from '@/components/DataPembagianSeragam'
import NotFound from '@/components/NotFound'
export default {
  name: 'SeragamView',
  components: {
    PembagianSeragam,
    DataPembagianSeragam,
    NotFound,
  },
  data() {
    return {}
  },
}
</script>

<style></style>