<template>
  <h4>Penerimaan Peserta Didik Baru {{ taAktif.nama }}</h4>
  <div class="card shadow">
    <h5 class="card-header">Formulir Pernyataan Pindah Jurusan/Mengundurkan Diri</h5>
    <form @submit.prevent="insertData">
      <div class="card-body vl-parent" ref="formPernyataan">
        <div class="row mb-2">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Pendaftar</label>
          <div class="col-sm-7 col-lg-5">
            <v-select class="bg-white rounded shadow" v-model="pendaftar" placeholder="Pendaftar" label="no_reg_nama"
              :options="listPendaftar">
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!pendaftar" v-bind="attributes" v-on="events" />
              </template>
              <template #option="option">
                <strong>({{ option.no_reg }})</strong> {{ option.nama }}
              </template>
              <template #no-options>
                Pendaftar tidak ditemukan.
              </template>
            </v-select>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Keperluan</label>
          <div class="col-auto">
            <div class="row row-cols-auto gap-3 row-gap-0" style="padding: 5px 12px;">
              <div v-for="k in listKeperluan" :key="k" class="form-check col-auto">
                <input class="form-check-input" name="jenis_keperluan" :id="'k' + k.jenis_keperluan" type="radio"
                  :value="k" v-model="keperluan" required>
                <label class="form-check-label" :for="'k' + k.jenis_keperluan">{{ k.nama }}</label>
              </div>
            </div>
          </div>
        </div>
        <div v-if="keperluan && keperluan.jenis_keperluan == 1" class="row mb-2">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Jurusan</label>
          <div class="col-sm-7 col-lg-5">
            <v-select class="bg-white rounded shadow" v-model="jurusan" placeholder="Pilih Jurusan Tujuan" label="nama"
              :options="listJurusan" :selectable="(option) => option.penuh == 0" :selectOnTab="true" :clearable="false">
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!jurusan && keperluan.jenis_keperluan == 1" v-bind="attributes"
                  v-on="events" />
              </template>
              <template #option="option">
                <div v-if="option.penuh" class="fst-italic">
                  {{ option.nama }} (penuh)
                </div>
                <div v-else>{{ option.nama }}</div>
              </template>
              <template #no-options>
                Jurusan tidak ditemukan.
              </template>
            </v-select>
          </div>
        </div>
        <div v-if="keperluan" class="row mb-2">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Alasan</label>
          <div class="col-sm-7 col-lg-5">
            <textarea rows="3" class="form-control form-control-sm shadow" :placeholder="'Alasan ' + keperluan.nama"
              v-model="alasan" required></textarea>
          </div>
        </div>
        <div v-if="keperluan" class="row">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Orang Tua/Wali</label>
          <div class="col-sm-7 col-lg-5">
            <input type="text" class="form-control form-control-sm shadow" placeholder="Nama Orang Tua/Wali Murid"
              v-model="orang_tua" required>
          </div>
        </div>
        <div v-if="dataPendaftar" class="row my-2">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Data Pendaftar</label>
          <div class="col-sm-8 col-md-9 col-lg-10">
            <table class="table table-sm table-striped small shadow mb-0">
              <tbody>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>No. Reg.</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.no_reg }}</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Nama</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.nama }}</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Jurusan</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.nama_jurusan }} ({{ dataPendaftar.kode }})</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Jalur Pendaftaran</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.jalur_pendaftaran }}</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Status Pendaftaran</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.status_pendaftaran }}</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Waktu Pendaftaran</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ dataPendaftar.created_at }}</th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Biaya Daftar Ulang</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ biaya_du.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }) }}
                    <span v-if="dataPendaftar.pembawa_ppdb_id && dataPendaftar.jalur_ppdb_id == 1" class="fst-italic">
                      (dengan voucher)
                    </span>
                    <span v-if="dataPendaftar.jalur_ppdb_id !== 1" class="fst-italic"> (Jalur {{
    dataPendaftar.jalur_pendaftaran }})</span>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>Kekurangan</span>
                      <span>:</span>
                    </div>
                  </td>
                  <th>{{ kekurangan.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }) }}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="dataPendaftar" class="row">
          <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Riwayat Pembayaran</label>
          <div class="col-sm-8 col-md-9 col-lg-10">
            <table class="table table-sm table-striped table-bordered border-secondary small text-center shadow mb-0">
              <thead class="table-secondary border-secondary">
                <tr>
                  <th>No</th>
                  <th>Waktu Pembayaran</th>
                  <th>Nominal Pembayaran</th>
                </tr>
              </thead>
              <tbody v-if="riwayatPembayaran.length">
                <tr v-for="r, index in riwayatPembayaran" :key="r">
                  <td>{{ index+=1 }}</td>
                  <td>{{ r.created_at }}</td>
                  <td>{{ r.nominal.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }) }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center fst-italic py-3" colspan="3">Riwayat pembayaran tidak ditemukan.</td>
                </tr>
              </tbody>
              <tfoot v-if="riwayatPembayaran.length" class="table-secondary border-secondary">
                <tr>
                  <th colspan="2">Total Pembayaran</th>
                  <th>{{ total_pembayaran.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }) }}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <input type="submit" class="btn btn-primary px-4" value="Simpan">
      </div>
    </form>
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'PernyataanView',
  data() {
    return {
      updateKuotaListener: () => {
        this.getListJurusan();
      },
      updateGelombangListener: () => {
        if (this.dataPendaftar) {
          this.getBiayaDu(1);
        }
      },
      updateJalurListener: () => {
        this.getListJalur();
        if (this.dataPendaftar) {
          this.getBiayaDu(1);
        }
      },
      insertedPpdbListener: (d) => {
        const insert = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == insert.tahun_ajaran_id) {
          this.getListPendaftar();
        }
      },
      updatePpdbListener: (d) => {
        const update = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == update.tahun_ajaran_id) {
          this.getListPendaftar();
        }
        if (this.dataPendaftar) {
          if (this.dataPendaftar.ppdb_id == update.ppdb_id) {
            this.getDataPendaftar();
            this.getBiayaDu(1);
          }
        }
      },
      deletePpdbListener: (d) => {
        const del = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == del.tahun_ajaran_id) {
          this.getListPendaftar();
        }
        if (this.dataPendaftar) {
          if (this.dataPendaftar.ppdb_id == del.ppdb_id) {
            this.pendaftar = null;
          }
        }
      },
      pembayaranListener: (d) => {
        const data = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == data.tahun_ajaran_id) {
          if (this.dataPendaftar) {
            if (this.dataPendaftar.ppdb_id == data.ppdb_id) {
              this.getDataPendaftar();
              this.getBiayaDu(1);
            }
          }
          this.getListJurusan();
        }
      },
      pernyataanListener: (d) => {
        const data = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getListJurusan();
        }
      },
      listPendaftar: [],
      listKeperluan: [
        {
          jenis_keperluan: 1,
          nama: 'Pindah Jurusan'
        },
        {
          jenis_keperluan: 2,
          nama: 'Mengundurkan Diri'
        }
      ],
      listJurusan: [],
      pendaftar: null,
      keperluan: null,
      jurusan: null,
      alasan: null,
      orang_tua: null,
      dataPendaftar: null,
      riwayatPembayaran: [],
      biaya_du: 0,
      total_pembayaran: 0,
      kekurangan: 0,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
  },
  mounted() {
    let memuat = this.$loading.show({
      container: this.$refs.formPernyataan,
      loader: 'spinner'
    });
    WebSocketService.on("upserted-info-jurusan", this.updateKuotaListener);
    WebSocketService.on("updated-jalur", this.updateJalurListener);
    WebSocketService.on("inserted-ppdb", this.insertedPpdbListener);
    WebSocketService.on("updated-ppdb", this.updatePpdbListener);
    WebSocketService.on("deleted-ppdb", this.deletePpdbListener);
    WebSocketService.on("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.on("updated-pembayaran", this.pembayaranListener);
    WebSocketService.on("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.on("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.on("updated-pernyataan", this.pernyataanListener);
    WebSocketService.on("deleted-pernyataan", this.pernyataanListener);
    this.getListPendaftar();
    this.getListJurusan();
    this.getListJalur();
    setTimeout(() => {
      memuat.hide();
    }, 250);
  },
  beforeUnmount() {
    WebSocketService.off("upserted-info-jurusan", this.updateKuotaListener);
    WebSocketService.off("updated-jalur", this.updateJalurListener);
    WebSocketService.off("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.off("updated-pembayaran", this.pembayaranListener);
    WebSocketService.off("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.off("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.off("updated-pernyataan", this.pernyataanListener);
    WebSocketService.off("deleted-pernyataan", this.pernyataanListener);
  },
  watch: {
    pendaftar() {
      if (this.pendaftar) {
        let memuat = this.$loading.show({
          container: this.$refs.formPernyataan,
          loader: 'spinner'
        });
        this.getDataPendaftar();
        this.getBiayaDu(0);
        setTimeout(() => {
          memuat.hide();
        }, 250);
      } else {
        this.dataPendaftar = null;
        this.keperluan = null;
        this.jurusan = null;
        this.alasan = null;
        this.orang_tua = null;
        this.riwayatPembayaran = [];
        this.biaya_du = null;
        this.kekurangan = null;
      }
    }
  },
  methods: {
    async getListPendaftar() {
      try {
        const response = await this.axios.post('/list/pendaftar', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listPendaftar = response.data.pendaftar;
          for (const p of this.listPendaftar) {
            p.no_reg_nama = "(" + p.no_reg + ") " + p.nama;
          }
          if (this.pendaftar) {
            let ditemukan = 0;
            for (const p of this.listPendaftar) {
              if (this.pendaftar.ppdb_id == p.ppdb_id) {
                ditemukan++;
                this.pendaftar = p;
              }
            }
            if (!ditemukan) {
              this.pendaftar = null;
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataPendaftar() {
      try {
        const response = await this.axios.post('/data/pendaftaran', {
          ppdb_id: Object(this.pendaftar).ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataPendaftar = response.data.pendaftar[0];
          this.dataPendaftar.created_at = new Date(this.dataPendaftar.created_at).toLocaleString('id-ID', { dateStyle: 'long', timeStyle: 'medium' });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getRiwayatPembayaran() {
      try {
        const response = await this.axios.post('/data/pembayaran', {
          ppdb_id: Object(this.pendaftar).ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.riwayatPembayaran = response.data.pembayaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getBiayaDu(n) {
      try {
        const response = await this.axios.post('/data/biaya-du', {
          ppdb_id: Object(this.pendaftar).ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.biaya_du = response.data.biaya_du;
          this.total_pembayaran = 0;
          if (!n) {
            await this.getRiwayatPembayaran();
          }
          for (const r of this.riwayatPembayaran) {
            r.created_at = new Date(r.created_at).toLocaleString('id-ID', { dateStyle: 'long', timeStyle: 'medium' });
            this.total_pembayaran += r.nominal;
          }
          this.kekurangan = Math.max(0, this.biaya_du - this.total_pembayaran);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListJurusan() {
      try {
        const response = await this.axios.post('/list/jurusan', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listJurusan = response.data.jurusan;
          for (const j of this.listJurusan) {
            if (this.jurusan) {
              if (this.jurusan.jurusan_id == j.jurusan_id && j.penuh) {
                this.jurusan = null;
                break;
              }
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListJalur() {
      try {
        const response = await this.axios.post('/list/jalur', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listJalur = response.data.jalur;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async insertData() {
      if (!this.riwayatPembayaran.length) {
        if (this.keperluan.jenis_keperluan == 1) {
          this.$swal({
            title: 'Perhatian',
            text: 'Pendaftar ini belum melakukan daftar ulang. Silahkan ganti jurusuan melalui fitur edit pada menu data pendaftaran.',
            icon: 'info',
            showConfirmButton: true,
          });
        } else {
          this.$swal({
            title: 'Perhatian',
            text: 'Pendaftar ini belum melakukan daftar ulang. Tidak diperlukan Surat Pernyataan untuk mengundurkan diri..',
            icon: 'info',
            showConfirmButton: true,
          });
        }
      } else {
        let memuat = this.$loading.show({
          container: this.$refs.formPernyataan,
          loader: 'spinner'
        });
        try {
          const response = await this.axios.post('/pernyataan/tambah', {
            ppdb_id: Object(this.pendaftar.ppdb_id),
            jurusan_id: Object(this.jurusan).jurusan_id,
            alasan: this.alasan,
            orang_tua: this.orang_tua
          }, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              const routeCetak = this.$router.resolve({
                path: '/cetak/pernyataan/' + response.data.insertId
              });
              this.pendaftar = null;
              window.open(routeCetak.href, '_blank');
            });
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
        setTimeout(() => {
          memuat.hide()
        }, 250);
      }
    },
  }
}
</script>

<style></style>