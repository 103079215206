<template>
  <h4>Penerimaan Peserta Didik Baru {{ taAktif.nama }}</h4>
  <div class="card shadow">
    <h5 class="card-header">Formulir Pendaftaran</h5>
    <form @submit.prevent="insertData">
      <div class="card-body vl-parent" ref="formPendaftaran">
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm align-items-center py-0">
            <div class="hstack gap-2"><span>NISN</span>
              <a href="https://nisn.data.kemdikbud.go.id/index.php/Cindex/caribynama/" target="blank"
                class="btn btn-sm btn-outline-primary border-0" v-tooltip:bottom="'Cari NISN'">
                <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor"
                  class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                  <path fill-rule="evenodd"
                    d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                </svg>
              </a>
            </div>
          </label>
          <div class="col-sm-6 col-lg-4">
            <input type="text" class="form-control form-control-sm shadow" placeholder="Nomor Induk Siswa Nasional"
              v-model="nisn" pattern="[0-9]{10}" title="NISN terdiri dari 10 digit angka" required>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">NIK</label>
          <div class="col-sm-6 col-lg-4">
            <input type="text" class="form-control form-control-sm shadow" placeholder="Nomor Induk Kependudukan"
              v-model="nik" pattern="[0-9]{16}" title="NIK terdiri dari 16 digit angka" required>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Nama</label>
          <div class="col-sm-9 col-lg-6">
            <input type="text" class="form-control form-control-sm shadow" style="text-transform: uppercase;"
              placeholder="Nama Lengkap Calon Peserta Didik Baru" v-model="nama" required>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Tempat Lahir</label>
          <div class="col-sm-6 col-lg-4">
            <v-select class="bg-white rounded shadow" v-model="tempat_lahir" placeholder="Tempat Lahir" label="nama"
              :options="listTempatLahir" :selectOnTab="true" :clearable="false" append-to-body
              :calculate-position="withPopper">
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!tempat_lahir" v-bind="attributes" v-on="events" />
              </template>
              <template #no-options>
                Tempat lahir tidak ditemukan.
              </template>
            </v-select>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Tanggal Lahir</label>
          <div class="col-sm-4 col-lg-3">
            <input type="date" class="form-control form-control-sm shadow" placeholder="Tanggal Lahir"
              v-model="tanggal_lahir" required>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Jenis Kelamin</label>
          <div class="col-sm-9 col-lg-10">
            <div class="row row-cols-auto gap-3 row-gap-0" style="padding: 5px 12px;">
              <div v-for="j in listJenisKelamin" :key="j" class="form-check col-auto">
                <input class="form-check-input" name="jenis_kelamin" :id="'jk' + j.singkatan" type="radio"
                  :value="j.singkatan" v-model="jenis_kelamin" required>
                <label class="form-check-label" :for="'jk' + j.singkatan">{{ j.nama }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Agama</label>
          <div class="col-sm-9 col-lg-10">
            <div class="row row-cols-auto gap-3 row-gap-0" style="padding: 5px 12px;">
              <div v-for="a in listAgama" :key="a" class="form-check col-auto">
                <input class="form-check-input" name="agama" :id="'ag' + a.agama_id" type="radio" :value="a.agama_id"
                  v-model="agama_id" required>
                <label class="form-check-label" :for="'ag' + a.agama_id">{{ a.nama }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Asal Sekolah</label>
          <div class="col-sm-9 col-lg-6">
            <v-select class="bg-white rounded shadow" v-model="asal_sekolah" :filterable="false"
              placeholder="Asal Sekolah" :options="listAsalSekolah" label="nama" @search="onSearch" :selectOnTab="true"
              :clearable="false" :dropdown-should-open="cariAsalSekolah" append-to-body :calculate-position="withPopper">
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!asal_sekolah" v-bind="attributes" v-on="events" />
              </template>
              <template #option="option">
                <div class="text-wrap">
                  {{ option.nama }} ~ <small class="fst-italic">{{ option.alamat }}</small>
                </div>
              </template>
              <template #no-options>
                Sekolah tidak ditemukan.
              </template>
            </v-select>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Jurusan</label>
          <div class="col-sm-9 col-lg-6">
            <v-select class="bg-white rounded shadow" v-model="jurusan" placeholder="Pilih Jurusan" label="nama"
              :options="listJurusan" :selectable="(option) => option.penuh == 0" :selectOnTab="true" :clearable="false"
              append-to-body :calculate-position="withPopper">
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!jurusan" v-bind="attributes" v-on="events" />
              </template>
              <template #option="option">
                <div v-if="option.penuh" class="fst-italic">
                  {{ option.nama }} (penuh)
                </div>
                <div v-else>{{ option.nama }}</div>
              </template>
              <template #no-options>
                Jurusan tidak ditemukan.
              </template>
            </v-select>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Pendidikan Dasar</label>
          <div class="col-sm-9 col-lg-10">
            <div class="row row-cols-auto gap-3 row-gap-0" style="padding: 5px 12px;">
              <div v-for="p in listPendidikanDasar" :key="p" class="form-check col-auto">
                <input class="form-check-input" name="pendidikan_dasar" :id="'pd' + p.singkatan" type="radio"
                  :value="p.singkatan" v-model="pendidikan_dasar" required>
                <label class="form-check-label" :for="'pd' + p.singkatan">{{ p.nama }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Alamat Domisili</label>
          <div class="col-sm-9 col-lg-10">
            <div class="row g-1">
              <div class="col-12 col-md-6 col-lg-4">
                <v-select class="bg-white rounded shadow" v-model="alamat_provinsi" placeholder="Provinsi" label="nama"
                  :options="listProv" :selectOnTab="true" :clearable="false" append-to-body
                  :calculate-position="withPopper">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!alamat_provinsi" v-bind="attributes" v-on="events" />
                  </template>
                  <template #no-options>
                    Provinsi tidak ditemukan.
                  </template>
                </v-select>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <v-select class="bg-white rounded shadow" v-model="alamat_kab_kota" placeholder="Kabupaten/Kota"
                  label="nama" :options="listKabKota" :selectOnTab="true" :clearable="false" append-to-body
                  :calculate-position="withPopper">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!alamat_kab_kota" v-bind="attributes" v-on="events" />
                  </template>
                  <template #no-options>
                    Kabupaten/Kota tidak ditemukan.
                  </template>
                </v-select>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <v-select class="bg-white rounded shadow" v-model="alamat_kec" placeholder="Kecamatan" label="nama"
                  :options="listKec" :selectOnTab="true" :clearable="false" append-to-body
                  :calculate-position="withPopper">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!alamat_kec" v-bind="attributes" v-on="events" />
                  </template>
                  <template #no-options>
                    Kecamatan tidak ditemukan.
                  </template>
                </v-select>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <v-select class="bg-white rounded shadow" v-model="alamat_desa" placeholder="Desa" label="nama"
                  :options="listDesa" :selectOnTab="true" :clearable="false" append-to-body
                  :calculate-position="withPopper">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!alamat_desa" v-bind="attributes" v-on="events" />
                  </template>
                  <template #no-options>
                    Desa tidak ditemukan.
                  </template>
                </v-select>
              </div>
              <div class="input-group">
                <div class="form-floating z-0 w-50">
                  <textarea type="text" class="form-control form-control-sm shadow" id="alamatLengkap"
                    placeholder="Alamat Lengkap" v-model="alamat" required></textarea>
                  <label for="alamatLengkap">Alamat Lengkap</label>
                </div>
                <div class="form-floating z-0">
                  <input type="number" class="form-control form-control-sm shadow" id="rt" placeholder="RT" v-model="rt"
                    required>
                  <label for="rt">RT</label>
                </div>
                <div class="form-floating z-0">
                  <input type="number" class="form-control form-control-sm shadow" id="rw" placeholder="RW" v-model="rw"
                    required>
                  <label for="rw">RW</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Kontak</label>
          <div class="col-sm-6 col-lg-4">
            <input type="text" class="form-control form-control-sm shadow"
              placeholder="Nomor Telepon Calon Peserta Didik Baru" v-model="kontak" pattern="[0-9]{10,13}"
              title="Nomor telepon setidaknya terdiri dari 10 hingga 13 digit" required>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Kontak Wali</label>
          <div class="col-sm-6 col-lg-4">
            <input type="text" class="form-control form-control-sm shadow" placeholder="Nomor Telepon Wali"
              v-model="kontak_wali" pattern="[0-9]{10,13}"
              title="Nomor telepon setidaknya terdiri dari 10 hingga 13 digit" required>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Pembawa PPDB</label>
          <div class="col-sm-6 col-lg-4">
            <v-select class="bg-white rounded shadow" v-model="pembawa_ppdb" placeholder="Pembawa PPDB" label="nik_nama"
              :options="listPembawa" :selectOnTab="true" append-to-body :calculate-position="withPopper">
              <template #no-options>
                Pembawa PPDB tidak ditemukan.
              </template>
            </v-select>
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Relasi</label>
          <div class="col-sm-6 col-lg-4">
            <input type="text" class="form-control form-control-sm shadow"
              placeholder="Relasi dengan Calon Peserta Didik Baru" v-model="relasi">
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Jalur Pendaftaran</label>
          <div class="col-sm-9 col-lg-10">
            <transition name="fade" mode="out-in">
              <div class="row row-cols-auto gap-3 row-gap-0" style="padding: 5px 12px;" :key="listJalur">
                <div v-for="j in listJalur" :key="j" class="form-check col-auto">
                  <input class="form-check-input" name="jalur_ppdb" :id="'jl' + j.jalur_ppdb_id" type="radio"
                    :value="j.jalur_ppdb_id" v-model="jalur_ppdb_id" required :disabled="j.penuh">
                  <label class="form-check-label" :class="{ 'fst-italic': j.penuh }" :for="'jl' + j.jalur_ppdb_id">{{
                    j.nama
                  }}<span v-if="j.penuh"> (penuh)</span></label>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Petugas CS</label>
          <div class="col-sm-6 col-lg-4">
            <v-select class="bg-white rounded shadow" v-model="petugas_cs" placeholder="Petugas CS" label="nama"
              :options="listCs" :selectOnTab="true" :clearable="false" append-to-body :calculate-position="withPopper">
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!petugas_cs" v-bind="attributes" v-on="events" />
              </template>
              <template #no-options>
                Petugas CS tidak ditemukan.
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <input type="submit" class="btn btn-primary px-4" value="Simpan">
      </div>
    </form>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import WebSocketService from '@/components/WebSocketService';
import { createPopper } from '@popperjs/core'
export default {
  name: 'PendaftaranView',
  data() {
    return {
      updateKuotaListener: () => {
        this.getListJurusan();
      },
      updateJalurListener: () => {
        this.getListJalur();
      },
      updateCsListener: () => {
        this.getListCs();
      },
      updatePembawaListener: (d) => {
        const data = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getListPembawa();
        }
      },
      pembayaranListener: (d) => {
        const data = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getListJurusan();
          this.getListJalur();
        }
      },
      pernyataanListener: (d) => {
        const data = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getListJurusan();
          this.getListJalur();
        }
      },
      listTempatLahir: [],
      listJenisKelamin: [
        {
          singkatan: 'L',
          nama: 'Laki-laki'
        },
        {
          singkatan: 'P',
          nama: 'Perempuan'
        },
      ],
      listAgama: [],
      listAsalSekolah: [],
      listJurusan: [],
      listPendidikanDasar: [
        {
          singkatan: 'SD',
          nama: 'Sekolah Dasar'
        },
        {
          singkatan: 'MI',
          nama: 'Madrasah Ibtidaiyyah'
        }
      ],
      listProv: [],
      listKabKota: [],
      listKec: [],
      listDesa: [],
      listPembawa: [],
      listJalur: [],
      listCs: [],
      nisn: null,
      nik: null,
      nama: null,
      tempat_lahir: null,
      tanggal_lahir: null,
      jenis_kelamin: null,
      agama_id: null,
      asal_sekolah: null,
      jurusan: null,
      pendidikan_dasar: null,
      alamat_provinsi: null,
      alamat_kab_kota: null,
      alamat_kec: null,
      alamat_desa: null,
      alamat: null,
      rt: null,
      rw: null,
      kontak: null,
      kontak_wali: null,
      pembawa_ppdb: null,
      relasi: null,
      jalur_ppdb_id: null,
      petugas_cs: null,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
  },
  mounted() {
    WebSocketService.on("upserted-info-jurusan", this.updateKuotaListener);
    WebSocketService.on("inserted-jalur", this.updateJalurListener);
    WebSocketService.on("updated-jalur", this.updateJalurListener);
    WebSocketService.on("updated-status-jalur", this.updateJalurListener);
    WebSocketService.on("deleted-jalur", this.updateJalurListener);
    WebSocketService.on("inserted-cs", this.updateCsListener);
    WebSocketService.on("updated-cs", this.updateCsListener);
    WebSocketService.on("deleted-cs", this.updateCsListener);
    WebSocketService.on("inserted-pembawa", this.updatePembawaListener);
    WebSocketService.on("updated-pembawa", this.updatePembawaListener);
    WebSocketService.on("deleted-pembawa", this.updatePembawaListener);
    WebSocketService.on("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.on("updated-pembayaran", this.pembayaranListener);
    WebSocketService.on("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.on("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.on("updated-pernyataan", this.pernyataanListener);
    WebSocketService.on("deleted-pernyataan", this.pernyataanListener);
    let memuat = this.$loading.show({
      container: this.$refs.formPendaftaran,
      loader: 'spinner'
    });
    this.getListTempatlahir();
    this.getListAgama();
    this.getListJurusan();
    this.getListWilayah();
    this.getListPembawa();
    this.getListJalur();
    this.getListCs();
    setTimeout(() => {
      memuat.hide();
    }, 250);
  },
  beforeUnmount() {
    WebSocketService.off("upserted-info-jurusan", this.updateKuotaListener);
    WebSocketService.off("inserted-jalur", this.updateJalurListener);
    WebSocketService.off("updated-jalur", this.updateJalurListener);
    WebSocketService.off("updated-status-jalur", this.updateJalurListener);
    WebSocketService.off("deleted-jalur", this.updateJalurListener);
    WebSocketService.off("inserted-cs", this.updateCsListener);
    WebSocketService.off("updated-cs", this.updateCsListener);
    WebSocketService.off("deleted-cs", this.updateCsListener);
    WebSocketService.off("inserted-pembawa", this.updatePembawaListener);
    WebSocketService.off("updated-pembawa", this.updatePembawaListener);
    WebSocketService.off("deleted-pembawa", this.updatePembawaListener);
    WebSocketService.off("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.off("updated-pembayaran", this.pembayaranListener);
    WebSocketService.off("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.off("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.off("updated-pernyataan", this.pernyataanListener);
    WebSocketService.off("deleted-pernyataan", this.pernyataanListener);
  },
  watch: {
    alamat_provinsi() {
      this.alamat_kab_kota = null;
      this.alamat_kec = null;
      if (!this.alamat_provinsi) {
        this.listKabKota = [];
      } else {
        this.getListWilayah();
      }
    },
    alamat_kab_kota() {
      this.alamat_kec = null;
      this.alamat_desa = null;
      if (!this.alamat_kab_kota) {
        this.listKec = [];
      } else {
        this.getListWilayah();
      }
    },
    alamat_kec() {
      this.alamat_desa = null;
      if (!this.alamat_kec) {
        this.listDesa = [];
      } else {
        this.getListWilayah();
      }
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce((loading, search, vm) => {
      vm.getListAsalSekolah(search).then(() => {
        loading(false);
      });
    }, 500),
    async getListTempatlahir() {
      try {
        const response = await this.axios.post('/list/tempat-lahir', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTempatLahir = response.data.tempat_lahir;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListAgama() {
      try {
        const response = await this.axios.post('/list/agama', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listAgama = response.data.agama;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    cariAsalSekolah(VueSelect) {
      return (VueSelect.search.length !== 0 && VueSelect.open && !VueSelect.mutableLoading) || (this.listAsalSekolah.length && VueSelect.open)
    },
    async getListAsalSekolah(key) {
      try {
        const response = await this.axios.post('/list/asal-sekolah', {
          key: String(key)
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listAsalSekolah = response.data.asal_sekolah;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListJurusan() {
      try {
        const response = await this.axios.post('/list/jurusan', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listJurusan = response.data.jurusan;
          if (this.jurusan) {
            for (const j of this.listJurusan) {
              if (this.jurusan.jurusan_id == j.jurusan_id && j.penuh) {
                this.jurusan = null
              }
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListWilayah() {
      try {
        const response = await this.axios.post('/list/wilayah', {
          wilayah_id_prov: Object(this.alamat_provinsi).wilayah_id,
          wilayah_id_kab_kota: Object(this.alamat_kab_kota).wilayah_id,
          wilayah_id_kec: Object(this.alamat_kec).wilayah_id,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          if (this.alamat_kec) {
            this.listDesa = response.data.desa
          } else if (this.alamat_kab_kota) {
            this.listKec = response.data.kecamatan
          } else if (this.alamat_provinsi) {
            this.listKabKota = response.data.kab_kota
          } else {
            this.listProv = response.data.provinsi
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListPembawa() {
      try {
        const response = await this.axios.post('/list/pembawa', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listPembawa = response.data.pembawa;
          let ditemukan = false;
          for (const p of this.listPembawa) {
            p.nik_nama = `(${p.nik}) ${p.nama}`;
            if (this.pembawa_ppdb && this.pembawa_ppdb.pembawa_ppdb_id == p.pembawa_ppdb_id) {
              ditemukan = true;
              this.pembawa_ppdb = p;
            }
          }
          if (this.pembawa_ppdb && !ditemukan) {
            this.pembawa_ppdb = null;
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListJalur() {
      try {
        const response = await this.axios.post('/list/jalur', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listJalur = response.data.jalur;
          if (this.jalur_ppdb_id) {
            for (const j of this.listJalur) {
              if (j.jalur_ppdb_id == this.jalur_ppdb_id && j.penuh) {
                this.jalur_ppdb_id = null
              }
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListCs() {
      try {
        const response = await this.axios.post('/list/cs', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listCs = response.data.cs;
          if (this.petugas_cs) {
            let ditemukan = 0;
            for (const c of this.listCs) {
              if (this.petugas_cs.petugas_cs_id == c.petugas_cs_id) {
                ditemukan++;
                this.petugas_cs = c;
              }
            }
            if (!ditemukan) {
              this.petugas_cs = null;
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async insertData() {
      let memuat = this.$loading.show({
        container: this.$refs.formPendaftaran,
        loader: 'spinner'
      });
      const newData = {
        tahun_ajaran_id: this.taAktif.tahun_ajaran_id,
        nisn: this.nisn,
        nik: this.nik,
        nama: this.nama.toUpperCase(),
        tempat_lahir: this.tempat_lahir.wilayah_id,
        tanggal_lahir: this.tanggal_lahir,
        jenis_kelamin: this.jenis_kelamin,
        agama_id: this.agama_id,
        npsn_asal_sekolah: this.asal_sekolah.npsn,
        nama_asal_sekolah: this.asal_sekolah.nama,
        jurusan_id: this.jurusan.jurusan_id,
        pendidikan_dasar: this.pendidikan_dasar,
        alamat_provinsi: this.alamat_provinsi.wilayah_id,
        alamat_kab_kota: this.alamat_kab_kota.wilayah_id,
        alamat_kec: this.alamat_kec.wilayah_id,
        alamat_desa: this.alamat_desa.wilayah_id,
        alamat: this.alamat,
        rt: this.rt,
        rw: this.rw,
        kontak: this.kontak,
        kontak_wali: this.kontak_wali,
        pembawa_ppdb_id: Object(this.pembawa_ppdb).pembawa_ppdb_id,
        relasi: this.relasi,
        jalur_ppdb_id: this.jalur_ppdb_id,
        petugas_cs_id: this.petugas_cs.petugas_cs_id
      }
      try {
        const response = await this.axios.post('/pendaftaran/tambah', newData, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.nisn = null;
            this.nik = null;
            this.nama = null;
            this.tempat_lahir = null;
            this.tanggal_lahir = null;
            this.jenis_kelamin = null;
            this.agama_id = null;
            this.asal_sekolah = null;
            this.jurusan = null;
            this.pendidikan_dasar = null;
            this.alamat_provinsi = null;
            this.alamat_kab_kota = null;
            this.alamat_kec = null;
            this.alamat_desa = null;
            this.alamat = null;
            this.rt = null;
            this.rw = null;
            this.kontak = null;
            this.kontak_wali = null;
            this.pembawa_ppdb = null;
            this.relasi = null;
            this.jalur_ppdb_id = null;
            this.petugas_cs = null;

            const routeCetak = this.$router.resolve({
              path: '/cetak/pendaftaran/' + response.data.insertId
            });
            window.open(routeCetak.href, '_blank');
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide()
      }, 250);
    },
  }
}
</script>

<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>