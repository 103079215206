<template>
  <h4>Penerimaan Peserta Didik Baru {{ ta.nama }}</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="row row-cols-auto justify-content-between">
        <div class="col-12 col-md-auto d-inline-flex">
          <h5 class="my-auto">Data Asal Pendaftar</h5>
        </div>
        <div class="col-auto d-inline-flex">
          <select class="form-select form-select-sm shadow-sm my-auto" v-model="ta">
            <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="vl-parent shadow border border-2 border-secondary-subtle mb-4" ref="asalSekolah">
        <table class="table table-sm table-striped table-hover mb-0">
          <thead class="table-secondary align-middle">
            <tr>
              <th class="text-center py-2" colspan="5">Asal Sekolah</th>
            </tr>
            <tr>
              <th>#</th>
              <th>NPSN</th>
              <th>Nama</th>
              <th>Pendaftar</th>
              <th>Diterima</th>
            </tr>
          </thead>
          <tbody v-if="asalSekolah.length">
            <tr v-for="d, index in asalSekolah" :key="d">
              <td>{{ index+=1 + sPageOffset }}</td>
              <td>
                <a :href="'https://referensi.data.kemdikbud.go.id/pendidikan/npsn/' + d.npsn_asal_sekolah"
                  target="blank">
                  {{ d.npsn_asal_sekolah }}
                </a>
              </td>
              <td>{{ d.nama_asal_sekolah }}</td>
              <td>{{ d.jumlah_pendaftar }}</td>
              <td>{{ d.jumlah_diterima }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="5">Data tidak ditemukan.</td>
            </tr>
          </tbody>
          <tfoot v-if="asalSekolah.length">
            <tr>
              <td class="px-2" colspan="5">
                <div class="row row-cols-auto">
                  <div class="col-auto row row-cols-auto me-auto">
                    <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
                    <div class="col-auto ps-1">
                      <select class="form-select form-select-sm shadow" v-model="sPageLimit" id="numRows">
                        <option v-for="l in arrayLimit" :key="l" :value="l">
                          {{ l }} baris
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-auto d-flex">
                    <span class="small text-muted fst-italic my-auto">
                      Menampilkan {{ sPageOffset + 1 }}-{{ sPageOffset + asalSekolah.length }} dari total {{ sCount }}
                      data
                    </span>
                  </div>
                  <div class="col-auto ms-auto">
                    <ul class="pagination pagination-sm shadow mb-0">
                      <li class="page-item">
                        <button class="page-link" :class="{ disabled: sPageCurrent == 1 }"
                          @click="sPageCurrent > 1 ? sPageCurrent-- : ''">&laquo;</button>
                      </li>
                      <li class="page-item" v-for="p in  sPageCount " :key="p">
                        <button v-if="p == sPageCurrent" class="page-link active fw-bold">{{ p }}</button>
                        <button v-else-if="p == 1 || p == sPageCount || Math.abs(p - sPageCurrent) == 1"
                          class="page-link" @click="sPageCurrent = p">{{ p }}</button>
                        <button v-else-if="(Math.abs(sPageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
    (Math.abs(sPageCurrent - sPageCount) < 4 && Math.abs(p - sPageCount) < 5)" class="page-link"
                          @click="sPageCurrent = p">{{ p }}</button>
                        <button
                          v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - sPageCount) == 5) && (Math.abs(p - sPageCount) == 1 || Math.abs(p - 1) == 1)"
                          class="page-link" @click="sPageCurrent = p">{{ p }}</button>
                        <button v-else-if="Math.abs(p - sPageCurrent) == 2 ||
    (Math.abs(sPageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
    (Math.abs(sPageCurrent - sPageCount) < 4 && Math.abs(p - sPageCount) == 5)" class="page-link px-1"
                          @click="sPageCurrent = p">...</button>
                      </li>
                      <li class="page-item">
                        <button class="page-link" :class="{ disabled: sPageCurrent == sPageCount }"
                          @click="sPageCurrent < sPageCount ? sPageCurrent++ : ''">&raquo;</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="vl-parent shadow border border-2 border-secondary-subtle" ref="asalLokasi">
        <table class="table table-sm table-striped table-hover mb-0">
          <thead class="table-secondary align-middle">
            <tr>
              <th class="text-center py-2" colspan="5">Asal Lokasi</th>
            </tr>
            <tr>
              <th>#</th>
              <th>Kab./Kota</th>
              <th>Kecamatan</th>
              <th>Pendaftar</th>
              <th>Diterima</th>
            </tr>
          </thead>
          <tbody v-if="asalLokasi.length">
            <tr v-for="d, index in asalLokasi" :key="d">
              <td>{{ index+=1 + lPageOffset }}</td>
              <td>{{ d.alamat_kab_kota }}</td>
              <td>{{ d.alamat_kecamatan }}</td>
              <td>{{ d.jumlah_pendaftar }}</td>
              <td>{{ d.jumlah_diterima }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="5">Data tidak ditemukan.</td>
            </tr>
          </tbody>
          <tfoot v-if="asalLokasi.length">
            <tr>
              <td class="px-2" colspan="5">
                <div class="row row-cols-auto">
                  <div class="col-auto row row-cols-auto me-auto">
                    <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
                    <div class="col-auto ps-1">
                      <select class="form-select form-select-sm shadow" v-model="lPageLimit" id="numRows">
                        <option v-for="l in arrayLimit" :key="l" :value="l">
                          {{ l }} baris
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-auto d-flex">
                    <span class="small text-muted fst-italic my-auto">
                      Menampilkan {{ lPageOffset + 1 }}-{{ lPageOffset + asalLokasi.length }} dari total {{ lCount }}
                      data
                    </span>
                  </div>
                  <div class="col-auto ms-auto">
                    <ul class="pagination pagination-sm shadow mb-0">
                      <li class="page-item">
                        <button class="page-link" :class="{ disabled: lPageCurrent == 1 }"
                          @click="lPageCurrent > 1 ? lPageCurrent-- : ''">&laquo;</button>
                      </li>
                      <li class="page-item" v-for="p in  lPageCount " :key="p">
                        <button v-if="p == lPageCurrent" class="page-link active fw-bold">{{ p }}</button>
                        <button v-else-if="p == 1 || p == lPageCount || Math.abs(p - lPageCurrent) == 1"
                          class="page-link" @click="lPageCurrent = p">{{ p }}</button>
                        <button v-else-if="(Math.abs(lPageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
    (Math.abs(lPageCurrent - lPageCount) < 4 && Math.abs(p - lPageCount) < 5)" class="page-link"
                          @click="lPageCurrent = p">{{ p }}</button>
                        <button
                          v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - lPageCount) == 5) && (Math.abs(p - lPageCount) == 1 || Math.abs(p - 1) == 1)"
                          class="page-link" @click="lPageCurrent = p">{{ p }}</button>
                        <button v-else-if="Math.abs(p - lPageCurrent) == 2 ||
    (Math.abs(lPageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
    (Math.abs(lPageCurrent - lPageCount) < 4 && Math.abs(p - lPageCount) == 5)" class="page-link px-1"
                          @click="lPageCurrent = p">...</button>
                      </li>
                      <li class="page-item">
                        <button class="page-link" :class="{ disabled: lPageCurrent == lPageCount }"
                          @click="lPageCurrent < lPageCount ? lPageCurrent++ : ''">&raquo;</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'DataAsalPendaftar',
  data() {
    return {
      pendaftaranListener: (d) => {
        const data = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getAsalSekolah(1);
          this.getAsalLokasi(1);
        }
      },
      pembayaranListener: (d) => {
        const data = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getAsalSekolah(1);
          this.getAsalLokasi(1);
        }
      },
      pernyataanListener: (d) => {
        const data = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getAsalSekolah(1);
          this.getAsalLokasi(1);
        }
      },
      listTa: [],
      ta: this.$store.getters.getTahunAjaran,
      arrayLimit: [10, 25, 50, 100, 250],
      asalSekolah: [],
      sCount: 0,
      sPageCurrent: 1,
      sPageOffset: 0,
      sPageLimit: this.$store.getters.getDefaultLimit,
      sPageCount: 0,
      asalLokasi: [],
      lCount: 0,
      lPageCurrent: 1,
      lPageOffset: 0,
      lPageLimit: this.$store.getters.getDefaultLimit,
      lPageCount: 0,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.getListTa();
    this.getAsalSekolah(0);
    this.getAsalLokasi(0);
    WebSocketService.on("inserted-ppdb", this.pendaftaranListener);
    WebSocketService.on("updated-ppdb", this.pendaftaranListener);
    WebSocketService.on("deleted-ppdb", this.pendaftaranListener);
    WebSocketService.on("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.on("updated-pembayaran", this.pembayaranListener);
    WebSocketService.on("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.on("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.on("updated-pernyataan", this.pernyataanListener);
    WebSocketService.on("deleted-pernyataan", this.pernyataanListener);
  },
  watch: {
    ta() {
      if (this.sPageCurrent == 1) {
        this.getAsalSekolah(1);
      } else {
        this.sPageCurrent = 1;
      }
      if (this.lPageCurrent == 1) {
        this.getAsalLokasi(1);
      } else {
        this.lPageCurrent = 1;
      }
    },
    sPageCurrent() {
      this.sPageOffset = (this.sPageCurrent - 1) * this.sPageLimit;
      this.getAsalSekolah(1);
    },
    sPageLimit() {
      if (this.sPageCurrent == 1) {
        this.getAsalSekolah(1);
      } else {
        this.sPageCurrent = 1;
      }
    },
    lPageCurrent() {
      this.lPageOffset = (this.lPageCurrent - 1) * this.lPageLimit;
      this.getAsalLokasi(1);
    },
    lPageLimit() {
      if (this.lPageCurrent == 1) {
        this.getAsalLokasi(1);
      } else {
        this.lPageCurrent = 1;
      }
    },
  },
  beforeUnmount() {
    WebSocketService.off("inserted-ppdb", this.pendaftaranListener);
    WebSocketService.off("updated-ppdb", this.pendaftaranListener);
    WebSocketService.off("deleted-ppdb", this.pendaftaranListener);
    WebSocketService.off("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.off("updated-pembayaran", this.pembayaranListener);
    WebSocketService.off("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.off("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.off("updated-pernyataan", this.pernyataanListener);
    WebSocketService.off("deleted-pernyataan", this.pernyataanListener);
  },
  methods: {
    async getListTa() {
      try {
        const response = await this.axios.post('/list/tahun-ajaran', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTa = response.data.tahun_ajaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getAsalSekolah(n) {
      let memuat = this.$loading.show({
        container: this.$refs.asalSekolah,
        loader: n ? 'dots' : 'spinner'
      });
      try {
        const response = await this.axios.post('/data/asal-sekolah', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          limit: this.sPageLimit,
          offset: this.sPageOffset,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.asalSekolah = response.data.asal_sekolah;
          this.sCount = response.data.count;
          this.sPageCount = Math.ceil(this.sCount / this.sPageLimit);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async getAsalLokasi(n) {
      let memuat = this.$loading.show({
        container: this.$refs.asalLokasi,
        loader: n ? 'dots' : 'spinner'
      });
      try {
        const response = await this.axios.post('/data/asal-kecamatan', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          limit: this.lPageLimit,
          offset: this.lPageOffset,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.asalLokasi = response.data.asal_kecamatan;
          this.lCount = response.data.count;
          this.lPageCount = Math.ceil(this.lCount / this.lPageLimit);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style scoped></style>