<template>
    <div class="card-body">
        <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
            <div class="col-12 col-md-auto p-0 d-inline-flex">
                <h5 class="my-auto">Rekap Petugas PPDB</h5>
            </div>
            <div class="col-auto p-0">
                <select class="form-select form-select-sm shadow-sm" v-model="ta">
                    <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
                </select>
            </div>
        </div>
        <div class="d-flex justify-content-end gap-2 mb-2">
            <div class="input-group input-group-sm w-auto">
                <span class="input-group-text">Rentang waktu</span>
                <input type="date" class="form-control form-control-sm" v-model="waktuAwal"
                    @change="getRekapPetugas(1)">
                <span class="input-group-text">s.d.</span>
                <input type="date" class="form-control form-control-sm" v-model="waktuAkhir"
                    @change="getRekapPetugas(1)">
            </div>
        </div>
        <div class="vl-parent" ref="dataLaporan">
            <div class="shadow">
                <table
                    class="table table-sm table-striped table-hover table-bordered border-secondary text-center mb-3">
                    <thead class="table-secondary align-middle border-secondary">
                        <tr>
                            <th colspan="4">PETUGAS PENDAFTARAN<br />
                                <span class="fst-normal fw-normal">(Daftar dan/atau bayar)</span>
                                <br />
                                <small class="fw-normal fst-italic">
                                    {{ durasiPpdb.awal }} - {{ durasiPpdb.akhir }}
                                </small>
                            </th>
                        </tr>
                    </thead>
                    <thead class="table-secondary align-middle border-secondary">
                        <tr>
                            <th>#</th>
                            <th>Nama</th>
                            <th>Hari</th>
                            <th>Kali</th>
                        </tr>
                    </thead>
                    <tbody v-if="tellerPpdb.length">
                        <tr v-for="d, index in tellerPpdb" :key="d">
                            <td>{{ index+=1 }}</td>
                            <td class="text-start">{{ d.nama_petugas }}</td>
                            <td>{{ d.hari || '-' }}</td>
                            <td>{{ d.kali || '-' }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="fst-italic py-3" colspan="4">Data tidak ditemukan.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="shadow">
                <table
                    class="table table-sm table-striped table-hover table-bordered border-secondary text-center mb-3">
                    <thead class="table-secondary align-middle border-secondary">
                        <tr>
                            <th colspan="5">PETUGAS CS<br />
                                <small class="fw-normal fst-italic">{{ durasiPpdb.awal }} - {{ durasiPpdb.akhir
                                    }}</small>
                            </th>
                        </tr>
                    </thead>
                    <thead class="table-secondary align-middle border-secondary">
                        <tr>
                            <th>#</th>
                            <th>Nama</th>
                            <th>Hari</th>
                            <th>Pendaftar</th>
                            <th>Diterima</th>
                        </tr>
                    </thead>
                    <tbody v-if="petugasCs.length">
                        <tr v-for="d, index in petugasCs" :key="d">
                            <td>{{ index+=1 }}</td>
                            <td class="text-start">{{ d.nama_petugas }}</td>
                            <td>{{ d.hari || '-' }}</td>
                            <td>{{ d.jumlah_pendaftar || '-' }}</td>
                            <td>{{ d.jumlah_diterima || '-' }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="fst-italic py-3" colspan="5">Data tidak ditemukan.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="shadow">
                <table
                    class="table table-sm table-striped table-hover table-bordered border-secondary text-center mb-3">
                    <thead class="table-secondary align-middle border-secondary">
                        <tr>
                            <th colspan="5">PETUGAS KEUANGAN<br />
                                <small class="fw-normal fst-italic">{{ durasiBayar.awal }} - {{ durasiBayar.akhir
                                    }}</small>
                            </th>
                        </tr>
                    </thead>
                    <thead class="table-secondary align-middle border-secondary">
                        <tr>
                            <th>#</th>
                            <th>Nama</th>
                            <th>Hari</th>
                            <th>Kali</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody v-if="petugasKeuangan.length">
                        <tr v-for="d, index in petugasKeuangan" :key="d">
                            <td>{{ index+=1 }}</td>
                            <td class="text-start">{{ d.nama_petugas }}</td>
                            <td>{{ d.hari || '-' }}</td>
                            <td>{{ d.kali_penerimaan || '-' }}</td>
                            <td>{{ d.total_penerimaan ?
                    d.total_penerimaan.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                    }) : "-" }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="fst-italic py-3" colspan="5">Data tidak ditemukan.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="shadow">
                <table
                    class="table table-sm table-striped table-hover table-bordered border-secondary text-center mb-3">
                    <thead class="table-secondary align-middle border-secondary">
                        <tr>
                            <th colspan="4">PERNYATAAN<br />
                                <small class="fw-normal fst-italic">{{ durasiSp.awal }} - {{ durasiSp.akhir
                                    }}</small>
                            </th>
                        </tr>
                    </thead>
                    <thead class="table-secondary align-middle border-secondary">
                        <tr>
                            <th>#</th>
                            <th>Nama</th>
                            <th>Hari</th>
                            <th>Kali</th>
                        </tr>
                    </thead>
                    <tbody v-if="tellerSp.length">
                        <tr v-for="d, index in tellerSp" :key="d">
                            <td>{{ index+=1 }}</td>
                            <td class="text-start">{{ d.nama_petugas }}</td>
                            <td>{{ d.hari || '-' }}</td>
                            <td>{{ d.kali || '-' }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="fst-italic py-3" colspan="4">Data tidak ditemukan.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="shadow">
                <table
                    class="table table-sm table-striped table-hover table-bordered border-secondary text-center mb-3">
                    <thead class="table-secondary align-middle border-secondary">
                        <tr>
                            <th colspan="4">PEMBAGIAN SERAGAM<br />
                                <small class="fw-normal fst-italic">{{ durasiSeragam.awal }} - {{ durasiSeragam.akhir
                                    }}</small>
                            </th>
                        </tr>
                    </thead>
                    <thead class="table-secondary align-middle border-secondary">
                        <tr>
                            <th>#</th>
                            <th>Nama</th>
                            <th>Hari</th>
                            <th>Kali</th>
                        </tr>
                    </thead>
                    <tbody v-if="petugasSeragam.length">
                        <tr v-for="d, index in petugasSeragam" :key="d">
                            <td>{{ index+=1 }}</td>
                            <td class="text-start">{{ d.nama_petugas }}</td>
                            <td>{{ d.hari || '-' }}</td>
                            <td>{{ d.kali || '-' }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="fst-italic py-3" colspan="4">Data tidak ditemukan.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
    name: 'DataLaporan',
    data() {
        return {
            updateListenr: () => {
                this.getRekapPetugas(1);
            },
            tellerPpdb: [],
            petugasCs: [],
            durasiPpdb: Object,
            tellerBayar: [],
            petugasKeuangan: [],
            durasiBayar: Object,
            tellerSp: [],
            durasiSp: Object,
            petugasSeragam: [],
            durasiSeragam: Object,
            listTa: [],
            ta: this.$store.getters.getTahunAjaran,
            waktuAwal: null,
            waktuAkhir: null
        }
    },
    computed: {
        token() {
            return this.$store.getters.getToken
        },
        taAktif() {
            return this.$store.getters.getTahunAjaran
        },
    },
    mounted() {
        this.getListTa();
        this.getRekapPetugas();
        WebSocketService.on("inserted-ppdb", this.updateListenr);
        WebSocketService.on("deleted-ppdb", this.updateListenr);
        WebSocketService.on("inserted-cs", this.updateListenr);
        WebSocketService.on("updated-cs", this.updateListenr);
        WebSocketService.on("deleted-cs", this.updateListenr);
        WebSocketService.on("inserted-pembayaran", this.updateListenr);
        WebSocketService.on("deleted-pembayaran", this.updateListenr);
        WebSocketService.on("inserted-keuangan", this.updateListenr);
        WebSocketService.on("updated-keuangan", this.updateListenr);
        WebSocketService.on("deleted-keuangan", this.updateListenr);
        WebSocketService.on("inserted-pernyataan", this.updateListenr);
        WebSocketService.on("deleted-pernyataan", this.updateListenr);
        WebSocketService.on("inserted-pembagian", this.updateListenr);
        WebSocketService.on("deleted-pembagian", this.updateListenr);
    },
    watch: {
        ta() {
            this.waktuAwal = null;
            this.waktuAkhir = null;
            this.getRekapPetugas(1);
        },
    },
    beforeUnmount() {
        WebSocketService.off("inserted-ppdb", this.updateListenr);
        WebSocketService.off("deleted-ppdb", this.updateListenr);
        WebSocketService.off("inserted-cs", this.updateListenr);
        WebSocketService.off("updated-cs", this.updateListenr);
        WebSocketService.off("deleted-cs", this.updateListenr);
        WebSocketService.off("inserted-pembayaran", this.updateListenr);
        WebSocketService.off("deleted-pembayaran", this.updateListenr);
        WebSocketService.off("inserted-keuangan", this.updateListenr);
        WebSocketService.off("updated-keuangan", this.updateListenr);
        WebSocketService.off("deleted-keuangan", this.updateListenr);
        WebSocketService.off("inserted-pernyataan", this.updateListenr);
        WebSocketService.off("deleted-pernyataan", this.updateListenr);
        WebSocketService.off("inserted-pembagian", this.updateListenr);
        WebSocketService.off("deleted-pembagian", this.updateListenr);
    },
    methods: {
        async getListTa() {
            try {
                const response = await this.axios.post('/list/tahun-ajaran', {
                }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.listTa = response.data.tahun_ajaran;
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silahkan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                    }
                } else {
                    console.log(err)
                }
            }
        },
        async getRekapPetugas(n) {
            let memuat = this.$loading.show({
                container: this.$refs.dataLaporan,
                loader: n ? 'dots' : 'spinner'
            });
            try {
                const response = await this.axios.post('/data/laporan', {
                    tahun_ajaran_id: this.ta.tahun_ajaran_id,
                    waktu_awal: this.waktuAwal,
                    waktu_akhir: this.waktuAkhir
                }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.tellerPpdb = response.data.teller_ppdb;
                    this.petugasCs = response.data.petugas_cs;
                    this.durasiPpdb = response.data.durasi_ppdb;
                    this.petugasKeuangan = response.data.petugas_keuangan;
                    this.durasiBayar = response.data.durasi_bayar;
                    this.tellerSp = response.data.teller_sp;
                    this.durasiSp = response.data.durasi_sp;
                    this.petugasSeragam = response.data.petugas_seragam;
                    this.durasiSeragam = response.data.durasi_seragam;
                    if (this.durasiPpdb.awal) {
                        this.durasiPpdb.awal = new Date(this.durasiPpdb.awal).toLocaleString('id-ID', { dateStyle: 'medium', timeStyle: 'short' })
                        this.durasiPpdb.akhir = new Date(this.durasiPpdb.akhir).toLocaleString('id-ID', { dateStyle: 'medium', timeStyle: 'short' })
                    }
                    if (this.durasiBayar.awal) {
                        this.durasiBayar.awal = new Date(this.durasiBayar.awal).toLocaleString('id-ID', { dateStyle: 'medium', timeStyle: 'short' })
                        this.durasiBayar.akhir = new Date(this.durasiBayar.akhir).toLocaleString('id-ID', { dateStyle: 'medium', timeStyle: 'short' })
                    }
                    if (this.durasiSp.awal) {
                        this.durasiSp.awal = new Date(this.durasiSp.awal).toLocaleString('id-ID', { dateStyle: 'medium', timeStyle: 'short' })
                        this.durasiSp.akhir = new Date(this.durasiSp.akhir).toLocaleString('id-ID', { dateStyle: 'medium', timeStyle: 'short' })
                    }
                    if (this.durasiSeragam.awal) {
                        this.durasiSeragam.awal = new Date(this.durasiSeragam.awal).toLocaleString('id-ID', { dateStyle: 'medium', timeStyle: 'short' })
                        this.durasiSeragam.akhir = new Date(this.durasiSeragam.akhir).toLocaleString('id-ID', { dateStyle: 'medium', timeStyle: 'short' })
                    }
                    console.log(this.durasiPpdb)
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silahkan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                    }
                } else {
                    console.log(err)
                }
            }
            setTimeout(() => {
                memuat.hide();
            }, 250);
        },
    }
}
</script>

<style></style>