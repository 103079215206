<template>
  <div v-if="!sp" class="h3 text-center text-secondary">Data pernyataan tidak ditemukan</div>
  <div v-else id="surat-pernyataan">
    <img v-for="i in 3" :key="i" :class="'bg-img bg-img' + i" src="../assets/bg-bukti.png" alt="">
    <div class="container-fluid px-2">
      <div class="row px-3 py-2">
        <div class="col-10 text-center d-flex px-1">
          <div class="col-2 d-flex">
            <div class="m-auto text-center">
              <img class="w-100" src="../assets/logo.png" alt="">
            </div>
          </div>
          <div class="col-10 text-center d-flex px-1">
            <div class="m-auto">
              <h5 class="mb-0">
                PENERIMAAN PESERTA DIDIK BARU SMK PGRI 1 KEDIRI<br />
                TAHUN AJARAN {{ sp.nama_tahun_ajaran }}
              </h5>
              <hr class="kop my-1">
              <span class="h6 mb-0">SURAT PERNYATAAN ORANG TUA/WALI MURID</span>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex border border-2 border-dark rounded-3">
          <div class="m-auto text-center">
            <h3 class="mb-0">P1</h3>
            <hr class="my-0 opacity-100">
            <h6>Panitia</h6>
          </div>
        </div>
      </div>
      <div class="px-4 py-2 small">
        <div class="px-0 mb-4" style="text-align: justify; text-justify: inter-word;">
          <p class="lh-base" style="text-indent: 50px;">
            Bersama ini, kami selaku orang tua/wali murid dari siswa atas nama
            <strong>{{ sp.nama }}</strong>
            dengan nomor registrasi <strong>{{ sp.no_reg }}</strong>, menyatakan bahwa telah
            mengajukan <strong>{{ keperluan[sp.jenis_keperluan] }}</strong>
            dari yang semula diterima pada jurusan <strong>{{ sp.nama_asal_jurusan }}</strong>
            <span v-if="sp.ke_jurusan"> pindah ke jurusan <strong>{{ sp.nama_jurusan_tujuan }}</strong></span>
            karena <strong>{{ sp.alasan }}</strong>
          </p>
          <p class="lh-base" style="text-indent: 50px;">
            Demikian Surat Pernyataan ini dibuat secara elektronik dan dibubuhi tanda tangan
            oleh kami selaku orang tua/wali murid dan petugas PPDB SMK PGRI 1 KEDIRI Tahun Ajaran
            {{ sp.nama_tahun_ajaran }}
            sebagai bukti persetujuan pengajuan <strong>{{ keperluan[sp.jenis_keperluan] }}</strong>.
          </p>
        </div>
        <div class="d-flex row row-cols-3 justify-content-between text-center">
          <div class="col-4 mt-auto">
            <div class="mb-0">Diverifikasi oleh,</div>
            <div class="mb-5">Petugas PPDB</div>
            <div class="fw-bold text-decoration-underline">{{ user.nama }}</div>
          </div>
          <div class="col-4 mt-auto">
            Kediri, {{ new Date().toLocaleDateString("id-ID", { dateStyle: "long" }) }}
            <div class="mb-0">Pembuat Pernyataan,</div>
            <div class="mb-5">Orang Tua/Walii Murid</div>
            <div class="fw-bold text-decoration-underline">{{ sp.orang_tua }}</div>
          </div>
        </div>
      </div>
    </div>
    <hr class="batas mb-0">
    <div class="container-fluid px-2">
      <div class="row px-3 py-2">
        <div class="col-10 text-center d-flex px-1">
          <div class="col-2 d-flex">
            <div class="m-auto text-center">
              <img class="w-100" src="../assets/logo.png" alt="">
            </div>
          </div>
          <div class="col-10 text-center d-flex px-1">
            <div class="m-auto">
              <h5 class="mb-0">
                PENERIMAAN PESERTA DIDIK BARU SMK PGRI 1 KEDIRI<br />
                TAHUN AJARAN {{ sp.nama_tahun_ajaran }}
              </h5>
              <hr class="kop my-1">
              <span class="h6 mb-0">SURAT PERNYATAAN ORANG TUA/WALI MURID</span>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex border border-2 border-dark rounded-3">
          <div class="m-auto text-center">
            <h3 class="mb-0">P1</h3>
            <hr class="my-0 opacity-100">
            <h6>Peserta</h6>
          </div>
        </div>
      </div>
      <div class="px-4 py-2 small">
        <div class="px-0 mb-4" style="text-align: justify; text-justify: inter-word;">
          <p class="lh-base" style="text-indent: 50px;">
            Bersama ini, kami selaku orang tua/wali murid dari siswa atas nama
            <strong>{{ sp.nama }}</strong>
            dengan nomor registrasi <strong>{{ sp.no_reg }}</strong>, menyatakan bahwa telah
            mengajukan <strong>{{ keperluan[sp.jenis_keperluan] }}</strong>
            dari yang semula diterima pada jurusan <strong>{{ sp.nama_asal_jurusan }}</strong>
            <span v-if="sp.ke_jurusan"> pindah ke jurusan <strong>{{ sp.nama_jurusan_tujuan }}</strong></span>
            karena <strong>{{ sp.alasan }}</strong>
          </p>
          <p class="lh-base" style="text-indent: 50px;">
            Demikian Surat Pernyataan ini dibuat secara elektronik dan dibubuhi tanda tangan
            oleh kami selaku orang tua/wali murid dan petugas PPDB SMK PGRI 1 KEDIRI Tahun Ajaran
            {{ sp.nama_tahun_ajaran }}
            sebagai bukti persetujuan pengajuan <strong>{{ keperluan[sp.jenis_keperluan] }}</strong>.
          </p>
        </div>
        <div class="d-flex row row-cols-3 justify-content-between text-center">
          <div class="col-4 mt-auto">
            <div class="mb-0">Diverifikasi oleh,</div>
            <div class="mb-5">Petugas PPDB</div>
            <div class="fw-bold text-decoration-underline">{{ sp.editor || sp.creator }}</div>
          </div>
          <div class="col-4 mt-auto">
            Kediri, {{ new Date().toLocaleDateString("id-ID", { dateStyle: "long" }) }}
            <div class="mb-0">Pembuat Pernyataan,</div>
            <div class="mb-5">Orang Tua/Walii Murid</div>
            <div class="fw-bold text-decoration-underline">{{ sp.orang_tua }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuratPernyataan',
  data() {
    return {
      keperluan: ["", "Pindah Jurusan", "Pengunduran Diri"]
    }
  },
  props: {
    sp: Object,
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style scoped>
#surat-pernyataan {
  position: relative;
  font-family: 'Trebuchet MS', sans-serif;
}

.bg-img {
  position: absolute;
  height: auto;
  opacity: 0.3;
  object-fit: cover;
  z-index: -1;
}

.bg-img1 {
  top: 0;
  left: 0;
  width: 60%;
}

.bg-img2 {
  bottom: 0;
  right: 0;
  width: 60%;
}

.bg-img3 {
  bottom: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 50%);
}

hr.kop {
  height: 5px;
  border-top: 2px solid black;
  border-bottom: 1px solid black;
}

hr.batas {
  height: 2px;
  border-top: none;
  border-bottom: 2px dashed black;
}
</style>