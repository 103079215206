<template>
  <h4>Pengaturan PPDB</h4>
  <div class="card shadow">
    <div class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item">
          <router-link to="/pengaturan/info-jurusan" class="nav-link" active-class="active">Info Jurusan</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/pengaturan/gelombang" class="nav-link" active-class="active">Gelombang</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/pengaturan/jalur" class="nav-link" active-class="active">Jalur</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/pengaturan/cs" class="nav-link" active-class="active">Petugas CS</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/pengaturan/keuangan" class="nav-link" active-class="active">Petugas Keuangan</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/pengaturan/seragam" class="nav-link" active-class="active">Seragam</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/pengaturan/laporan" class="nav-link" active-class="active">Laporan</router-link>
        </li>
      </ul>
    </div>
    <div v-if="!$route.params.data">
      <div class="card-body">
        <quick-count />
      </div>
      <div class="card-footer">
        <div class="hstack gap-1">
          <div class="col-auto">
            Jumlah baris default:
          </div>
          <div class="col-auto">
            <select class="form-select form-select-sm shadow" v-model="limit" @change="changeDefaultLimit">
              <option v-for="l in arrayLimit" :key="l" :value="l">
                {{ l }} baris
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <data-info-jurusan v-else-if="$route.params.data === 'info-jurusan'" />
    <gelombang-ppdb v-else-if="$route.params.data === 'gelombang'" />
    <jalur-ppdb v-else-if="$route.params.data === 'jalur'" />
    <petugas-cs v-else-if="$route.params.data === 'cs'" />
    <petugas-keuangan v-else-if="$route.params.data === 'keuangan'" />
    <data-seragam v-else-if="$route.params.data === 'seragam'" />
    <data-laporan v-else-if="$route.params.data === 'laporan'" />
    <not-found v-else class="card-body" />
  </div>
</template>

<script>
import QuickCount from '@/components/QuickCount'
import DataInfoJurusan from '@/components/DataInfoJurusan'
import GelombangPpdb from '@/components/GelombangPpdb'
import JalurPpdb from '@/components/JalurPpdb'
import PetugasCs from '@/components/PetugasCs'
import PetugasKeuangan from '@/components/PetugasKeuangan'
import NotFound from '@/components/NotFound'
import DataSeragam from '@/components/DataSeragam'
import DataLaporan from '@/components/DataLaporan'
export default {
  name: 'PengaturanView',
  components: {
    QuickCount,
    DataInfoJurusan,
    GelombangPpdb,
    JalurPpdb,
    PetugasCs,
    PetugasKeuangan,
    DataSeragam,
    DataLaporan,
    NotFound,
  },
  data() {
    return {
      arrayLimit: [10, 25, 50, 100, 250],
      limit: 10,
    }
  },
  computed: {
    defaultLimit() {
      return this.$store.getters.getDefaultLimit
    }
  },
  watch: {
  },
  mounted() { this.limit = this.defaultLimit },
  methods: {
    changeDefaultLimit() {
      this.$store.dispatch('updateDefaultLimit', this.limit);
    },
  }
}
</script>

<style></style>