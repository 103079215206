<template>
  <div class="card-body">
    <div class="row row-cols-auto justify-content-md-between justify-content-end mb-2 mx-auto gap-2">
      <div class="col-12 col-md-auto p-0 d-inline-flex">
        <h5 class="my-auto">Formulir Pembagian Seragam</h5>
      </div>
      <div class="col-auto p-0" v-if="adminKhusus">
        <select class="form-select form-select-sm shadow-sm" v-model="ta">
          <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
        </select>
      </div>
      <div class="col-auto p-0" v-else>
        <h6 class="bg-white rounded px-2 py-1 shadow my-auto">{{ ta.nama }}</h6>
      </div>
    </div>
    <form @submit.prevent="insertData">
      <div class="row mb-3">
        <label class="col-sm-4 col-md-3 col-lg-2 col-form-label col-form-label-sm">Pendaftar</label>
        <div class="col-sm-7 col-lg-5">
          <v-select class="bg-white rounded shadow" v-model="pendaftar" placeholder="Pendaftar" label="no_reg_nama"
            :options="listPendaftar">
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!pendaftar" v-bind="attributes" v-on="events" />
            </template>
            <template #option="option">
              <strong>({{ option.no_reg }})</strong> {{ option.nama }}
            </template>
            <template #no-options>
              Pendaftar tidak ditemukan.
            </template>
          </v-select>
        </div>
      </div>
      <div class="shadow vl-parent" ref="tableSeragam">
        <table class="table table-sm table-striped table-hover mb-0">
          <thead class="table-secondary">
            <tr>
              <th>#</th>
              <th>Jenis Seragam</th>
              <th v-if="pendaftar">Pembagian</th>
            </tr>
          </thead>
          <tbody v-if="listSeragam.length">
            <tr v-for="d, index in listSeragam" :key="d">
              <td>{{ index+=1 }}</td>
              <td>{{ d.nama }}</td>
              <td v-if="pendaftar" class="fst-italic">
                <div v-if="d.creator" class="form-check my-0 p-0"><strong>{{ d.creator }}</strong> ~ {{ d.created_at }}
                  <div v-if="d.editor">diperbarui {{ d.editor }} ~ {{ d.updated_at }}</div>
                </div>
                <div v-else class="form-check my-0">
                  <input type="checkbox" class="form-check-input" :value="d.seragam_id" v-model="listSeragamId"
                    :id="'s' + d.seragam_id">
                  <label class="form-check-label" :for="'s' + d.seragam_id">bagikan</label>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="3">Data tidak ditemukan.</td>
            </tr>
          </tbody>
          <tfoot class="text-end table-secondary" v-if="pendaftar">
            <tr>
              <th colspan="3">
                <input type="submit" class="btn btn-primary px-4" value="Simpan">
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </form>
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'PembagianSeragam',
  data() {
    return {
      insertedPpdbListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          this.getListPendaftar();
        }
      },
      updatePpdbListener: (d) => {
        const update = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == update.tahun_ajaran_id) {
          this.getListPendaftar();
        }
      },
      deletePpdbListener: (d) => {
        const del = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == del.tahun_ajaran_id) {
          this.getListPendaftar();
        }
        if (this.dataPendaftar) {
          if (this.dataPendaftar.ppdb_id == del.ppdb_id) {
            this.pendaftar = null;
          }
        }
      },
      insertedSeragamListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          if (this.pendaftar) {
            this.getDataPembagianSeragamByPpdbId();
          } else {
            this.getListSeragam();
          }
        }
      },
      updateSeragamListener: (d) => {
        const update = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == update.tahun_ajaran_id) {
          if (this.pendaftar) {
            this.getDataPembagianSeragamByPpdbId();
          } else {
            this.getListSeragam();
          }
        }
      },
      deleteSeragamListener: (d) => {
        const del = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == del.tahun_ajaran_id) {
          if (this.pendaftar) {
            this.getDataPembagianSeragamByPpdbId();
          } else {
            this.getListSeragam();
          }
        }
      },
      insertedPembagianListener: (d) => {
        const insert = JSON.parse(d);
        if (this.pendaftar && this.pendaftar.ppdb_id == insert.ppdb_id) {
          this.getDataPembagianSeragamByPpdbId();
        }
      },
      updatePembagianListener: (d) => {
        const update = JSON.parse(d);
        if (this.pendaftar) {
          for (const ls of this.listSeragam) {
            if (ls.pembagian_seragam_id == update.pembagian_seragam_id) {
              this.getDataPembagianSeragamByPpdbId();
              break;
            }
          }
        }
      },
      deletePembagianListener: (d) => {
        const del = JSON.parse(d);
        if (this.pendaftar) {
          for (const ls of this.listSeragam) {
            if (ls.pembagian_seragam_id == del.pembagian_seragam_id) {
              this.getDataPembagianSeragamByPpdbId();
              break;
            }
          }
        }
      },
      adminKhusus: false,
      listTa: [],
      ta: this.$store.getters.getTahunAjaran,
      listPendaftar: [],
      pendaftar: null,
      listSeragam: [],
      listSeragamId: [],
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.adminKhusus = String(this.user.ket).split(",").some((k) => {
      return ["it", "ppdb-khusus"].includes(k);
    });
    this.getListTa();
    this.getListPendaftar();
    this.getListSeragam();
    WebSocketService.on("inserted-ppdb", this.insertedPpdbListener);
    WebSocketService.on("updated-ppdb", this.updatePpdbListener);
    WebSocketService.on("deleted-ppdb", this.deletePpdbListener);
    WebSocketService.on("inserted-seragam", this.insertedSeragamListener);
    WebSocketService.on("updated-seragam", this.updateSeragamListener);
    WebSocketService.on("deleted-seragam", this.deleteSeragamListener);
    WebSocketService.on("inserted-pembagian", this.insertedPembagianListener);
    WebSocketService.on("updated-pembagian", this.updatePembagianListener);
    WebSocketService.on("deleted-pembagian", this.deletePembagianListener);
  },
  watch: {
    ta() {
      this.pendaftar = null;
      this.listSeragamId = [];
      this.getListPendaftar();
      this.getListSeragam();
    },
    pendaftar(newValue, oldValue) {
      if (this.pendaftar) {
        if (Object(oldValue).ppdb_id !== Object(newValue).ppdb_id) {
          this.listSeragamId = [];
          this.getDataPembagianSeragamByPpdbId();
        }
      } else {
        this.listSeragamId = [];
      }
    }
  },
  beforeUnmount() {
    WebSocketService.off("inserted-ppdb", this.insertedPpdbListener);
    WebSocketService.off("updated-ppdb", this.updatePpdbListener);
    WebSocketService.off("deleted-ppdb", this.deletePpdbListener);
    WebSocketService.off("inserted-seragam", this.insertedSeragamListener);
    WebSocketService.off("updated-seragam", this.updateSeragamListener);
    WebSocketService.off("deleted-seragam", this.deleteSeragamListener);
    WebSocketService.off("inserted-pembagian", this.insertedPembagianListener);
    WebSocketService.off("updated-pembagian", this.updatePembagianListener);
    WebSocketService.off("deleted-pembagian", this.deletePembagianListener);
  },
  methods: {
    async getListTa() {
      try {
        const response = await this.axios.post('/list/tahun-ajaran', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTa = response.data.tahun_ajaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListPendaftar() {
      try {
        const response = await this.axios.post('/list/pendaftar', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listPendaftar = response.data.pendaftar;
          for (const p of this.listPendaftar) {
            p.no_reg_nama = "(" + p.no_reg + ") " + p.nama;
          }
          if (this.pendaftar) {
            let ditemukan = 0;
            for (const p of this.listPendaftar) {
              if (this.pendaftar.ppdb_id == p.ppdb_id) {
                ditemukan++;
                this.pendaftar = p;
              }
            }
            if (!ditemukan) {
              this.pendaftar = null;
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListSeragam() {
      let memuat = this.$loading.show({
        container: this.$refs.tableSeragam,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/list/seragam', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listSeragam = response.data.seragam;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async getDataPembagianSeragamByPpdbId() {
      let memuat = this.$loading.show({
        container: this.$refs.tableSeragam,
        loader: 'dots'
      });
      this.listSeragamId = [];
      try {
        const response = await this.axios.post('/data/pembagian-seragam-by-ppdb-id', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          ppdb_id: this.pendaftar.ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listSeragam = response.data.pembagian;
          for (const s of this.listSeragam) {
            if (s.creator) {
              s.created_at = new Date(s.created_at).toLocaleString('id-ID');
            }
            if (s.editor) {
              s.updated_at = new Date(s.updated_at).toLocaleString('id-ID');
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async insertData() {
      if (this.listSeragamId.length) {
        let memuat = this.$loading.show({
          container: this.$refs.tableSeragam,
          loader: 'spinner'
        });
        try {
          const response = await this.axios.post('/pembagian-seragam/tambah', {
            ppdb_id: this.pendaftar.ppdb_id,
            listSeragamId: this.listSeragamId
          }, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
        setTimeout(() => {
          memuat.hide();
        }, 250);
      } else {
        this.$swal({
          title: 'Perhatian',
          text: `Belum ada seragam yang dibagikan!`,
          icon: 'warning',
          showConfirmButton: true,
        });
      }
    }
  },
}
</script>

<style></style>