<template>
  <h4>Penerimaan Peserta Didik Baru {{ ta.nama }}</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="row row-cols-auto justify-content-between mb-2">
        <div class="col-12 col-md-auto d-inline-flex">
          <h5 class="my-auto">Data Pendaftaran</h5>
        </div>
        <div v-if="adminKhusus" class="col-auto d-inline-flex">
          <select class="form-select form-select-sm shadow-sm my-auto" v-model="ta">
            <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
          </select>
        </div>
        <div v-else class="col-auto d-inline-flex">
          <h6 class="bg-white rounded px-2 py-1 shadow my-auto">{{ ta.nama }}</h6>
        </div>
      </div>
      <div class="row g-1">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="gelombang_ppdb" placeholder="Gelombang" label="nama"
            :options="listGelombang">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Gelombang tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="jurusan" placeholder="Jurusan" label="nama"
            :options="listJurusan">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Jurusan tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="pembawa_ppdb" placeholder="Pembawa" label="nama"
            :options="listPembawa">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Pembawa tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="status_ppdb" placeholder="Status" label="nama"
            :options="listStatus">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Status tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="jalur_ppdb" placeholder="Jalur" label="nama"
            :options="listJalur">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Jalur tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="petugas_cs" placeholder="Petugas CS" label="nama"
            :options="listCs">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Petugas CS tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="teller" placeholder="Petugas Pendaftaran"
            label="nama_petugas" :options="listTeller">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Petugas Pendaftaran tidak ditemukan.
            </template>
          </v-select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 ms-auto">
          <input id="cari" type="search" class="form-control form-control-sm shadow-sm" placeholder="Cari . . ."
            v-model="key" @keyup="search" @search="search">
        </div>
      </div>
      <div class="table-responsive vl-parent shadow" ref="dataPendaftaran">
        <table class="table table-sm mb-0 text-nowrap">
          <thead class="table-secondary align-middle">
            <tr>
              <th>#</th>
              <th class="position-sticky start-0">No. Reg.</th>
              <th>Nama</th>
              <th>Jurusan</th>
              <th>Pembawa</th>
              <th>Status</th>
              <th>Jalur</th>
              <th>Petugas CS</th>
              <th>Dibuat</th>
              <th>Diperbarui</th>
              <th>Tindakan</th>
            </tr>
          </thead>
          <tbody v-if="dataPendaftaran.length">
            <tr v-for="d, index in dataPendaftaran" :key="d">
              <td>{{ index+=1 + pageOffset }}</td>
              <td class="position-sticky start-0">
                {{ d.no_reg }}</td>
              <td>
                <transition name="fade" mode="out-in">
                  <span :key="d.nama">{{ d.nama }}</span>
                </transition>
              </td>
              <td>
                <transition name="fade" mode="out-in">
                  <span :key="d.kode">{{ d.kode }}</span>
                </transition>
              </td>
              <td>
                <transition name="fade" mode="out-in">
                  <span :key="d.nama_pembawa">{{ d.nama_pembawa || '-' }}</span>
                </transition>
              </td>
              <td>
                <transition name="fade" mode="out-in">
                  <span :key="d.status_pendaftaran">{{ d.status_pendaftaran }}</span>
                </transition>
              </td>
              <td>
                <transition name="fade" mode="out-in">
                  <span :key="d.jalur_pendaftaran">{{ d.jalur_pendaftaran }}</span>
                </transition>
              </td>
              <td>
                <transition name="fade" mode="out-in">
                  <span :key="d.nama_cs">{{ d.nama_cs }}</span>
                </transition>
              </td>
              <td>
                <div class="fw-bold">{{ d.creator }}</div>
                <small class="fst-italic">{{ d.created_at }}</small>
              </td>
              <td>
                <transition name="fade" mode="out-in">
                  <div class="fw-bold" :key="d.editor">{{ d.editor || '-' }}</div>
                </transition>
                <transition name="fade" mode="out-in">
                  <small class="fst-italic" :key="d.updated_at">{{ d.updated_at }}</small>
                </transition>
              </td>
              <td>
                <div class="hstack gap-1">
                  <button type="button" class="btn btn-sm btn-outline-secondary d-flex" @click="cetak(d)"
                    v-tooltip="'Cetak Bukti Pendaftaran'"><svg xmlns="http://www.w3.org/2000/svg" width="13"
                      fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path
                        d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button type="button" class="btn btn-sm btn-outline-primary d-flex"
                    @click="getDataPendaftarById(d.ppdb_id)" data-bs-toggle="modal" data-bs-target="#editData"
                    v-tooltip="'Edit Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                      viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button type="button" class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)"
                    v-tooltip="'Hapus Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="11">Data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <span v-if="dataPendaftaran.length" class="small text-muted fst-italic">
        Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataPendaftaran.length }} dari total {{ count }} data
      </span>
    </div>
    <div v-if="dataPendaftaran.length" class="card-footer">
      <div class="row row-cols-auto justify-content-sm-between justify-content-center">
        <div class="row row-cols-auto">
          <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
          <div class="col-auto ps-1">
            <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
              <option v-for="l in arrayLimit" :key="l" :value="l">
                {{ l }} baris
              </option>
            </select>
          </div>
        </div>
        <div class="col-auto">
          <ul class="pagination pagination-sm shadow mb-0">
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
                @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
            </li>
            <li class="page-item" v-for="p in  pageCount " :key="p">
              <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
              <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button
                v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
                class="page-link" @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
    (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
                @click="pageCurrent = p">...</button>
            </li>
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
                @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-fullscreen-xl-down modal-dialog-centered w-100 h-auto">
      <div class="modal-content vl-parent" ref="formEdit">
        <form @submit.prevent="updateData">
          <div class="modal-header position-sticky top-0 bg-body z-2">
            <legend class="modal-title">Perbarui Data Pendaftaran</legend>
            <div class="hstack gap-2">
              <h5 class="border border-2 rounded px-4 py-2 my-auto shadow text-nowrap">No. Reg. {{ formEdit.no_reg }}
              </h5>
              <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
          </div>
          <div class="modal-body">
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm align-items-center py-0">
                <div class="hstack gap-2"><span>NISN</span>
                  <a href="https://nisn.data.kemdikbud.go.id/index.php/Cindex/caribynama/" target="blank"
                    class="btn btn-sm btn-outline-primary border-0" v-tooltip:bottom="'Cari NISN'">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor"
                      class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                      <path fill-rule="evenodd"
                        d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                    </svg>
                  </a>
                </div>
              </label>
              <div class="col-sm-6 col-lg-4">
                <input type="text" class="form-control form-control-sm shadow" placeholder="Nomor Induk Siswa Nasional"
                  v-model="formEdit.nisn" pattern="[0-9]{10}" title="NISN terdiri dari 10 digit angka" required>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">NIK</label>
              <div class="col-sm-6 col-lg-4">
                <input type="text" class="form-control form-control-sm shadow" placeholder="Nomor Induk Kependudukan"
                  v-model="formEdit.nik" pattern="[0-9]{16}" title="NIK terdiri dari 16 digit angka" required>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-9 col-lg-6">
                <input type="text" class="form-control form-control-sm shadow" style="text-transform: uppercase;"
                  placeholder="Nama Lengkap Calon Peserta Didik Baru" v-model="formEdit.nama" required>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Tempat Lahir</label>
              <div class="col-sm-6 col-lg-4">
                <v-select class="bg-white rounded shadow" v-model="formEdit.tempat_lahir" placeholder="Tempat Lahir"
                  label="nama" :options="listTempatLahir" :clearable="false">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formEdit.tempat_lahir" v-bind="attributes" v-on="events" />
                  </template>
                  <template #no-options>
                    Tempat lahir tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Tanggal Lahir</label>
              <div class="col-sm-4 col-lg-3">
                <input type="date" class="form-control form-control-sm shadow" placeholder="Tanggal Lahir"
                  v-model="formEdit.tanggal_lahir" required @input="console.log(formEdit.tanggal_lahir)">
              </div>
            </div>
            <div class="row">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Jenis Kelamin</label>
              <div class="col-sm-9 col-lg-10">
                <div class="row row-cols-auto gap-3 row-gap-0" style="padding: 5px 12px;">
                  <div v-for="j in listJenisKelamin" :key="j" class="form-check col-auto">
                    <input class="form-check-input" name="jenis_kelamin" :id="'jk' + j.singkatan" type="radio"
                      :value="j.singkatan" v-model="formEdit.jenis_kelamin" required>
                    <label class="form-check-label" :for="'jk' + j.singkatan">{{ j.nama }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Agama</label>
              <div class="col-sm-9 col-lg-10">
                <div class="row row-cols-auto gap-3 row-gap-0" style="padding: 5px 12px;">
                  <div v-for="a in listAgama" :key="a" class="form-check col-auto">
                    <input class="form-check-input" name="agama" :id="'ag' + a.agama_id" type="radio"
                      :value="a.agama_id" v-model="formEdit.agama_id" required>
                    <label class="form-check-label" :for="'ag' + a.agama_id">{{ a.nama }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Asal Sekolah</label>
              <div class="col-sm-9 col-lg-6">
                <v-select class="bg-white rounded shadow" v-model="formEdit.asal_sekolah" :filterable="false"
                  :placeholder="formEdit.nama_asal_sekolah" :options="listAsalSekolah" label="nama" @search="onSearch"
                  :dropdown-should-open="cariAsalSekolah">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" v-bind="attributes" v-on="events" />
                  </template>
                  <template #option="option">
                    <div class="text-wrap">
                      {{ option.nama }} ~ <small class="fst-italic">{{ option.alamat }}</small>
                    </div>
                  </template>
                  <template #no-options>
                    Sekolah tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Jurusan</label>
              <div class="col-sm-9 col-lg-6">
                <v-select class="bg-white rounded shadow" v-model="formEdit.jurusan" placeholder="Pilih Jurusan"
                  label="nama" :options="listJurusan" :selectable="(option) => option.penuh == 0"
                  :disabled="dataAwal.status_ppdb_id > 1" :clearable="false">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formEdit.jurusan" v-bind="attributes" v-on="events" />
                  </template>
                  <template #option="option">
                    <div v-if="option.penuh" class="fst-italic">
                      {{ option.nama }} (penuh)
                    </div>
                    <div v-else>{{ option.nama }}</div>
                  </template>
                  <template #no-options>
                    Jurusan tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Pendidikan Dasar</label>
              <div class="col-sm-9 col-lg-10">
                <div class="row row-cols-auto gap-3 row-gap-0" style="padding: 5px 12px;">
                  <div v-for="p in listPendidikanDasar" :key="p" class="form-check col-auto">
                    <input class="form-check-input" name="pendidikan_dasar" :id="'pd' + p.singkatan" type="radio"
                      :value="p.singkatan" v-model="formEdit.pendidikan_dasar" required>
                    <label class="form-check-label" :for="'pd' + p.singkatan">{{ p.nama }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Alamat Domisili</label>
              <div class="col-sm-9 col-lg-10">
                <div class="row g-1">
                  <div class="col-12 col-md-6 col-lg-4">
                    <v-select class="bg-white rounded shadow" v-model="formEdit.provinsi" placeholder="Provinsi"
                      label="nama" :options="listProv" :clearable="false">
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!formEdit.provinsi" v-bind="attributes" v-on="events" />
                      </template>
                      <template #no-options>
                        Provinsi tidak ditemukan.
                      </template>
                    </v-select>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <v-select class="bg-white rounded shadow" v-model="formEdit.kab_kota" placeholder="Kabupaten/Kota"
                      label="nama" :options="listKabKota" :clearable="false">
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!formEdit.kab_kota" v-bind="attributes" v-on="events" />
                      </template>
                      <template #no-options>
                        Kabupaten/Kota tidak ditemukan.
                      </template>
                    </v-select>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <v-select class="bg-white rounded shadow" v-model="formEdit.kecamatan" placeholder="Kecamatan"
                      label="nama" :options="listKec" :clearable="false">
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!formEdit.kecamatan" v-bind="attributes" v-on="events" />
                      </template>
                      <template #no-options>
                        Kecamatan tidak ditemukan.
                      </template>
                    </v-select>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <v-select class="bg-white rounded shadow" v-model="formEdit.desa" placeholder="Desa" label="nama"
                      :options="listDesa" :clearable="false">
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!formEdit.desa" v-bind="attributes" v-on="events" />
                      </template>
                      <template #no-options>
                        Desa tidak ditemukan.
                      </template>
                    </v-select>
                  </div>
                  <div class="input-group">
                    <div class="form-floating z-0 w-50">
                      <textarea type="text" class="form-control form-control-sm shadow" id="alamatLengkap"
                        placeholder="Alamat Lengkap" v-model="formEdit.alamat" required></textarea>
                      <label for="alamatLengkap">Alamat Lengkap</label>
                    </div>
                    <div class="form-floating z-0">
                      <input type="number" class="form-control form-control-sm shadow" id="rt" placeholder="RT"
                        v-model="formEdit.rt" required>
                      <label for="rt">RT</label>
                    </div>
                    <div class="form-floating z-0">
                      <input type="number" class="form-control form-control-sm shadow" id="rw" placeholder="RW"
                        v-model="formEdit.rw" required>
                      <label for="rw">RW</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Kontak Siswa</label>
              <div class="col-sm-6 col-lg-4">
                <input type="text" class="form-control form-control-sm shadow"
                  placeholder="Nomor Telepon Calon Peserta Didik Baru" v-model="formEdit.kontak" pattern="[0-9]{10,13}"
                  title="Nomor telepon setidaknya terdiri dari 10 hingga 13 digit" required>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Kontak Wali</label>
              <div class="col-sm-6 col-lg-4">
                <input type="text" class="form-control form-control-sm shadow" placeholder="Nomor Telepon Wali"
                  v-model="formEdit.kontak_wali" pattern="[0-9]{10,13}"
                  title="Nomor telepon setidaknya terdiri dari 10 hingga 13 digit" required>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Pembawa PPDB</label>
              <div class="col-sm-6 col-lg-4">
                <v-select class="bg-white rounded shadow" v-model="formEdit.pembawa_ppdb" placeholder="Pembawa PPDB"
                  label="nama" :options="listPembawa">
                  <template #no-options>
                    Pembawa PPDB tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Relasi</label>
              <div class="col-sm-6 col-lg-4">
                <input type="text" class="form-control form-control-sm shadow"
                  placeholder="Relasi dengan Calon Peserta Didik Baru" v-model="formEdit.relasi">
              </div>
            </div>
            <div class="row">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Jalur Pendaftaran</label>
              <div class="col-sm-9 col-lg-10">
                <transition name="fade" mode="out-in">
                  <div class="row row-cols-auto gap-3 row-gap-0" style="padding: 5px 12px;" :key="listJalur">
                    <div v-for="j in listJalur" :key="j" class="form-check col-auto">
                      <input class="form-check-input" name="jalur_ppdb" :id="'jl' + j.jalur_ppdb_id" type="radio"
                        :value="j.jalur_ppdb_id" v-model="formEdit.jalur_ppdb_id" required :disabled="j.penuh">
                      <label class="form-check-label" :class="{ 'fst-italic': j.penuh }"
                        :for="'jl' + j.jalur_ppdb_id">{{
    j.nama
  }}<span v-if="j.penuh"> (penuh)</span></label>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-3 col-lg-2 col-form-label col-form-label-sm">Petugas CS</label>
              <div class="col-sm-6 col-lg-4">
                <v-select class="bg-white rounded shadow" v-model="formEdit.petugas_cs" placeholder="Petugas CS"
                  label="nama" :options="listCs" :clearable="false">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formEdit.petugas_cs" v-bind="attributes" v-on="events" />
                  </template>
                  <template #no-options>
                    Petugas CS tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="modal-footer position-sticky bottom-0 bg-body z-2">
            <button type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'DataPendaftaran',
  data() {
    return {
      insertListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          this.getDataPendaftaran(1);
        }
      },
      updateListener: (d) => {
        const update = JSON.parse(d);
        for (const i of this.dataPendaftaran) {
          if (i.ppdb_id === update.ppdb_id) {
            if (this.formEdit.ppdb_id == update.ppdb_id) {
              document.getElementById('tutupFormEdit').click();
            }
            this.getDataPendaftaran(2);
          }
        }
      },
      deleteListener: (d) => {
        const del = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == del.tahun_ajaran_id) {
          if (this.pageCurrent == this.pageCount && this.pageCurrent > 1) {
            if (this.dataPendaftaran.length == 1) {
              this.pageCurrent = 1;
            } else {
              this.getDataPendaftaran(1);
            }
          } else {
            this.getDataPendaftaran(1);
          }
        }
      },
      updateKuotaListener: () => {
        this.getListJurusan();
      },
      updateJalurListener: () => {
        this.getListJalur();
      },
      updateCsListener: () => {
        this.getListCs();
      },
      updatePembawaListener: (d) => {
        const data = JSON.parse(d);
        if (this.taAktif.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getListPembawa();
        }
      },
      pembayaranListener: (d) => {
        const data = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == data.tahun_ajaran_id) {
          for (const p of this.dataPendaftaran) {
            if (p.ppdb_id == data.ppdb_id) {
              this.getDataPendaftaran(0);
              break;
            }
          }
          this.getListJurusan();
          this.getListJalur();
        }
      },
      pernyataanListener: (d) => {
        const data = JSON.parse(d);
        console.log(data)
        if (this.ta.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getListJurusan();
          this.getListJalur();
        }
      },
      adminKhusus: false,
      listTempatLahir: [],
      listJenisKelamin: [
        {
          singkatan: 'L',
          nama: 'Laki-laki'
        },
        {
          singkatan: 'P',
          nama: 'Perempuan'
        },
      ],
      listAgama: [],
      listAsalSekolah: [],
      listJurusan: [],
      listPendidikanDasar: [
        {
          singkatan: 'SD',
          nama: 'Sekolah Dasar'
        },
        {
          singkatan: 'MI',
          nama: 'Madrasah Ibtidaiyyah'
        }
      ],
      listProv: [],
      listKabKota: [],
      listKec: [],
      listDesa: [],
      listPembawa: [],
      listJalur: [],
      listCs: [],
      dataPendaftaran: [],
      listTa: [],
      listGelombang: [],
      listStatus: [],
      listTeller: [],
      ta: this.$store.getters.getTahunAjaran,
      key: null,
      gelombang_ppdb: null,
      jurusan: null,
      pembawa_ppdb: null,
      status_ppdb: null,
      jalur_ppdb: null,
      petugas_cs: null,
      teller: null,
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: this.$store.getters.getDefaultLimit,
      pageCount: 0,
      dataAwal: {},
      formEdit: {},
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.adminKhusus = String(this.user.ket).split(",").some((k) => {
      return ["it", "ppdb-khusus"].includes(k);
    });
    this.getListTa();
    this.getListGelombang();
    this.getListStatus();
    this.getListTeller();
    this.getDataPendaftaran(0);
    this.getListTempatlahir();
    this.getListAgama();
    this.getListJurusan();
    this.getListProv();
    this.getListPembawa();
    this.getListJalur();
    this.getListCs();
    WebSocketService.on("inserted-ppdb", this.insertListener);
    WebSocketService.on("updated-ppdb", this.updateListener);
    WebSocketService.on("deleted-ppdb", this.deleteListener);
    WebSocketService.on("upserted-info-jurusan", this.updateKuotaListener);
    WebSocketService.on("inserted-jalur", this.updateJalurListener);
    WebSocketService.on("updated-jalur", this.updateJalurListener);
    WebSocketService.on("updated-status-jalur", this.updateJalurListener);
    WebSocketService.on("deleted-jalur", this.updateJalurListener);
    WebSocketService.on("inserted-cs", this.updateCsListener);
    WebSocketService.on("updated-cs", this.updateCsListener);
    WebSocketService.on("deleted-cs", this.updateCsListener);
    WebSocketService.on("inserted-pembawa", this.updatePembawaListener);
    WebSocketService.on("updated-pembawa", this.updatePembawaListener);
    WebSocketService.on("deleted-pembawa", this.updatePembawaListener);
    WebSocketService.on("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.on("updated-pembayaran", this.pembayaranListener);
    WebSocketService.on("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.on("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.on("updated-pernyataan", this.pernyataanListener);
    WebSocketService.on("deleted-pernyataan", this.pernyataanListener);
  },
  watch: {
    ta() {
      if (this.pageCurrent == 1) {
        this.getDataPendaftaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    gelombang_ppdb() {
      if (this.pageCurrent == 1) {
        this.getDataPendaftaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    jurusan() {
      if (this.pageCurrent == 1) {
        this.getDataPendaftaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    pembawa_ppdb() {
      if (this.pageCurrent == 1) {
        this.getDataPendaftaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    status_ppdb() {
      if (this.pageCurrent == 1) {
        this.getDataPendaftaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    jalur_ppdb() {
      if (this.pageCurrent == 1) {
        this.getDataPendaftaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    petugas_cs() {
      if (this.pageCurrent == 1) {
        this.getDataPendaftaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    teller() {
      if (this.pageCurrent == 1) {
        this.getDataPendaftaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataPendaftaran(1);
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataPendaftaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  beforeUnmount() {
    WebSocketService.off("inserted-ppdb", this.insertListener);
    WebSocketService.off("updated-ppdb", this.updateListener);
    WebSocketService.off("deleted-ppdb", this.deleteListener);
    WebSocketService.off("upserted-info-jurusan", this.updateKuotaListener);
    WebSocketService.off("inserted-jalur", this.updateJalurListener);
    WebSocketService.off("updated-jalur", this.updateJalurListener);
    WebSocketService.off("updated-status-jalur", this.updateJalurListener);
    WebSocketService.off("deleted-jalur", this.updateJalurListener);
    WebSocketService.off("inserted-cs", this.updateCsListener);
    WebSocketService.off("updated-cs", this.updateCsListener);
    WebSocketService.off("deleted-cs", this.updateCsListener);
    WebSocketService.off("inserted-pembawa", this.updatePembawaListener);
    WebSocketService.off("updated-pembawa", this.updatePembawaListener);
    WebSocketService.off("deleted-pembawa", this.updatePembawaListener);
    WebSocketService.off("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.off("updated-pembayaran", this.pembayaranListener);
    WebSocketService.off("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.off("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.off("updated-pernyataan", this.pernyataanListener);
    WebSocketService.off("deleted-pernyataan", this.pernyataanListener);
  },
  methods: {
    search: debounce(function () {
      if (this.pageCurrent == 1) {
        this.getDataPendaftaran(1);
      } else {
        this.pageCurrent = 1;
      }
    }, 500),
    async getListTa() {
      try {
        const response = await this.axios.post('/list/tahun-ajaran', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTa = response.data.tahun_ajaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListGelombang() {
      try {
        const response = await this.axios.post('/list/gelombang', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listGelombang = response.data.gelombang;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListStatus() {
      try {
        const response = await this.axios.post('/list/status-ppdb', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listStatus = response.data.status_ppdb;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListTeller() {
      try {
        const response = await this.axios.post('/list/teller', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTeller = response.data.teller;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataPendaftaran(n) {
      let memuat;
      if (n != 2) {
        memuat = this.$loading.show({
          container: this.$refs.dataPendaftaran,
          loader: n ? 'dots' : 'spinner'
        });
      }
      try {
        const response = await this.axios.post('/data/pendaftaran', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          limit: this.pageLimit,
          offset: this.pageOffset,
          key: this.key,
          gelombang_ppdb_id: Object(this.gelombang_ppdb).gelombang_ppdb_id,
          jurusan_id: Object(this.jurusan).jurusan_id,
          pembawa_ppdb_id: Object(this.pembawa_ppdb).pembawa_ppdb_id,
          status_ppdb_id: Object(this.status_ppdb).status_ppdb_id,
          jalur_ppdb_id: Object(this.jalur_ppdb).jalur_ppdb_id,
          petugas_cs_id: Object(this.petugas_cs).petugas_cs_id,
          created_by: Object(this.teller).user_id,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataPendaftaran = response.data.pendaftar;
          for (const i of this.dataPendaftaran) {
            i.created_at = new Date(i.created_at).toLocaleString('id-ID')
            if (i.updated_at) {
              i.updated_at = new Date(i.updated_at).toLocaleString('id-ID')
            }
          }
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      if (n != 2) {
        setTimeout(() => {
          memuat.hide();
        }, 250);
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.searchSekolah(loading, search, this);
      }
    },
    searchSekolah: debounce((loading, search, vm) => {
      vm.getListAsalSekolah(search).then(() => {
        loading(false);
      });
    }, 500),
    async getListTempatlahir() {
      try {
        const response = await this.axios.post('/list/tempat-lahir', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTempatLahir = response.data.tempat_lahir;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListAgama() {
      try {
        const response = await this.axios.post('/list/agama', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listAgama = response.data.agama;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    cariAsalSekolah(VueSelect) {
      return (VueSelect.search.length !== 0 && VueSelect.open && !VueSelect.mutableLoading) || (this.listAsalSekolah.length && VueSelect.open)
    },
    async getListAsalSekolah(key) {
      try {
        const response = await this.axios.post('/list/asal-sekolah', {
          key: String(key)
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listAsalSekolah = response.data.asal_sekolah;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListJurusan() {
      try {
        const response = await this.axios.post('/list/jurusan', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listJurusan = response.data.jurusan;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListProv() {
      try {
        const response = await this.axios.post('/list/wilayah', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listProv = response.data.provinsi
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListKabKota(w) {
      try {
        const response = await this.axios.post('/list/wilayah', {
          wilayah_id_prov: w
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listKabKota = response.data.kab_kota
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListKec(w) {
      try {
        const response = await this.axios.post('/list/wilayah', {
          wilayah_id_kab_kota: w
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listKec = response.data.kecamatan
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListDesa(w) {
      try {
        const response = await this.axios.post('/list/wilayah', {
          wilayah_id_kec: w
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listDesa = response.data.desa
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListPembawa() {
      try {
        const response = await this.axios.post('/list/pembawa', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listPembawa = response.data.pembawa;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListJalur() {
      try {
        const response = await this.axios.post('/list/jalur', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listJalur = response.data.jalur;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListCs() {
      try {
        const response = await this.axios.post('/list/cs', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listCs = response.data.cs;
          if (this.petugas_cs) {
            let ditemukan = 0;
            for (const c of this.listCs) {
              if (this.petugas_cs.petugas_cs_id == c.petugas_cs_id) {
                ditemukan++;
                this.petugas_cs = c;
              }
            }
            if (!ditemukan) {
              this.petugas_cs = null;
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    cetak(d) {
      const routeCetak = this.$router.resolve({
        path: '/cetak/pendaftaran/' + d.ppdb_id
      });
      window.open(routeCetak.href, '_blank');
    },
    async getDataPendaftarById(id) {
      let memuat = this.$loading.show({
        container: this.$refs.formEdit,
        loader: 'spinner'
      })
      try {
        const response = await this.axios.post('/data/pendaftaran', {
          ppdb_id: id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataAwal = response.data.pendaftar[0];
          this.dataAwal.tempat_lahir = this.listTempatLahir.filter((w) => w.wilayah_id == this.dataAwal.tempat_lahir)[0];
          const tanggalLahir = new Date(this.dataAwal.tanggal_lahir)
          this.dataAwal.tanggal_lahir = tanggalLahir.getFullYear() + '-' + ("0" + (tanggalLahir.getMonth() + 1)).slice(-2) + '-' + ("0" + tanggalLahir.getDate()).slice(-2);
          this.dataAwal.jurusan = this.listJurusan.filter((j) => j.jurusan_id == this.dataAwal.jurusan_id)[0];
          this.dataAwal.provinsi = this.listProv.filter((p) => p.wilayah_id == this.dataAwal.alamat_provinsi)[0];
          await this.getListKabKota(this.dataAwal.alamat_provinsi)
          this.dataAwal.kab_kota = this.listKabKota.filter((k) => k.wilayah_id == this.dataAwal.alamat_kab_kota)[0];
          await this.getListKec(this.dataAwal.alamat_kab_kota)
          this.dataAwal.kecamatan = this.listKec.filter((k) => k.wilayah_id == this.dataAwal.alamat_kec)[0];
          await this.getListDesa(this.dataAwal.alamat_kec)
          this.dataAwal.desa = this.listDesa.filter((d) => d.wilayah_id == this.dataAwal.alamat_desa)[0];
          this.dataAwal.pembawa_ppdb = this.listPembawa.filter((p) => p.pembawa_ppdb_id == this.dataAwal.pembawa_ppdb_id)[0];
          this.dataAwal.petugas_cs = this.listCs.filter((c) => c.petugas_cs_id == this.dataAwal.petugas_cs_id)[0];
          this.dataAwal.asal_sekolah = null;
          this.formEdit = {
            ...this.dataAwal
          };
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async updateData() {
      if (this.formEdit.asal_sekolah) {
        if (this.dataAwal.npsn_asal_sekolah == this.formEdit.asal_sekolah.npsn) {
          this.dataAwal.asal_sekolah = this.formEdit.asal_sekolah
        } else {
          this.dataAwal.asal_sekolah = null;
        }
      } else {
        this.dataAwal.asal_sekolah = null;
      }
      let dataSama = JSON.stringify(this.formEdit) === JSON.stringify(this.dataAwal);
      if (dataSama) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan!',
          icon: 'info',
          showConfirmButton: true,
        });
      } else {
        try {
          const response = await this.axios.post('/pendaftaran/perbarui', {
            ppdb_id: this.formEdit.ppdb_id,
            nisn: this.formEdit.nisn,
            nik: this.formEdit.nik,
            nama: this.formEdit.nama.toUpperCase(),
            tempat_lahir: this.formEdit.tempat_lahir.wilayah_id,
            tanggal_lahir: this.formEdit.tanggal_lahir,
            jenis_kelamin: this.formEdit.jenis_kelamin,
            agama_id: this.formEdit.agama_id,
            npsn_asal_sekolah: this.formEdit.asal_sekolah ? this.formEdit.asal_sekolah.npsn : this.formEdit.npsn_asal_sekolah,
            nama_asal_sekolah: this.formEdit.asal_sekolah ? this.formEdit.asal_sekolah.nama : this.formEdit.nama_asal_sekolah,
            jurusan_id: this.formEdit.jurusan.jurusan_id,
            pendidikan_dasar: this.formEdit.pendidikan_dasar,
            alamat_provinsi: this.formEdit.provinsi.wilayah_id,
            alamat_kab_kota: this.formEdit.kab_kota.wilayah_id,
            alamat_kec: this.formEdit.kecamatan.wilayah_id,
            alamat_desa: this.formEdit.desa.wilayah_id,
            alamat: this.formEdit.alamat,
            rt: this.formEdit.rt,
            rw: this.formEdit.rw,
            kontak: this.formEdit.kontak,
            kontak_wali: this.formEdit.kontak_wali,
            pembawa_ppdb_id: Object(this.formEdit.pembawa_ppdb).pembawa_ppdb_id,
            relasi: this.formEdit.relasi,
            jalur_ppdb_id: this.formEdit.jalur_ppdb_id,
            petugas_cs_id: this.formEdit.petugas_cs.petugas_cs_id,
          }, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.cetak(this.formEdit)
            })
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Pendaftaran <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span><br/>
                    <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(d);
        }
      });
    },
    async deleteData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/pendaftaran/hapus', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          ppdb_id: d.ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err);
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style scoped>
.modal-header {
  animation: shadow-bottom linear;
  animation-timeline: scroll(block nearest);
}

.position-sticky.bottom-0 {
  animation: shadow-top linear;
  animation-timeline: scroll(nearest block);
}

@keyframes shadow-top {
  from {
    box-shadow: 0 -10px 15px 0px rgb(0 0 0 / 0.3);
  }

  to {
    box-shadow: none;
  }
}

@keyframes shadow-bottom {
  from {
    box-shadow: none;
  }

  to {
    box-shadow: 0 10px 15px 0px rgb(0 0 0 / 0.3);
  }
}
</style>