<template>
  <div class="row row-cols-auto justify-content-md-between justify-content-end mb-2 mx-auto gap-2">
    <div class="col-12 col-md-auto p-0 d-inline-flex">
      <h5 class="my-auto">Quick Count PPDB</h5>
    </div>
    <div class="col-auto p-0">
      <select class="form-select form-select-sm shadow-sm" v-model="ta">
        <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
      </select>
    </div>
  </div>
  <div class="shadow vl-parent mb-2" ref="quickCount">
    <table class="table table-sm table-striped table-hover text-center mb-0 table-bordered border-secondary">
      <thead class="table-secondary align-middle border-secondary">
        <tr>
          <th class="py-2">Jurusan</th>
          <th>Kuota</th>
          <th>Pendaftar</th>
          <th>Diterima</th>
          <th>Sisa Kuota</th>
        </tr>
      </thead>
      <tbody v-if="quickCount.length">
        <tr v-for="q in quickCount" :key="q">
          <td>{{ q.kode }}</td>
          <td>
            <transition name="slide-up" mode="out-in">
              <span class="d-inline-block" :key="q.kuota">{{ q.kuota }}</span>
            </transition>
          </td>
          <td>
            <transition name="slide-up" mode="out-in">
              <span class="d-inline-block" :key="q.jumlah_pendaftar">{{ q.jumlah_pendaftar }}</span>
            </transition>
          </td>
          <td>
            <transition name="slide-up" mode="out-in">
              <span class="d-inline-block" :key="q.jumlah_diterima">{{ q.jumlah_diterima }}</span>
            </transition>
          </td>
          <td>
            <transition name="slide-up" mode="out-in">
              <span class="d-inline-block" :key="q.sisa_kuota">{{ q.sisa_kuota || "-" }}</span>
            </transition>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center fst-italic py-3" colspan="5">Data tidak ditemukan.</td>
        </tr>
      </tbody>
      <tfoot v-if="quickCount.length" class="table-secondary align-middle border-secondary">
        <tr>
          <th class="py-2">TOTAL</th>
          <th>
            <transition name="slide-up" mode="out-in">
              <span class="d-inline-block" :key="total.pendaftar">{{ total.kuota }}</span>
            </transition>
          </th>
          <th>
            <transition name="slide-up" mode="out-in">
              <span class="d-inline-block" :key="total.pendaftar">{{ total.pendaftar }}</span>
            </transition>
          </th>
          <th>
            <transition name="slide-up" mode="out-in">
              <span class="d-inline-block" :key="total.diterima">{{ total.diterima }}</span>
            </transition>
          </th>
          <th>
            <transition name="slide-up" mode="out-in">
              <span class="d-inline-block" :key="total.sisa">{{ total.sisa || "-" }}</span>
            </transition>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
  <button class="btn btn-success shadow" @click="downloadDataPpdb">Unduh Data Pendaftaran</button>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
  name: 'QuickCount',
  data() {
    return {
      updateKuotaListener: (d) => {
        const data = JSON.parse(d);
        let totalKuota = 0;
        let sisaKuota = 0;
        for (const q of this.quickCount) {
          if (q.jurusan_id == data.jurusan_id) {
            q.kuota = data.kuota;
            q.sisa_kuota = q.kuota - q.jumlah_diterima;
          }
          totalKuota += q.kuota;
          sisaKuota += q.sisa_kuota;
        }
        this.total.kuota = totalKuota;
        this.total.sisa = sisaKuota;
      },
      insertPpdbListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          for (const q of this.quickCount) {
            if (q.jurusan_id == insert.jurusan_id) {
              q.jumlah_pendaftar++;
              this.total.pendaftar++;
            }
          }
        }
      },
      updatePpdbListener: (d) => {
        const update = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == update.tahun_ajaran_id) {
          this.getDataQuickCount(0);
        }
      },
      deletePpdbListener: (d) => {
        const del = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == del.tahun_ajaran_id) {
          this.getDataQuickCount(0);
        }
      },
      pembayaranListener: (d) => {
        const data = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getDataQuickCount(0);
        }
      },
      pernyataanListener: (d) => {
        const data = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == data.tahun_ajaran_id) {
          this.getDataQuickCount(0);
        }
      },
      quickCount: [],
      listTa: [],
      ta: this.$store.getters.getTahunAjaran,
      total: {
        kuota: 0,
        pendaftar: 0,
        diterima: 0
      }
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
  },
  mounted() {
    this.getListTa();
    this.getDataQuickCount(1);
    WebSocketService.on("upserted-info-jurusan", this.updateKuotaListener);
    WebSocketService.on("inserted-ppdb", this.insertPpdbListener);
    WebSocketService.on("updated-ppdb", this.updatePpdbListener);
    WebSocketService.on("deleted-ppdb", this.deletePpdbListener);
    WebSocketService.on("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.on("updated-pembayaran", this.pembayaranListener);
    WebSocketService.on("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.on("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.on("updated-pernyataan", this.pernyataanListener);
    WebSocketService.on("deleted-pernyataan", this.pernyataanListener);
  },
  watch: {
    ta() {
      this.getDataQuickCount(2);
    }
  },
  beforeUnmount() {
    WebSocketService.off("upserted-info-jurusan", this.updateKuotaListener);
    WebSocketService.off("inserted-ppdb", this.insertPpdbListener);
    WebSocketService.off("updated-ppdb", this.updatePpdbListener);
    WebSocketService.off("deleted-ppdb", this.deletePpdbListener);
    WebSocketService.off("inserted-pembayaran", this.pembayaranListener);
    WebSocketService.off("updated-pembayaran", this.pembayaranListener);
    WebSocketService.off("deleted-pembayaran", this.pembayaranListener);
    WebSocketService.off("inserted-pernyataan", this.pernyataanListener);
    WebSocketService.off("updated-pernyataan", this.pernyataanListener);
    WebSocketService.off("deleted-pernyataan", this.pernyataanListener);
  },
  methods: {
    async getListTa() {
      try {
        const response = await this.axios.post('/list/tahun-ajaran', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTa = response.data.tahun_ajaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataQuickCount(n) {
      let memuat;
      if (n) {
        memuat = this.$loading.show({
          container: this.$refs.quickCount,
          loader: n == 2 ? 'dots' : 'spinner'
        });
      }
      try {
        const response = await this.axios.post('/data/quick-count', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          aktif: this.ta.ppdb_aktif
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.quickCount = response.data.quick_count;
          this.total = {
            kuota: 0,
            pendaftar: 0,
            diterima: 0,
            sisa: 0
          }
          for (const q of this.quickCount) {
            this.total.kuota += q.kuota;
            this.total.pendaftar += q.jumlah_pendaftar;
            this.total.diterima += q.jumlah_diterima;
            q.sisa_kuota = q.kuota - q.jumlah_diterima;
            this.total.sisa += q.sisa_kuota;
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      if (n) {
        setTimeout(() => {
          memuat.hide();
        }, 250);
      }
    },
    async downloadDataPpdb() {
      let memuat = this.$loading.show({
        loader: 'bars'
      });
      await this.axios.post('/data/unduh-pendaftar', {
        tahun_ajaran_id: this.ta.tahun_ajaran_id
      }, {
        headers: { Authorization: this.token },
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
          const a = document.createElement('a');
          a.href = url;
          a.download = 'DataPendaftar.xlsx';
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
            }
          } else {
            console.log(err)
          }
        });
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  },
}
</script>

<style></style>