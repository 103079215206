import { createRouter, createWebHistory } from "vue-router";
import SetToken from "../components/SetToken";
import HomeView from "../views/HomeView.vue";
import PendaftaranView from "../views/PendaftaranView";
import DaftarUlangView from "../views/DaftarUlangView";
import PernyataanView from "../views/PernyataanView";
import DataPendaftaranView from "../views/DataPendaftaranView";
import DataPembayaranView from "../views/DataPembayaranView";
import DataPernyataanView from "../views/DataPernyataanView";
import PembawaView from "../views/PembawaView";
import RiwayatPendaftaranView from "../views/RiwayatPendaftaranView";
import RiwayatPembayaranView from "../views/RiwayatPembayaranView";
import PengaturanView from "../views/PengaturanView";
import CetakView from "../views/CetakView";
import ProfilView from "../views/ProfilView";
import GantiSandiView from "../views/GantiSandiView";
import ForbiddenView from "../views/ForbiddenView";
import NotFound from "../components/NotFound";
import SeragamView from "../views/SeragamView";
import DataAsalPendaftarView from "@/views/DataAsalPendaftarView.vue";

const routes = [
    {
        path: "/auth/:token?",
        name: "setToken",
        component: SetToken,
    },
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: { adminPpdb: true },
    },
    {
        path: "/pendaftaran",
        name: "pendaftaran",
        component: PendaftaranView,
        meta: { adminPpdb: true },
    },
    {
        path: "/daftar-ulang",
        name: "daftarUlang",
        component: DaftarUlangView,
        meta: { adminPpdb: true },
    },
    {
        path: "/pernyataan",
        name: "pernyataan",
        component: PernyataanView,
        meta: { adminPpdb: true },
    },
    {
        path: "/data-pendaftaran",
        name: "dataPendaftaran",
        component: DataPendaftaranView,
        meta: { adminPpdb: true },
    },
    {
        path: "/data-pembayaran",
        name: "dataPembayaran",
        component: DataPembayaranView,
        meta: { adminPpdb: true },
    },
    {
        path: "/data-pernyataan",
        name: "dataPernyataan",
        component: DataPernyataanView,
        meta: { adminKhusus: true },
    },
    {
        path: "/pembawa",
        name: "dataPembawa",
        component: PembawaView,
        meta: { adminPpdb: true },
    },
    {
        path: "/riwayat-pendaftaran",
        name: "dataRiwayatPendaftaran",
        component: RiwayatPendaftaranView,
        meta: { adminKhusus: true },
    },
    {
        path: "/riwayat-pembayaran",
        name: "dataRiayatPembayaran",
        component: RiwayatPembayaranView,
        meta: { adminKhusus: true },
    },
    {
        path: "/asal-pendaftar",
        name: "dataAsalPendaftar",
        component: DataAsalPendaftarView,
        meta: { adminKhusus: true },
    },
    {
        path: "/pengaturan/:data?",
        name: "pengaturan",
        component: PengaturanView,
        meta: { adminKhusus: true },
    },
    {
        path: "/cetak/:jenis/:ppdb_id",
        name: "cetak",
        component: CetakView,
        meta: { adminPpdb: true },
    },
    {
        path: "/profil",
        name: "profil",
        component: ProfilView,
    },
    {
        path: "/ganti-sandi",
        name: "gantiSandi",
        component: GantiSandiView,
    },
    {
        path: "/forbidden/:halaman(.*)?",
        name: "forbidden",
        component: ForbiddenView,
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
    },
    {
        path: "/seragam/:tab?",
        name: "seragam",
        component: SeragamView,
        meta: { adminSeragam: true },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
