<template>
  <h4>Penerimaan Peserta Didik Baru {{ taAktif.nama }}</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="row row-cols-auto justify-content-between mb-2">
        <div class="col-12 col-md-auto d-inline-flex">
          <h5 class="my-auto">Data Riwayat Pembayaran</h5>
        </div>
        <div class="col-auto d-inline-flex">
          <select class="form-select form-select-sm shadow-sm my-auto" v-model="ta">
            <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
          </select>
        </div>
      </div>
      <div class="row g-1">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="pendaftar" placeholder="Pendaftar" label="no_reg_nama"
            :options="listPendaftar">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #option="option">
              <strong>({{ option.no_reg }})</strong> {{ option.nama }}
            </template>
            <template #no-options>
              Pendaftar tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="petugas_keuangan" placeholder="Petugas Keuangan"
            label="nama" :options="listKeuangan">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Petugas Keuangan tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="action_type" placeholder="Jenis Tindakan"
            :options="['INSERT', 'UPDATE', 'DELETE']">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Jenis Tindakan tidak ditemukan.
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <v-select class="bg-white rounded shadow" v-model="actioned_by" placeholder="Petugas Pendaftaran"
            label="nama_petugas" :options="listTeller">
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template #no-options>
              Petugas Pendaftaran tidak ditemukan.
            </template>
          </v-select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive vl-parent shadow" ref="riwayatPembayaran">
        <table class="table table-sm mb-0 text-nowrap">
          <thead class="table-secondary align-middle">
            <tr>
              <th rowspan="2">#</th>
              <th rowspan="2" class="position-sticky start-0">No. Reg.</th>
              <th rowspan="2">Nama</th>
              <th rowspan="2">Jurusan</th>
              <th rowspan="2">Jalur</th>
              <th rowspan="2">Voucher</th>
              <th rowspan="2">Nominal</th>
              <th rowspan="2">Petugas Keuangan</th>
              <th colspan="3">Tindakan</th>
            </tr>
            <tr>
              <th>Jenis</th>
              <th>Oleh</th>
              <th>Waktu</th>
            </tr>
          </thead>
          <tbody v-if="riwayatPembayaran.length">
            <tr v-for="d, index in riwayatPembayaran" :key="d" :class="d.action_type">
              <td>{{ index+=1 + pageOffset }}</td>
              <td class="position-sticky start-0">{{ d.no_reg }}</td>
              <td>{{ d.nama }}</td>
              <td>{{ d.kode }}</td>
              <td>{{ d.jalur_pendaftaran }}</td>
              <td>
                <svg v-if="d.pembawa_ppdb_id" xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor"
                  class="bi bi-check-lg" viewBox="0 0 16 16">
                  <path
                    d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
                <span v-else>-</span>
              </td>
              <td>
                <div :key="d.nominal" class="d-flex justify-content-between gap-1 fw-semibold">
                  <span>Rp</span><span>{{ d.nominal.toLocaleString("id-ID") }}</span>
                </div>
              </td>
              <td>{{ d.nama_petugas_keuangan }}</td>
              <td class="hstack gap-1 justify-content-between">{{ d.action_type }}
                <button type="button" class="btn btn-sm btn-outline-secondary d-flex border-0"
                  @click="getDataRiwayatPembayaranByPpdbId(d)" data-bs-toggle="modal" data-bs-target="#detailRiwayat"
                  v-tooltip="'Detail'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-info-circle"
                    viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                  </svg>
                </button>
              </td>
              <td>{{ d.actor }}</td>
              <td>{{ d.actioned_at }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="11">Data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <span v-if="riwayatPembayaran.length" class="small text-muted fst-italic">
        Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + riwayatPembayaran.length }} dari total {{ count }} data
      </span>
    </div>
    <div v-if="riwayatPembayaran.length" class="card-footer">
      <div class="row row-cols-auto justify-content-sm-between justify-content-center">
        <div class="row row-cols-auto">
          <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
          <div class="col-auto ps-1">
            <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
              <option v-for="l in arrayLimit" :key="l" :value="l">
                {{ l }} baris
              </option>
            </select>
          </div>
        </div>
        <div class="col-auto">
          <ul class="pagination pagination-sm shadow mb-0">
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
                @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
            </li>
            <li class="page-item" v-for="p in  pageCount " :key="p">
              <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
              <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button
                v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
                class="page-link" @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
    (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
                @click="pageCurrent = p">...</button>
            </li>
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
                @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="detailRiwayat" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-fullscreen-xl-down modal-dialog-centered w-100 h-auto">
      <div class="modal-content vl-parent" ref="detailRiwayat">
        <div class="modal-header">
          <legend class="modal-title">Riwayat Pembayaran</legend>
          <div class="hstack gap-2">
            <h5 class="border border-2 rounded px-4 py-2 my-auto shadow text-nowrap">No. Reg. {{ no_reg }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
        </div>
        <div class="modal-body">
          <div class="table-responsive shadow">
            <table class="table table-sm mb-0">
              <thead class="table-secondary align-middle small">
                <tr>
                  <th>#</th>
                  <th>Tindakan</th>
                  <th>Nama</th>
                  <th>Voucher</th>
                  <th>Jurusan</th>
                  <th>Jalur Pendaftaran</th>
                  <th>Nominal</th>
                  <th>Petugas Keuangan</th>
                </tr>
              </thead>

              <tbody class="small">
                <tr v-for="d, index in detailRiwayat" :key="d"
                  :class="[d.action_type == 'UPDATE' ? '' : d.action_type]">
                  <td>{{ index+=1 }}</td>
                  <td class="text-nowrap">
                    {{ d.action_type }}<br /><strong>{{ d.actor }}</strong><br />{{ d.actioned_at }}
                  </td>
                  <td class="text-nowrap">
                    {{ d.nama }}
                  </td>
                  <td
                    :class="{ 'UPDATE': index > 1 && detailRiwayat[index - 2].bayar_ppdb_id == d.bayar_ppdb_id && detailRiwayat[index - 2].pembawa_ppdb_id !== d.pembawa_ppdb_id }">
                    <svg v-if="d.pembawa_ppdb_id" xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor"
                      class="bi bi-check-lg" viewBox="0 0 16 16">
                      <path
                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                    <span v-else>-</span>
                  </td>
                  <td>
                    {{ d.kode }}
                  </td>
                  <td
                    :class="{ 'UPDATE': index > 1 && detailRiwayat[index - 2].bayar_ppdb_id == d.bayar_ppdb_id && detailRiwayat[index - 2].jalur_pendaftaran !== d.jalur_pendaftaran }">
                    {{ d.jalur_pendaftaran }}
                  </td>
                  <td
                    :class="{ 'UPDATE': index > 1 && detailRiwayat[index - 2].bayar_ppdb_id == d.bayar_ppdb_id && detailRiwayat[index - 2].nominal !== d.nominal }">
                    {{ d.nominal }}
                  </td>
                  <td
                    :class="{ 'UPDATE': index > 1 && detailRiwayat[index - 2].bayar_ppdb_id == d.bayar_ppdb_id && detailRiwayat[index - 2].nama_petugas_keuangan !== d.nama_petugas_keuangan }">
                    {{ d.nama_petugas_keuangan }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'RiwayatPembayaran',
  data() {
    return {
      ppdbListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          this.getListPendaftar();
        }
      },
      bayarListener: (d) => {
        const insert = JSON.parse(d);
        if (this.ta.tahun_ajaran_id == insert.tahun_ajaran_id) {
          this.getDataRiwayatPembayaran(1);
        }
      },
      keuanganListener: () => {
        this.getListCs();
      },
      listTa: [],
      listPendaftar: [],
      listKeuangan: [],
      listTeller: [],
      riwayatPembayaran: [],
      ta: this.$store.getters.getTahunAjaran,
      pendaftar: null,
      petugas_keuangan: null,
      action_type: null,
      actioned_by: null,
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: this.$store.getters.getDefaultLimit,
      pageCount: 0,
      no_reg: null,
      detailRiwayat: []
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.getListTa();
    this.getListPendaftar();
    this.getListKeuangan();
    this.getListTeller();
    this.getDataRiwayatPembayaran(0);
    WebSocketService.on("inserted-ppdb", this.ppdbListener);
    WebSocketService.on("updated-ppdb", this.ppdbListener);
    WebSocketService.on("deleted-ppdb", this.ppdbListener);
    WebSocketService.on("inserted-pembayaran", this.bayarListener);
    WebSocketService.on("updated-pembayaran", this.bayarListener);
    WebSocketService.on("deleted-pembayaran", this.bayarListener);
    WebSocketService.on("inserted-keuangan", this.keuanganListener);
    WebSocketService.on("updated-keuangan", this.keuanganListener);
    WebSocketService.on("deleted-keuangan", this.keuanganListener);
  },
  watch: {
    ta() {
      if (this.pageCurrent == 1) {
        this.getDataRiwayatPembayaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    pendaftar() {
      if (this.pageCurrent == 1) {
        this.getDataRiwayatPembayaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    petugas_keuangan() {
      if (this.pageCurrent == 1) {
        this.getDataRiwayatPembayaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    action_type() {
      if (this.pageCurrent == 1) {
        this.getDataRiwayatPembayaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    actioned_by() {
      if (this.pageCurrent == 1) {
        this.getDataRiwayatPembayaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataRiwayatPembayaran(1);
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataRiwayatPembayaran(1);
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  beforeUnmount() {
    WebSocketService.off("inserted-ppdb", this.ppdbListener);
    WebSocketService.off("updated-ppdb", this.ppdbListener);
    WebSocketService.off("deleted-ppdb", this.ppdbListener);
    WebSocketService.off("inserted-pembayaran", this.bayarListener);
    WebSocketService.off("updated-pembayaran", this.bayarListener);
    WebSocketService.off("deleted-pembayaran", this.bayarListener);
    WebSocketService.off("inserted-keuangan", this.keuanganListener);
    WebSocketService.off("updated-keuangan", this.keuanganListener);
    WebSocketService.off("deleted-keuangan", this.keuanganListener);
  },
  methods: {
    async getListTa() {
      try {
        const response = await this.axios.post('/list/tahun-ajaran', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTa = response.data.tahun_ajaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListPendaftar() {
      try {
        const response = await this.axios.post('/list/pendaftar', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listPendaftar = response.data.pendaftar;
          for (const p of this.listPendaftar) {
            p.no_reg_nama = "(" + p.no_reg + ") " + p.nama;
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListKeuangan() {
      try {
        const response = await this.axios.post('/list/keuangan', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listKeuangan = response.data.keuangan;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListTeller() {
      try {
        const response = await this.axios.post('/list/teller', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTeller = response.data.teller;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataRiwayatPembayaran(n) {
      let memuat;
      if (n != 2) {
        memuat = this.$loading.show({
          container: this.$refs.riwayatPembayaran,
          loader: n ? 'dots' : 'spinner'
        });
      }
      try {
        const response = await this.axios.post('/data/riwayat-pembayaran', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id,
          limit: this.pageLimit,
          offset: this.pageOffset,
          ppdb_id: Object(this.pendaftar).ppdb_id,
          petugas_keuangan_id: Object(this.petugas_keuangan).petugas_keuangan_id,
          action_type: this.action_type,
          actioned_by: Object(this.actioned_by).user_id,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.riwayatPembayaran = response.data.riwayat;
          for (const i of this.riwayatPembayaran) {
            i.actioned_at = new Date(i.actioned_at).toLocaleString('id-ID')
          }
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      if (n != 2) {
        setTimeout(() => {
          memuat.hide();
        }, 250);
      }
    },
    async getDataRiwayatPembayaranByPpdbId(b) {
      let memuat = this.$loading.show({
        container: this.$refs.detailRiwayat,
        loader: 'spinner'
      });
      this.no_reg = b.no_reg;
      this.detailRiwayat = [];
      try {
        const response = await this.axios.post('/data/riwayat-pembayaran', {
          ppdb_id: b.ppdb_id,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.detailRiwayat = response.data.riwayat;
          for (const i of this.detailRiwayat) {
            i.actioned_at = new Date(i.actioned_at).toLocaleString('id-ID')
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style scoped>
tr.INSERT:nth-child(odd)>td {
  background-color: #c7dbd2 !important;
}

tr.INSERT:nth-child(even)>td {
  background-color: #d1e7dd !important;
}

tr.INSERT:hover>td {
  background-color: #c1d6cc !important;
}

tr.UPDATE:nth-child(odd)>td {
  background-color: #f2e7c3 !important;
}

tr.UPDATE:nth-child(even)>td {
  background-color: #fff3cd !important;
}

tr.UPDATE:hover>td {
  background-color: #ece1be !important;
}

tr:nth-child(odd)>td.UPDATE {
  background-color: #f2e7c3 !important;
}

tr:nth-child(even)>td.UPDATE {
  background-color: #fff3cd !important;
}

tr:hover>td.UPDATE {
  background-color: #ece1be !important;
}

tr.DELETE:nth-child(odd)>td {
  background-color: #eccccf !important;
}

tr.DELETE:nth-child(even)>td {
  background-color: #f8d7da !important;
}

tr.DELETE:hover>td {
  background-color: #e5c7ca !important;
}
</style>