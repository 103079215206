<template>
  <div class="card-body">
    <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
      <div class="col-auto p-0 d-inline-flex">
        <h5 class="my-auto">Gelombang Pendaftaran</h5>
      </div>
      <div class="col-auto p-0">
        <button type="button" class="btn btn-sm btn-secondary hstack gap-1 shadow-sm" data-bs-toggle="modal"
          data-bs-target="#tambahData">
          <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-plus-lg"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
          </svg>Tambahkan Gelombang
        </button>
      </div>
    </div>
    <div class="table-responsive shadow vl-parent" ref="dataGelombang">
      <form @submit.prevent="updateData">
        <table class="table table-sm mb-0">
          <thead class="table-secondary align-middle">
            <tr>
              <th>Gelombang ke-</th>
              <th>Nama</th>
              <th>Biaya DU</th>
              <th>Mulai</th>
              <th>Tindakan</th>
            </tr>
          </thead>
          <tbody v-if="dataGelombang.length">
            <tr v-for="d in dataGelombang" :key="d">
              <td>{{ d.gelombang_ppdb_id }}</td>
              <td v-if="d.edit"><input type="text" class="form-control form-control-sm" v-model="formEdit.nama"
                  :placeholder="d.nama" required></td>
              <td v-else>
                <transition name="fade" mode="out-in">
                  <span :key="d.nama">{{ d.nama }}</span>
                </transition>
              </td>
              <td v-if="d.edit"><input type="text" class="form-control form-control-sm" v-model="input_nominal"
                  :placeholder="d.biaya_du" required></td>
              <td v-else>
                <transition name="fade" mode="out-in">
                  <span :key="d.biaya_du">{{ d.biaya_du.toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0,
      }) }}</span>
                </transition>
              </td>
              <td v-if="d.edit"><input type="number" class="form-control form-control-sm" v-model="formEdit.mulai"
                  :placeholder="d.mulai" required></td>
              <td v-else>
                <transition name="fade" mode="out-in">
                  <span :key="d.mulai">{{ d.mulai }}</span>
                </transition>
              </td>
              <td v-if="d.edit">
                <div class="hstack gap-1">
                  <button class="btn btn-sm btn-outline-dark" @click="batalEdit">Batal
                  </button>
                  <div class="vr"></div>
                  <button type="submit" class="btn btn-sm btn-primary" :disabled="sedangMengunggah">
                    <span v-if="sedangMengunggah" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span v-if="sedangMengunggah" role="status">Loading...</span>
                    <span v-else>Simpan</span>
                  </button>
                </div>
              </td>
              <td v-else>
                <div class="hstack gap-1">
                  <button type="button" class="btn btn-sm btn-outline-primary d-flex" @click="editData(d)"
                    v-tooltip="'Edit Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                      viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button type="button" class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)"
                    v-tooltip="'Hapus Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="5">Data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
  <div class="modal fade" id="tambahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formCreate">
        <form @submit.prevent="insertData">
          <div class="modal-header">
            <legend class="modal-title">Tambah Data Gelombang Pendaftaran</legend>
            <button type="button" id="tutupFormCreate" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Gelombang ke</label>
              <div class="col-sm-9">
                <input type="number" class="form-control form-control-sm" placeholder="Gelombang ke"
                  v-model="formCreate.gelombang_ppdb_id" required min="1">
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama/Singkatan Gelombang"
                  v-model="formCreate.nama" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Biaya DU</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Biaya Daftar Ulang"
                  v-model="biaya_du" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Mulai</label>
              <div class="col-sm-9">
                <input type="number" class="form-control form-control-sm" placeholder="Mulai Setelah Urutan"
                  v-model="formCreate.mulai" required>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
  name: 'DataGelombang',
  data() {
    return {
      insertOrDeleteListener: () => {
        this.getDataGelombang(1)
      },
      updateListener: (d) => {
        const update = JSON.parse(d);
        for (const i of this.dataGelombang) {
          if (i.gelombang_ppdb_id === update.gelombang_ppdb_id) {
            i.edit = false;
            i.nama = update.nama;
            i.biaya_du = update.biaya_du;
            i.mulai = update.mulai;
          }
        }
      },
      dataGelombang: [],
      formCreate: {
        gelombang_ppdb_id: null,
        nama: null,
        biaya_du: null,
        mulai: null,
      },
      biaya_du: null,
      formEdit: {},
      input_nominal: null,
      sedangMengunggah: false
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
  },
  mounted() {
    this.getDataGelombang(0);
    WebSocketService.on("inserted-gelombang", this.insertOrDeleteListener);
    WebSocketService.on("updated-gelombang", this.updateListener);
    WebSocketService.on("deleted-gelombang", this.insertOrDeleteListener);
  },
  watch: {
    biaya_du(newValue) {
      let input = new String(newValue).replace(/[^,\d]/g, '');
      const formattedValue = new Intl.NumberFormat('id-ID', {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0,
      }).format(input);
      this.biaya_du = formattedValue;
      this.formCreate.biaya_du = new Number(input)
    },
    input_nominal(newValue) {
      let input = new String(newValue).replace(/[^,\d]/g, '');
      const formattedValue = new Intl.NumberFormat('id-ID', {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0,
      }).format(input);
      this.input_nominal = formattedValue;
      this.formEdit.biaya_du = new Number(input)
    }
  },
  beforeUnmount() {
    WebSocketService.off("inserted-gelombang", this.insertOrDeleteListener);
    WebSocketService.off("updated-gelombang", this.updateListener);
    WebSocketService.off("deleted-gelombang", this.insertOrDeleteListener);
  },
  methods: {
    async getDataGelombang(n) {
      let memuat = this.$loading.show({
        container: this.$refs.dataGelombang,
        loader: n ? 'dots' : 'spinner'
      });
      try {
        const response = await this.axios.post('/data/gelombang', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataGelombang = response.data.gelombang;
          for (const i of this.dataGelombang) {
            i.edit = false;
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async insertData() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/setting/gelombang/tambah', this.formCreate, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          document.getElementById('tutupFormCreate').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.formCreate = {
              gelombang_ppdb_id: null,
              nama: null,
              biaya_du: null,
              mulai: null,
            }
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide()
      }, 250);
    },
    editData(d) {
      for (const i of this.dataGelombang) {
        i.edit = i.gelombang_ppdb_id === d.gelombang_ppdb_id;
      }
      this.input_nominal = d.biaya_du;
      this.formEdit = { ...d }
    },
    batalEdit() {
      for (const i of this.dataGelombang) {
        i.edit = false;
      }
    },
    async updateData() {
      this.sedangMengunggah = true;
      const awal = this.dataGelombang.filter((i) => i.gelombang_ppdb_id == this.formEdit.gelombang_ppdb_id)[0];
      let perubahan = false;
      if (JSON.stringify(awal) === JSON.stringify(this.formEdit)) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan!',
          icon: 'info',
          showConfirmButton: true,
        }).then(() => {
          this.sedangMengunggah = false;
        })
      } else {
        perubahan = true;
        try {
          const response = await this.axios.post('/setting/gelombang/perbarui', {
            gelombang_ppdb_id: this.formEdit.gelombang_ppdb_id,
            nama: this.formEdit.nama,
            biaya_du: this.formEdit.biaya_du,
            mulai: this.formEdit.mulai,
          }, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
        catch (err) {
          this.sedangMengunggah = false;
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err);
          }
        }
      }
      setTimeout(() => {
        this.sedangMengunggah = perubahan ? false : true;
      }, 250);
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Gelombang Pendaftaran ke-<code class="text-black text-bg-secondary bg-opacity-50">${d.gelombang_ppdb_id}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(d);
        }
      });
    },
    async deleteData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/setting/gelombang/hapus', { gelombang_ppdb_id: d.gelombang_ppdb_id }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err);
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style></style>