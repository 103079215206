import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import FungsiTerbilang from "./components/Terbilang";
import bootstrapTooltip from "./components/bootstrapTooltip";

axios.defaults.withCredentials = true;

const app = createApp(App);

// axios.defaults.baseURL = "http://backend-ppdb.com:3004";
// app.config.globalProperties.$auth = "http://auth.com:3000";
// app.config.globalProperties.$sso = "http://sso.com:200";
// app.config.globalProperties.$appUrl = "http://ppdb.com:204";
// app.config.globalProperties.$appId = "5e7f297a-10cd-11ef-801e-ac120340b234";

// axios.defaults.baseURL = "http://backend-ppdb.local";
// app.config.globalProperties.$auth = "http://auth.local";
// app.config.globalProperties.$sso = "http://sso.local";
// app.config.globalProperties.$appUrl = "http://ppdb.local";
// app.config.globalProperties.$appId = "35e6ec42-2621-11ef-abda-2b8d42a26824";

axios.defaults.baseURL = "https://localbackend-ppdb.sitepgri.com";
app.config.globalProperties.$auth = "https://localauth.sitepgri.com";
app.config.globalProperties.$sso = "https://sso.sitepgri.com";
app.config.globalProperties.$appUrl = "https://ppdb.sitepgri.com";
app.config.globalProperties.$appId = "9a87216b-57b8-11ee-a065-ee52fd8f2043";

let isAuthenticated = false;
let isForbidden = true;

router.beforeEach((to, from, next) => {
    if (isAuthenticated) {
        if (to.name === "forbidden") {
            next();
        } else if (isForbidden) {
            next("/forbidden");
        } else {
            const user = store.getters.getUserData;
            let allowed = [];
            if (to.meta.adminKhusus) {
                allowed = ["it", "ppdb-khusus"];
            } else if (to.meta.adminPpdb) {
                allowed = ["it", "ppdb-khusus", "ppdb"];
            } else if (to.meta.adminSeragam) {
                allowed = ["it", "ppdb-khusus", "seragam"];
            }
            if (!allowed.length) {
                next();
            } else if (
                String(user.ket)
                    .split(",")
                    .some((k) => {
                        return allowed.includes(k);
                    })
            ) {
                next();
            } else if (
                to.name === "home" &&
                String(user.ket)
                    .split(",")
                    .some((k) => {
                        return /^seragam$/.test(k);
                    })
            ) {
                next("/seragam");
            } else {
                next("/forbidden" + to.path);
            }
        }
    } else {
        const token = store.getters.getToken;
        if (to.name === "setToken" && to.params.token) {
            store.dispatch("updateToken", to.params.token);
            store
                .dispatch("authenticateUser", app.config.globalProperties.$auth)
                .then(() => {
                    isAuthenticated = true;
                    const user = store.getters.getUserData;
                    const allowed = ["it", "ppdb-khusus", "ppdb"];
                    if (
                        String(user.ket)
                            .split(",")
                            .some((k) => {
                                return allowed.includes(k);
                            })
                    ) {
                        isForbidden = false;
                        store.dispatch("fetchTahunAjaran").then(() => {
                            next("/");
                        });
                    } else if (
                        String(user.ket)
                            .split(",")
                            .some((k) => {
                                return /^seragam$/.test(k);
                            })
                    ) {
                        isForbidden = false;
                        store.dispatch("fetchTahunAjaran").then(() => {
                            next("/seragam");
                        });
                    } else {
                        isForbidden = true;
                        next("/forbidden");
                    }
                })
                .catch((err) => {
                    isAuthenticated = false;
                    isForbidden = true;
                    console.error("Failed to login with token:", err);
                    redirectToSSO();
                });
        } else if (token) {
            store
                .dispatch("authenticateUser", app.config.globalProperties.$auth)
                .then(() => {
                    isAuthenticated = true;
                    const user = store.getters.getUserData;
                    let allowed = [];
                    const allowedMain = [
                        "it",
                        "ppdb-khusus",
                        "ppdb",
                        "seragam",
                    ];
                    if (
                        String(user.ket)
                            .split(",")
                            .some((k) => {
                                return allowedMain.includes(k);
                            })
                    ) {
                        isForbidden = false;
                        store.dispatch("fetchTahunAjaran").then(() => {
                            if (to.meta.adminKhusus) {
                                allowed = ["it", "ppdb-khusus"];
                            } else if (to.meta.adminPpdb) {
                                allowed = ["it", "ppdb-khusus", "ppdb"];
                            } else if (to.meta.adminSeragam) {
                                allowed = ["it", "ppdb-khusus", "seragam"];
                            }
                            if (!allowed.length) {
                                next();
                            } else if (
                                String(user.ket)
                                    .split(",")
                                    .some((k) => {
                                        return allowed.includes(k);
                                    })
                            ) {
                                next();
                            } else if (
                                to.name === "home" &&
                                String(user.ket)
                                    .split(",")
                                    .some((k) => {
                                        return /^seragam$/.test(k);
                                    })
                            ) {
                                next("/seragam");
                            } else {
                                next("/forbidden" + to.path);
                            }
                        });
                    } else {
                        isForbidden = true;
                        next("/forbidden");
                    }
                })
                .catch((err) => {
                    isAuthenticated = false;
                    isForbidden = true;
                    console.error("Failed to login with token:", err);
                    redirectToSSO();
                });
        } else {
            redirectToSSO();
        }
    }
});

function redirectToSSO() {
    window.location.href =
        app.config.globalProperties.$sso +
        "/" +
        app.config.globalProperties.$appId;
}

app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(LoadingPlugin, {
    canCancel: false,
    color: "#002b80",
    backgroundColor: "#ccddff",
    opacity: 0.3,
    zIndex: 9999,
});
app.component("v-select", vSelect);
app.use(FungsiTerbilang);
app.directive("tooltip", bootstrapTooltip);

app.mount("#app");
