<template>
  <nav-bar v-if="user" :user="user" style="z-index: 4;" />
  <main class="container mt-2 mb-4 shadow-lg p-3 small">
    <router-view />
  </main>
  <footer class="fixed-bottom d-flex justify-content-end px-2 text-bg-secondary bg-opacity-75 shadow small">
    <span class="small">&copy; 2024</span>
  </footer>
</template>

<script>
import NavBar from './components/NavBar.vue';
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'App',
  data() {
    return {
    };
  },
  components: { NavBar },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
  },
  created() {
    WebSocketService.connect("/ppdb");
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style>
main,
nav {
  font-family: 'Trebuchet MS', sans-serif;
}

#cari {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" fill="gray" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: 7px;
  background-position-y: center;
  text-indent: 21px;
  background-size: 16px;
}

::-webkit-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

.v-select {
  --vs-line-height: 1.36;
  --vs-border-radius: .25rem;
  --vs-controls-size: .75;
  --vs-border-width: 1px;
  --vs-border-style: solid;
  --vs-border-color: #dee2e6;
  --vs-search-input-placeholder-color: #595c5f;
  --vs-font-size: .875rem;
}

.reset-button {
  background: transparent;
  border: 1px solid #dee2e6;
  fill: rgba(60, 60, 60, 0.5);
  cursor: pointer;
}

.select-wrapper {
  position: relative;
  display: inline-block;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(20px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* .table-responsive {
  animation: shadow-inside linear;
  animation-timeline: scroll(self inline);
} */

.table-responsive table {
  border-collapse: separate;
  border-spacing: 0;
}

table .position-sticky {
  animation: shadow-outside linear;
  animation-timeline: scroll(nearest inline);
}

.table-responsive tbody tr:nth-child(odd) td {
  background-color: #f2f2f2;
}

.table-responsive tbody tr:nth-child(even) td {
  background-color: #ffffff;
}

.table-responsive tbody tr:hover td {
  background-color: #ececec;
}

@keyframes shadow-inside {
  from {
    box-shadow: inset -10px -10px 15px 0px rgb(0 0 0 / 0.3);
  }

  to {
    box-shadow: none;
  }
}

@keyframes shadow-outside {
  from {
    box-shadow: none;
  }

  to {
    box-shadow: 10px 10px 15px 0px rgb(0 0 0 / 0.3);
  }
}
</style>